/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const OfferBOGOTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const buy = useInput(1);
  const get = useInput(1);
  const val = useInput("");
  const type = useInput("Free");
  const typeData = [
    { id: "%", title: "%" },
    { id: "% off", title: "% off" },
    { id: "$", title: "$" },
    { id: "$ off", title: "$ off" },
    { id: "Free", title: "Free" },
  ];

  const handleUpdateValue = (b, g, v, type) => {
    switch (type) {
      case "Free":
        onChange(`B${b}G${g}F`);
        val.setValue("");
        break;
      case "%":
        onChange(`B${b}G${g} ${v}%`);
        break;
      case "% off":
        onChange(`B${b}G${g} ${v}% off`);
        break;
      case "$":
        onChange(`B${b}G${g} $${v}`);
        break;
      case "$ off":
        onChange(`B${b}G${g} $${v} off`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (value) {
      const parseBOGO = (str) => {
        const gIndex = str.indexOf("G");
        const b = str.substring(1, gIndex);
        const g = str.substring(gIndex + 1);
        return { b, g };
      };
      if (value.endsWith("F")) {
        type.setValue("Free");
        const { b, g } = parseBOGO(value.substring(0, value.length - 1));
        buy.setValue(b);
        get.setValue(g);
      } else if (value.endsWith("%")) {
        const parts = value.substring(0, value.length - 1).split(" ");
        const { b, g } = parseBOGO(parts[0]);
        buy.setValue(b);
        get.setValue(g);
        val.setValue(parts[1]);
        type.setValue("%");
      } else if (value.endsWith("% off")) {
        const parts = value.substring(0, value.length - 5).split(" ");
        const { b, g } = parseBOGO(parts[0]);
        buy.setValue(b);
        get.setValue(g);
        val.setValue(parts[1]);
        type.setValue("% off");
      } else if (value.endsWith(" off")) {
        const parts = value.substring(0, value.length - 4).split(" ");
        const { b, g } = parseBOGO(parts[0]);
        buy.setValue(b);
        get.setValue(g);
        val.setValue(parts[1].substring(1));
        type.setValue("$ off");
      } else {
        const parts = value.split(" ");
        if (parts.length === 2) {
          const { b, g } = parseBOGO(parts[0]);
          buy.setValue(b);
          get.setValue(g);
          val.setValue(parts[1].substring(1));
          type.setValue("$");
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 60, marginRight: 20 }}>
          <Input2
            label="Buy"
            value={buy.value}
            onChange={(e) => {
              buy.setValue(e);
              handleUpdateValue(e, get.value, val.value, type.value);
            }}
          />
        </div>
        <div style={{ width: 60, marginRight: 20 }}>
          <Input2
            label="Get"
            value={get.value}
            onChange={(e) => {
              get.setValue(e);
              handleUpdateValue(buy.value, e, val.value, type.value);
            }}
          />
        </div>

        <div style={{ width: 120, marginRight: 20 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(buy.value, get.value, e, type.value);
            }}
          />
        </div>

        <div className="ml-auto" style={{ width: 120 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={120}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(buy.value, get.value, val.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
