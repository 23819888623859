import React from "react";
import styled from "styled-components";

export const EmptyOfferSelector = ({
  onCreateOffer = () => {},
  onCreateIncentive = () => {},
}) => {
  return (
    <NoItemsContainer className="mt-2">
      <div>There is no offer/incentive available.</div>
      <div>
        Create a new{" "}
        <span className="cursor-pointer" onClick={onCreateOffer}>
          <u>offer</u>
        </span>
        {", "}
        <span className="cursor-pointer" onClick={onCreateIncentive}>
          <u>incentive</u>
        </span>{" "}
        or flag the adblock appropriately.
      </div>
    </NoItemsContainer>
  );
};

const NoItemsContainer = styled.div`
  font-size: 14px;
`;
