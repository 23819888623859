/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Link } from "@reach/router";
import { FaCropAlt, FaTag, FaBarcode } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { useAuthContext, useMainContext } from "contexts";
import { getQueueCountApi } from "api";

export const HomePage = () => {
  const { userInfo } = useAuthContext();
  const { setCurrentUserId } = useMainContext();
  const circularMenuInfo = {
    label: "Circulars",
    icon: <AiFillSetting size={120} />,
    route: "/circular",
    disabled: false,
  };
  const [menuData, setMenuData] = useState([
    {
      label: "Adblock Cropping",
      icon: <FaCropAlt size={120} />,
      route: "/adblocks/",
      disabled: false,
      count: "",
    },
    {
      label: "Product Processing",
      icon: <FaBarcode size={120} />,
      route: "/products/",
      disabled: false,
      count: "",
    },
    {
      label: "Offer Processing",
      icon: <FaTag size={120} />,
      route: "/offers/",
      disabled: false,
      count: "",
    },
  ]);

  const init = async () => {
    setCurrentUserId(userInfo?.user?.id);

    const res = await getQueueCountApi();
    if (res.success) {
      setMenuData([
        { ...menuData[0], count: res.results.adblock_processing },
        { ...menuData[1], count: res.results.product_processing },
        { ...menuData[2], count: res.results.offer_processing },
      ]);
    }
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  return (
    <MainLayout>
      <MainContent>
        <MainBox>
          <div className="w-100 ml-3">Welcome, please choose a Workflow</div>
          {menuData.map((item) => (
            <MenuItem key={item.route} {...item} />
          ))}
          {userInfo?.user?.is_superuser && <MenuItem {...circularMenuInfo} />}
        </MainBox>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
`;
const MainBox = styled.div`
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
`;
const MenuItem = ({ label, route, icon, disabled, count = "" }) => {
  return (
    <MenuItemContainer className={disabled ? "disabled" : ""} to={route}>
      <div className="count">{count}</div>
      {icon}
      <div className="label">{label}</div>
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled(Link)`
  width: 240px;
  min-width: 240px;
  height: 240px;
  background: ${(props) => props.theme.palette.backgrounds};
  color: white;
  margin: 5px;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  flex-direction: column;
  padding: 10px;

  &.disabled {
    color: ${(props) => props.theme.palette.secondary};
    pointer-events: none;

    .label {
      color: ${(props) => props.theme.palette.secondary};
      font-size: 24px;
    }

    &:hover {
      opacity: 1;
      color: ${(props) => props.theme.palette.secondary};
      border: none;
      text-decoration: none;
    }
  }

  svg {
    margin: auto;
  }
  .count {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
  }
  .label {
    font-weight: bold;
    font-size: 20px;
    color: white;
    text-align: left;
  }
  &:hover {
    opacity: 0.5;
    color: ${(props) => props.theme.palette.primaryHover};
    border: 1px solid ${(props) => props.theme.palette.primaryHover};
    text-decoration: none;
  }
`;
