/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, Button, Input2, TextArea2 } from "components";
import { Formik, Form } from "formik";
import { useInput } from "hooks";
import * as Yup from "yup";
import { useMutation } from "react-query";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const CreateParseTemplateModal = ({
  open,
  onClose,
  onCreate = () => {},
  data = {},
}) => {
  const name = useInput("");
  const retailer = useInput("");
  const scrapeType = useInput("");
  const contents = useInput("");

  const createMutation = useMutation((data) => data);

  useEffect(() => {
    if (open) {
      name.setValue(data?.name || "");
      retailer.setValue(data?.domain || "");
      scrapeType.setValue(data?.lang || "");
      contents.setValue(data?.country || "");
    }
    return () => {};
  }, [open]);

  const handleCreate = async (status) => {
    await createMutation.mutateAsync({
      name: name.value,
      data: {
        retailer_id: retailer.value,
        scrape_type_id: scrapeType.value,
        contents: contents.value,
        status,
      },
    });

    onClose();
  };

  const isValid = () =>
    retailer.value && scrapeType.value && contents.value && name.value;

  return (
    <Modal
      open={open}
      size="small"
      onClose={onClose}
      title={data?.name ? "Update Parser" : "Create Parser"}
    >
      <Formik
        initialValues={{
          password: "",
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {}}
      >
        {({ isSubmitting }) => (
          <Form>
            <Input2 label="Name" name="name" {...name} />

            <TextArea2
              className="mt-3"
              label="Contents"
              name="contents"
              {...contents}
            />

            <div className="d-flex mt-4">
              <Button
                size="normal"
                buttonTheme="dark"
                width="fit-content"
                className="ml-auto"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={() => handleCreate("DRAFT")}
                disabled={!isValid()}
              >
                Draft
              </Button>
              <Button
                size="normal"
                buttonTheme="dark"
                width="fit-content"
                className="ml-3"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={() => handleCreate("ACTIVE")}
                disabled={!isValid()}
              >
                Publish
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
