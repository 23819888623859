/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button } from "components";
import { setAdblockEvents } from "api";
import styled from "styled-components";

export const ApplyEventsModal = ({
  open,
  onClose,
  onOk = () => {},
  events = [],
  pageId,
  onRefresh = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    applyChange();
  };
  const applyChange = async () => {
    setIsLoading(true);
    await setAdblockEvents(
      pageId,
      events.map((item) => item).filter((item) => item.length > 0)
    );
    await onRefresh();
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={"Apply Events to All Page"}
    >
      <Content className="mb-4 pb-4">
        {isLoading ? (
          <>Please wait while we apply events to each page...</>
        ) : (
          <>
            <div>
              Are you sure you want to apply these events to all the adblocks on
              this page?
            </div>
            <div className="d-flex mt-2">{events.join(", ")}</div>
            <div className="d-flex mt-4">
              <Button
                size="small"
                buttonTheme="dark"
                width="fit-content"
                className="ml-auto"
                type="button"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                size="small"
                buttonTheme="primary"
                width="fit-content"
                className="ml-3"
                type="button"
                onClick={() => handleOk()}
              >
                Apply
              </Button>
            </div>
          </>
        )}
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  font-size: 14px;
  color: white;
  height: 100px;
`;
