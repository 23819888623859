import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

export const EmptyHeader = () => {
  const handleNavigateToHome = () => {
    navigate("/home");
  };

  const handleLogoClick = (event) => {
    if (event.button === 1) {
      window.open("/home");
    }
  };

  return (
    <StyledHeader>
      <Logo
        className="my-auto"
        onMouseDown={handleLogoClick}
        onClick={handleNavigateToHome}
      >
        ADDISON
      </Logo>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background: #131313;
  height: 50px;
  display: flex;
  padding: 0 24px;
  border-bottom: 4px solid #0a0a0a;
`;

const Logo = styled.div`
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
`;
