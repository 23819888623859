/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PageViewModal } from "modals";
import { useModal } from "hooks";
import { useMainContext } from "contexts";

export const AdblockLocation = ({
  backImg,
  pageWidth = 0,
  pageHeight = 0,
  adblockCoords = { coords_x1: 0, coords_x2: 0, coords_y1: 0, coords_y2: 0 },
  id = "Adblock Location",
}) => {
  const pageViewModal = useModal();
  const { postPageEvents } = useMainContext();
  const ref = useRef(null);
  const [clientWidth, setClientWidth] = useState(1);
  const [clientHeight, setClientHeight] = useState(1);
  const [adblockRect, setAdblockRect] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setClientWidth(ref.current.clientWidth - 24);
      setClientHeight(ref.current.clientHeight - 16);
    }
    return () => {};
  }, [ref]);

  useEffect(() => {
    if (
      backImg &&
      pageWidth > 1 &&
      pageHeight > 1 &&
      (adblockCoords.coords_x1 > 0 ||
        adblockCoords.coords_x2 > 0 ||
        adblockCoords.coords_y1 > 0 ||
        adblockCoords.coords_y2 > 0)
    ) {
      let offsetX = 0,
        offsetY = 0,
        adblockWidth,
        adblockHeight;
      const clientRatio = clientWidth / clientHeight;
      const adblockRatio = pageWidth / pageHeight;
      if (adblockRatio < clientRatio) {
        offsetY = 0;
        offsetX = (clientWidth - clientHeight * adblockRatio) / 2;
        adblockWidth = clientHeight * adblockRatio;
        adblockHeight = clientHeight;
      } else {
        offsetY = (clientHeight - clientWidth / adblockRatio) / 2;
        offsetX = 0;
        adblockWidth = clientWidth;
        adblockHeight = clientHeight / adblockRatio;
      }
      setAdblockRect({
        x: offsetX + adblockWidth * (adblockCoords.coords_x1 / pageWidth),
        y: offsetY + adblockHeight * (adblockCoords.coords_y1 / pageHeight),
        w:
          adblockWidth *
          ((adblockCoords.coords_x2 - adblockCoords.coords_x1) / pageWidth),
        h:
          adblockHeight *
          ((adblockCoords.coords_y2 - adblockCoords.coords_y1) / pageHeight),
      });
    }
    return () => {};
  }, [backImg, pageWidth, pageHeight, adblockCoords]);

  return (
    <Container>
      <Header>ADBLOCK LOCATION</Header>
      <Content ref={ref}>
        {backImg && (
          <img
            src={backImg}
            onClick={() => {
              pageViewModal.openModal();
              postPageEvents("click", id);
            }}
            alt="page back"
          />
        )}
        {adblockRect.w > 0 && adblockRect.h > 0 && <RedRect {...adblockRect} />}
        <PageViewModal
          {...pageViewModal}
          backImg={backImg}
          pageWidth={pageWidth}
          pageHeight={pageHeight}
          adblockCoords={adblockCoords}
        />
      </Content>
    </Container>
  );
};
const Container = styled.div`
  min-width: 240px;
  max-height: 35%;
  height: calc(100% - 440px);
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.items};
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const RedRect = styled.div`
  position: absolute;
  margin-top: ${(props) => props.y}px;
  margin-left: ${(props) => props.x}px;
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  border: 2px solid red;
`;
