/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import JobReducer from "./RetailersReducer";
import {
  SET_LOADING,
  INIT_RETAILERS,
  GET_RETAILERS,
  SET_ERROR,
  SET_REFETCHING,
  GET_MORE_RETAILERS,
  GET_SINGLE_RETAILER,
  SET_RETAILERS_SEARCH,
  SET_RETAILERS_FILTER,
} from "../types";
import { getRetailersList, getRetailerByIdApi } from "api";
const RetailersContext = createContext({});
export const useRetailersContext = () => useContext(RetailersContext);
const STORAGE_KEY = "addison-retailers-state";

export const RetailersProvider = (props) => {

  const initialState = {
    query: "",
    retailers: [],
    totalCount: 0,
    resultCount: 0,
    totalPages: 0,
    currentPage: 1,
    isLoading: false,
    isRefetching: false,
    sortBy: "name",
    sortDir: "ASC",
  };

  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const [state, dispatch] = useReducer(JobReducer, value);

  useEffect(() => {
    set(state);
    return () => {};
  }, [state]);

  const getRetailers = async () => {
    setRetailersLoading();
    const res = await getRetailersList({
      page: 0,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
    });
    dispatch({
      type: res.success ? GET_RETAILERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const loadMore = async ({ retailer_id = undefined }) => {
    setRetailersRefetching();
    const res = await getRetailersList({
      page: state.currentPage + 50,
      sort_by: state.sortBy || undefined,
      sort_dir: state.sortDir || undefined,
      stage: state.stage,
    });
    dispatch({
      type: res.success ? GET_MORE_RETAILERS : SET_ERROR,
      payload: res.payload,
    });
  };

  const findRetailer = async (params) => {
    console.log(params);
    setRetailersRefetching();
    const res = await getRetailerByIdApi(params);
    dispatch({
      type: res.success ? GET_SINGLE_RETAILER : SET_ERROR,
      payload: res.results,
    });
  };

  const initRetailers = async () => {
    dispatch({
      type: INIT_RETAILERS,
    });
  };

  // Set Loading
  const setRetailersLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Refetching
  const setRetailersRefetching = (params = true) =>
    dispatch({ type: SET_REFETCHING, payload: params });

  const setQuery = (query) => {
    dispatch({ type: SET_RETAILERS_SEARCH, payload: query });
  };

  const setFilter = (params) => {
    dispatch({ type: SET_RETAILERS_FILTER, payload: params });
  };

  const { children } = props;

  return (
    <RetailersContext.Provider
      value={{
        ...state,
        initRetailers,
        setRetailersLoading,
        setRetailersRefetching,
        getRetailers,
        findRetailer,
        loadMore,
        setQuery,
        setFilter,
      }}
    >
      {children}
    </RetailersContext.Provider>
  );
};
