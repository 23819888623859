export * from "./API";
export * from "./string";
export * from "./parse";
export * from "./cookie";
export * from "./filter";
export * from "./json";
export * from "./url";
export * from "./data";
export * from "./products";

export const validateIncentive = (incentive) => {
  return !!incentive?.incentive_text;
};

export const validateOffer = (offer) => offer?.offer_type && offer?.offer_text;
