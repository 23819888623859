import React from "react";
import styled from "styled-components";
import { Button } from "components";
import { Transition } from "react-transition-group";
import ReactModal from "react-modal";
import { useAuthContext } from "contexts";
import { useModal } from "hooks";
import { ResetPasswordModal } from "modals";
const customStyles = (state) => {
  return {
    content: {
      padding: 0,
      borderRadius: 0,
      border: "none",
      height: "calc(100% - 50px)",
      width: "fit-content",
      top: 50,
      left: "calc(100% + 20px)",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.50)",
      transform:
        state === "entering" || state === "entered"
          ? "translateX(-440px)"
          : state === "exiting" || state === "exited"
          ? "translateX(0px)"
          : "translateX(0px)",
      transition: "all 300ms ease-in-out",
      opacity: state === "entering" || state === "entered" ? 1 : 0,
    },
    overlay: {
      background: "#0A0A0Aa0",
      transition: "all 300ms ease-in-out",
    },
  };
};
export const ProfileModal = ({ open, onClose, userName }) => {
  const { logoutUser, userInfo } = useAuthContext();
  const resetPasswordModal = useModal();

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <>
      <Transition in={open} timeout={300}>
        {(state) => (
          <ReactModal
            closeTimeoutMS={300}
            isOpen={open}
            style={customStyles(state)}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick
          >
            <Container>
              <Header>
                <span className="my-auto">{userName ?? ""} Profile</span>
              </Header>
              <Content>
                <ContentHeading>Account Type</ContentHeading>
                <div className="ml-4 mt-3">
                  {userInfo?.user?.is_superuser ? "Super User" : "Normal User"}
                </div>
                <ContentHeading>User Email</ContentHeading>
                <div className="ml-4 mt-3">{userInfo?.user?.email}</div>
                <RestPasswordButton
                  className="mt-auto ml-2"
                  onClick={() => resetPasswordModal.openModal()}
                >
                  Reset Password
                </RestPasswordButton>
                <Text className="mt-1">Issues? Feedback ?</Text>
                <div className="ml-4 mt-3 d-flex">
                  Contact Us: &nbsp;
                  <a
                    href="https://deepad.atlassian.net/servicedesk/customer/portal/6"
                    target="_blank"
                    rel="noreferrer"
                    className="my-auto"
                  >
                    here
                  </a>
                </div>
                <Text>Version</Text>
                <div className="ml-4 mt-3">{"Demo 0.0.0.0"}</div>
              </Content>
              <Button size="normal" onClick={handleLogout}>
                Logout
              </Button>
            </Container>
          </ReactModal>
        )}
      </Transition>
      <ResetPasswordModal {...resetPasswordModal} />
    </>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 420px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 0 20px;
`;
const Content = styled.div`
  padding: 20px 20px 16px 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  flex: 1;
  a {
    color: ${(props) => props.theme.palette.white};
    cursor: pointer;
    transition: all 300ms ease;
    text-decoration: underline;
    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }
`;
const Text = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.palette.white};
  padding: 4px 8px;
  display: flex;
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    line-height: ${(props) => props.theme.font.size.xl};
    color: ${(props) => props.theme.palette.success};
  }
`;
const ContentHeading = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.palette.secondary};
  padding: 4px 8px;
  display: flex;
`;

const RestPasswordButton = styled.div`
  font-size: ${(props) => props.theme.font.size.xm};
  color: white;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.primary};
  }
`;
