import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {

  return (
    <Container>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn label="Name"></TableHeaderColumn>
      <TableHeaderColumn label="Parent Name"></TableHeaderColumn>
      <TableHeaderColumn label="Extensions ID"></TableHeaderColumn>
      <TableHeaderColumn label="Active"></TableHeaderColumn>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  position:relative;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  & > div:nth-child(1) {
    width: 0px;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
  & > div:nth-child(3) {
    width: 350px;
  }
  & > div:nth-child(4) {
    width: 250px;
  }
  & > div:nth-child(5) {
    width: 200px;
  }
`;