/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { getCircularsApi } from "api";
import { format } from "date-fns";
import { useMainContext } from "contexts";
import "./style.scss";

export const CircularDropDown = ({
  value,
  className,
  placeholder = "Select a circular",
  width = 280,
  onSelectValue = () => {},
  isVisible,
  id = "Circular Dropdown",
}) => {
  const menuRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const { postPageEvents } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    onSelectValue(params?.uuid, data);
    setShowResults(false);
  };

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
  };

  const handleFetch = async () => {
    setIsLoading(true);
    const result = await getCircularsApi({
      page: currentPage,
      completed: false,
      sort_dir: "ASC",
    });
    setCurrentPage((page) => page + 1);
    setTotalPages(result.payload.pages);
    const newData = [...data, ...(result?.payload?.circulars || [])];
    setData(newData);
    if (newData.length > 0) {
      onSelectValue(
        !value || !newData.find((item) => item.uuid === value)
          ? newData[0]?.uuid
          : newData.find((item) => item.uuid === value)?.uuid,
        newData,
        value ? false : true
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleFetch();
    return () => {};
  }, []);

  useEffect(() => {
    if (value) {
      handleFetch();
    }
    return () => {};
  }, [value]);

  return (
    <div className={classNames(className, isVisible ? "" : "d-none")}>
      <div className="circular-dropdown" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <div className="d-flex w-100" onClick={() => setShowResults(true)}>
            {isLoading ? (
              <StyledInput type="text" value={"Loading..."} readonly />
            ) : (
              <>
                <StyledInput
                  type="text"
                  aria-labelledby={"auto complete"}
                  onFocus={() => {
                    setFocused(true);
                    postPageEvents("focus", id);
                  }}
                  onBlur={handleBlur}
                  aria-describedby={"auto complete"}
                  value={
                    data.find((item) => item?.uuid === value)
                      ? `${
                          data.find((item) => item?.uuid === value)?.retailer
                            ?.name
                        }_${format(
                          new Date(
                            data.find(
                              (item) => item?.uuid === value
                            )?.circ_week_dt
                          ),
                          "MM/dd/yyyy"
                        )}`
                      : ""
                  }
                  placeholder={placeholder}
                  onChange={(e) => {}}
                  readonly
                />
                {showResults ? (
                  <AiFillCaretUp
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                ) : (
                  <AiFillCaretDown
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                )}
              </>
            )}
          </div>

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} className="menu-dropdown">
            {!isLoading && data.length === 0 ? (
              <div className="menu-item text-center">No circulars</div>
            ) : (
              data.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "menu-item",
                      item?.uuid === value ? "active" : ""
                    )}
                    key={`key${index}`}
                    onClick={() => {
                      handleChange(item);
                      postPageEvents("click", "Circular Dropdown");
                    }}
                    // style={{ width }}
                  >
                    {`${item?.retailer?.name}_${format(
                      new Date(item?.circ_week_dt),
                      "MM/dd/yyyy"
                    )}`}
                  </div>
                );
              })
            )}
            {isLoading ? (
              <LoadMore>Loading...</LoadMore>
            ) : currentPage < totalPages ? (
              <LoadMore onClick={handleFetch}>Load more</LoadMore>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  cursor: pointer;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const LoadMore = styled.div`
  color: ${(props) => props.theme.palette.primaryOutline};
  cursor: pointer;
  transition: all 300ms ease;
  font-size: 16px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
`;
