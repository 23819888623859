/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";

export const RetryCroppingModal = ({ open, onClose, onOk = () => {} }) => {
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onOk();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={"Retry Auto-Cropping"}
    >
      <div>
        <div>
          There are already adblocks cropped. Are you sure to auto crop again?
        </div>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          No
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-3"
          type="button"
          onClick={() => handleOk()}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};
