import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView, Button, RiseLoader } from "components";
import { times } from "lodash";
import { useRetailersContext } from "contexts";
import { useInput } from "hooks";
import { SearchCircular } from "./components";
import { TableHeader, TableRow } from "./components";
import { getRetailerAutocompleteApi } from "api";

export const RetailersPage = () => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const search = useInput("");

  const {
    getRetailers,
    findRetailer,
    retailers = [],
    isLoading,
    isRefetching,
    totalCount = 0,
    totalPages,
    currentPage,
    loadMore,
    setFilter,
  } = useRetailersContext();

  const init = async () => {
    getRetailers();
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (params) => {
    if(params === "") {
      getRetailers();
    }else{
      findRetailer(params);
    }
  };

  const handleSelectRetailer = (params) => {
    setFilter({ retailerId: params });
  };

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
    setFilter({ sortBy: sortCol, sortDir: sortType === 1 ? "ASC" : "DESC" });
    getRetailers();
  };

  return (
    <MainLayout>
      <MainContent>
        <SideSection>
          <SideTop
            currentCount={retailers.length}
            totalCount={totalCount}
            placeholder="retailers"
            isLoading={isLoading}
          />
          <SearchCircular
            isLoading={isLoading}
            func={getRetailerAutocompleteApi}
            onSearch={handleSearch}
            onSelect={handleSelectRetailer}
            {...search}
          />
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent>
            {isLoading ? (
              times(7, String).map((index) => (
                <TableRow key={`${index}key`} isLoading />
              ))
            ) : retailers && retailers.length > 0 ? (
              retailers.map((item, index) => (
                <TableRow key={`${index}key`} data={item} />
              ))
            ) : (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                No retailers match this search request.
              </div>
            )}
            {!isLoading && isRefetching ? (
              <div className="d-flex">
                <RiseLoader className="my-4 mx-auto" />
              </div>
            ) : !isLoading && currentPage + 1 < totalPages ? (
              <div className="my-4 d-flex justify-content-center">
                <Button
                  size="small"
                  width="120px"
                  onClick={() =>
                    loadMore({
                      retailer_id: search.value,
                    })
                  }
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </TableContent>
        </TableSection>
      </MainContent> 
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;
