import React from "react";
import { OfferInfoContainer, CheckBox } from "components";
import { useMainContext } from "contexts";

export const InfoCheckBox = ({
  label,
  inputId,
  className,
  shouldMoveNextOnSelect = true,
}) => {
  const {
    moveNextField,
    setActiveField,
    updateInfo,
    currentInfo,
    setIsManualEntry,
  } = useMainContext();

  const handleChange = (value) => {
    updateInfo(inputId, value);
    shouldMoveNextOnSelect && moveNextField(value);
  };
  return (
    <OfferInfoContainer inputId={inputId} className={className}>
      <CheckBox
        inputId={inputId}
        shouldMoveNextOnSelect={shouldMoveNextOnSelect}
        value={currentInfo[inputId]}
        onChange={handleChange}
        onFocus={() => setActiveField(inputId)}
        onClick={() => setIsManualEntry()}
      >
        {label}
      </CheckBox>
    </OfferInfoContainer>
  );
};
