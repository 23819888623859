import React, { useEffect } from "react";
import styled from "styled-components";
import { Header, Footer } from "components";
import { useIdleTimer } from "react-idle-timer";
import { IdleModal } from "modals";
import { useModal } from "hooks";
import { useLocation } from "@reach/router";
import { useMainContext } from "contexts";
import { resetTimerAdblock } from "api";

export const MainLayout = ({ children, activeTab }) => {
  const idleModal = useModal();
  const location = useLocation();
  const { currentAdBlockId } = useMainContext();
  useEffect(() => {
    document.body.style.cursor = "default";
    return () => {};
  }, []);

  const onIdle = () => {
    if (
      location.pathname.startsWith("/offers") ||
      location.pathname.startsWith("/products") ||
      location.pathname.startsWith("/adblocks")
    ) {
      idleModal.openModal();
      console.log("idle...");
    }
  };

  const handleCloseIdle = async () => {
    idleModal.onClose();
    await resetTimerAdblock(
      currentAdBlockId,
      location.pathname.startsWith("/offers")
        ? "offers"
        : location.pathname.startsWith("/products")
        ? "products"
        : location.pathname.startsWith("/adblocks")
        ? "adblocks"
        : ""
    );
  };

  useIdleTimer({ onIdle, timeout: 90000 });

  return (
    <StyledLayout>
      <Header active={activeTab} />

      {children}
      <Footer />
      <IdleModal {...idleModal} onClose={handleCloseIdle} />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: ${(props) => props.theme.palette.canvas};
  & > div {
    min-width: 1660px;
  }
`;
