import styled from "styled-components";

export const Choice = ({
  index,
  data,
  active,
  onClick = () => {},
  showIndex = false,
}) => {
  return (
    <ChoiceContainer active={active} onClick={() => onClick(data?.value)}>
      {showIndex && <div className="bullet">{index}</div>}
      <div className="title">{data?.title}</div>
    </ChoiceContainer>
  );
};
const ChoiceContainer = styled.div`
  width: 100%;
  margin: 2px 0;
  display: flex;
  width: 100%;
  padding: 8px;
  background: ${(props) =>
    props.active ? props.theme.palette.items : props.theme.palette.gray};
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
  .bullet {
    min-width: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.gray};
    border: 1px solid ${(props) => props.theme.palette.secondary};
    color: white;
    font-size: 16px;
    text-align: center;
    margin: auto 0 auto 8px;
  }
  .title {
    color: white;
    font-size: 16px;
    margin-left: 16px;
    /* line-height: 48px; */
  }
`;
