import React from "react";
import styled from "styled-components";

export const ProductInfoContainer = ({
  children,
  active,
  className,
  onClick = () => { },
}) => {
  return (
    <Container className={className} active={active} onClick={onClick}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  padding: 4px 0 4px 8px;
  border-left: 4px solid
    ${(props) =>
    props.active ? props.theme.palette.primaryHover : "transparent"};
    
  &:focus, &:active{
    border-left: 4px solid ${(props) => props.theme.palette.primaryHover};
  }
  &:focus-within {
    border-left: 4px solid ${(props) => props.theme.palette.primaryHover};
  }

  &.width-80 {
    width:80px;
  }

`;
