import React from "react";
import { useMainContext } from "contexts";
import { MetaCheckBox } from "components";

export const MetaData = ({ className }) => {
  const { metaData, updateMetaData } = useMainContext();
  return (
    <div className={className}>
      {metaData.map((item, index) => (
        <MetaCheckBox
          key={`key${index}`}
          value={item.value}
          onChange={(e) => updateMetaData(item.key, e)}
        >
          {item.label}
        </MetaCheckBox>
      ))}
    </div>
  );
};
