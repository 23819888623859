/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  Button,
  DropDown2,
  MetaCheckBox,
  Input2,
  CheckBox,
  ScrollView,
  ProductBrandAutoComplete,
  ProductSelector,
  FilterableDropDown,
  AdblockLocation,
  AdblockImage,
  SearchableDropDown,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  CreateBrand,
  HistoryDropdown,
} from "components";
import {
  getNextProduct,
  getImage,
  approveProduct,
  getProductBrandAutocompleteApi,
  skipTopDownAdblock,
  doNotEnterTopDownAdblock,
  getProductSizesApi,
  updateAdblockSuggestions,
  getBrandByIdApi,
  rejectAdblock,
} from "api";
import { useMainContext, useAdblockHistoryContext } from "contexts";
import { MdRefresh } from "react-icons/md";
import { EndQueueModal, RejectWarningModal, ErrorModal } from "modals";
import { isEmpty, omit } from "lodash";
import { format } from "date-fns";
import {
  Card,
  ProductInfoContainer,
  Choice,
  NoSuggestions,
} from "./components";
import { useInput, useModal } from "hooks";
import { useParams } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useKeypress from "react-use-keypress";
import { FaClone, FaTrash, FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import { findDuplicates } from "utils";

export const ProductsPage = () => {
  const params = useParams();
  const blockRef = useRef();

  const {
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    setSessionId,
  } = useMainContext();

  const { initHistory, pushHistory } = useAdblockHistoryContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);

  //Currently Viewed Information
  const [circularName, setCircularName] = useState("");
  const [currentAdBlockData, setCurrentAdBlockData] = useState({});
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");

  //Adblock Data
  const events = useInput([]);
  const newItem = useInput(false);
  const selectedVarieties = useInput(false);
  const blkqty = useInput(false);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });

  //Product list from adblock
  const [productsData, setProductsData] = useState([]);
  const currentProductIndex = useInput(0);
  const [currentProduct, setCurrentProduct] = useState({});
  const [allProductsValid, setAllProductsValid] = useState(false);
  const [queueCount, setQueueCount] = useState("");
  const [messageID, setMessageID] = useState(false);

  // Product Information
  const brand = useInput("");
  const brand_nm = useInput("");
  const dimensions = useInput("");
  const unit = useInput("");
  const unit2 = useInput("");
  const size1 = useInput("");
  const size2 = useInput("");
  const size3 = useInput("");
  const size4 = useInput("");
  const variant = useInput("");
  const variant_nm = useInput("");
  const origin = useInput("");
  const origin_nm = useInput("");
  const liner = useInput(false);

  //Optional Product Information
  const [variantsData, setVariantsData] = useState([]);
  const [originData, setOriginData] = useState([]);

  // Form Dropdown sets
  const [dimensionData, setDimensionData] = useState([]);
  const [sizeData, setSizeData] = useState([]);

  //Form Flow
  const [formWorkflow, setFormWorkflow] = useState({});
  const [activeProductInfo, setActiveProductInfo] = useState("ext_brand_i");
  const [activeProductText, setActiveProductText] = useState("");

  // suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);

  // Create Brand
  const [showCreateBrand, setShowCreateBrand] = useState(false);
  const [createBrandName, setCreateBrandName] = useState("");

  // Suggestions general
  const [brands, setBrands] = useState([]);
  // const [defaultSizeData, setDefaultSizeData] = useState([]);
  const [unitDimensions, setUnitDimensions] = useState([
    {
      id: 1,
      title: "Single",
      subSets: ["ext_size_unit_i", "size_1"],
    },
    {
      id: 2,
      title: "Range",
      subSets: ["ext_size_unit_i", "size_1", "size_2"],
    },
    {
      id: 5,
      title: "SizeAndQty",
      subSets: [
        "ext_size_unit_i",
        "size_1",
        "size_2",
        "ext_size_unit_2_i",
        "size_3",
        "size_4",
      ],
    },
  ]);
  const [sizes, setSizes] = useState([]);
  const [units, setUnits] = useState([]);

  //Modals and user infromation
  const endQueueModal = useModal();
  const rejectModal = useModal();
  const duplicatedModal = useModal();

  const [isEndQueue, setIsEndQueue] = useState(false);
  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  const updateProductSize = async () => {
    const res = await getProductSizesApi();
    setSizeData(
      res.payload.map((item) => ({ id: item.ext_id, title: item.size_c }))
    );
  };

  const init = async () => {
    setCurrentPage("TD - Products");
    setPreviousTime(new Date());
    setIsLoading(false);

    const workFlow = ["ext_brand_i"];
    setFormWorkflow(workFlow);

    setImageHeight(blockRef.current.offsetHeight);
    setImageWidth(blockRef.current.offsetWidth);

    // let res = await getProductDimensionApi();
    setDimensionData(
      [
        {
          id: 1,
          title: "Single",
          subSets: ["ext_size_unit_i", "size_1"],
        },
        {
          id: 2,
          title: "Range",
          subSets: ["ext_size_unit_i", "size_1", "size_2"],
        },
        {
          id: 5,
          title: "SizeAndQty",
          subSets: [
            "ext_size_unit_i",
            "size_1",
            "size_2",
            "ext_size_unit_2_i",
            "size_3",
            "size_4",
          ],
        },
      ]
      // res.payload.map((item) => ({ id: item.ext_id, title: item.name }))
    );
    await updateProductSize();

    //Go and grab the first adblock in the queue
    fetchAdBlock();
    // const res = await getProductSizesApi();
    // setSizeData(
    //   res.payload.map((item) => ({ id: item.ext_id, title: item.size_c }))
    // );
    // setDefaultSizeData(
    //   res.payload.map((item) => ({ id: item.ext_id, title: item.size_c }))
    // );
  };

  useEffect(() => {
    if (!brand.value) {
      updateProductSize();
    }
    return () => {};
  }, [brand.value]);

  function validateProduct(product) {
    //Validate product on change, check the required fields

    // var tempDimensionData = dimensionData;
    // if (dimensionData.length < 1) {
    //   tempDimensionData = [
    //     {
    //       id: 1,
    //       title: "Single",
    //       subSets: ["ext_size_unit_i", "size_1"],
    //     },
    //     {
    //       id: 2,
    //       title: "Range",
    //       subSets: ["ext_size_unit_i", "size_1", "size_2"],
    //     },
    //     {
    //       id: 5,
    //       title: "SizeAndQty",
    //       subSets: [
    //         "ext_size_unit_i",
    //         "size_1",
    //         "size_2",
    //         "ext_size_unit_2_i",
    //         "size_3",
    //       ],
    //     },
    //   ];
    // }

    product.isValid = true;
    if (product["ext_brand_i"] === null) {
      product.isValid = false;
    } else {
      if (
        product["size_dimension_i"] !== null &&
        product["size_dimension_i"] !== ""
      ) {
        // var dimensionSubSet =
        //   tempDimensionData.find((x) => x.id === product["size_dimension_i"])
        //     ?.subSets || [];
        // dimensionSubSet.forEach((element) => {
        //   //skip size_2 if it is size and qty, no validation
        //   if (element === "size_2" && product["size_dimension_i"] === 5) {
        //     return;
        //   } else {
        //     if (
        //       (product[element] === null || product[element] === "") &&
        //       product.isValid
        //     ) {
        //       product.isValid = false;
        //     }
        //   }
        // });
      } else {
        product.isValid = false;
      }
    }

    return product.isValid;
  }

  /*
  ///////////////////////////////////////
  ////// Watchers /////////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    initHistory("product");
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    let validCheck = productsData.every(function (e) {
      return e.isValid;
    });

    if (validCheck) {
      setAllProductsValid(true);
    } else {
      setAllProductsValid(false);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsData]);

  useEffect(() => {
    if (!isEmpty(currentProduct)) {
      brand.setValue(currentProduct.ext_brand_i);
      brand_nm.setValue(currentProduct.brand_nm);
      dimensions.setValue(currentProduct.size_dimension_i);
      size1.setValue(
        currentProduct?.size_1 !== null ? currentProduct?.size_1 : ""
      );
      size2.setValue(
        currentProduct?.size_2 !== null ? currentProduct?.size_2 : ""
      );
      size3.setValue(
        currentProduct?.size_3 !== null ? currentProduct?.size_3 : ""
      );
      size4.setValue(
        currentProduct?.size_4 !== null ? currentProduct?.size_4 : ""
      );
      unit.setValue(currentProduct.ext_size_unit_i);
      unit2.setValue(currentProduct.ext_size_unit_2_i);
      liner.setValue(currentProduct.liner_f);

      if (currentProduct.variant) {
        variant.setValue(currentProduct.variant.ext_id);
        variant_nm.setValue(currentProduct.variant.variety_nm);
      } else {
        variant.setValue(currentProduct.variant_id);
        variant_nm.setValue(currentProduct.variant_nm);
      }

      if (currentProduct.origin) {
        origin.setValue(currentProduct.origin.ext_id);
        origin_nm.setValue(currentProduct.origin.origin_nm);
      } else {
        origin.setValue(currentProduct.origin_id);
        origin_nm.setValue(currentProduct.origin_nm);
      }

      setActiveProductInfo("ext_brand_i");

      if (currentProduct.variantsData) {
        setVariantsData(currentProduct.variantsData);
      } else {
        //Reset the optional fields
        setVariantsData([]);
      }

      if (currentProduct.originsData) {
        setOriginData(currentProduct.originsData);
      } else {
        setOriginData([]);
      }
    }

    return () => {};
  }, [currentProduct]);

  useEffect(() => {
    if (currentProductIndex.value < productsData.length) {
      setCurrentProduct(
        productsData.find(
          (product) => product.index === currentProductIndex.value
        )
      );
    }
    return () => {};
  }, [currentProductIndex.value]);

  useEffect(() => {
    if (!isEmpty(currentProduct) && activeProductInfo) {
      const polar_question = [
        { title: "No", value: false },
        { title: "Yes", value: true },
      ];

      switch (activeProductInfo) {
        case "ext_brand_i":
          if (brands.length > 0) {
            setCurrentSuggestions(
              brands.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.brand_name,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText("What brand is this product?");
          break;
        case "size_dimension_i":
          if (unitDimensions.length > 0) {
            setCurrentSuggestions(
              unitDimensions.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.name,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText("What is this products unit dimensions?");
          break;
        case "ext_size_unit_i":
        case "ext_size_unit_2_i":
        case "ext_size_unit_3_i":
          if (units.length > 0) {
            setCurrentSuggestions(
              units.slice(0, 9).map((item, index) => {
                return {
                  value: item.ext_id,
                  title: item.size_c,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText(
            "What is the type of unit " + activeProductInfo + " ?"
          );
          break;
        case "size_1":
        case "size_2":
        case "size_3":
        case "size_4":
          if (sizes.length > 0) {
            setCurrentSuggestions(
              sizes.slice(0, 9).map((item, index) => {
                return {
                  value: item.size,
                  title: item.size,
                };
              })
            );
          } else {
            setCurrentSuggestions([]);
          }
          setActiveProductText("What is the " + activeProductInfo + " amount?");
          break;
        case "liner_f":
          setCurrentSuggestions(polar_question);
          setActiveProductText("Is this product a liner?");
          break;
        default:
          setCurrentSuggestions([]);
          break;
      }
    }
    return () => {};
  }, [activeProductInfo, currentProduct]);

  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchAdBlock = async () => {
    setIsFetching(true);
    setIsManualEntry(false);

    setProductsData([]);
    const result = await getNextProduct();

    if (result.success) {
      //Even if we have success we might not get a payload because end of queue sends a 204 reponse
      if (result.payload !== "") {
        setIsEndQueue(false);
        setCurrentAdBlockData(result.payload.adblock);
        setCurrentAdBlockId(result.payload.adblock.uuid);
        setCurrentPageId(result.payload.page.uuid);
        setCurrentCircularId(result.payload.circular.uuid);
        pushHistory({
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        setCircularName(
          result.payload.circular.retailer.name +
            "_" +
            format(new Date(result.payload.circular.circ_week_dt), "MM/dd/yyyy")
        );

        setAdblockCoords({
          coords_x1: result.payload.adblock.coords_x1,
          coords_x2: result.payload.adblock.coords_x2,
          coords_y1: result.payload.adblock.coords_y1,
          coords_y2: result.payload.adblock.coords_y2,
        });
        setPageWidth(result.payload.page.img_width);
        setPageHeight(result.payload.page.img_height);
        setQueueCount(result.payload.queue_count);
        setMessageID(result.payload.message_id);
        setSessionId(result.payload.message_id);
        events.setValue(
          result.payload.adblock?.event_nm
            ? result.payload.adblock?.event_nm.split(",")
            : []
        );

        const backImg = await getImage(result.payload.page.filename);
        setPageImg(backImg.payload);

        //TODO: If we do not find any suggestions we should put the user directly into manual entry
        if (result.payload.adblock.suggestions?.products) {
          //Set brands
          if (result.payload.adblock.suggestions?.brands) {
            setBrands(result.payload.adblock.suggestions?.brands);
          } else {
            setBrands([]);
          }

          //Set dimensions
          if (result.payload.adblock.suggestions?.dimensions) {
            setUnitDimensions(result.payload.adblock.suggestions?.dimensions);
          } else {
            setUnitDimensions([]);
          }

          //Set sizes
          if (result.payload.adblock.suggestions?.sizes) {
            setSizes(result.payload.adblock.suggestions?.sizes);
          } else {
            setSizes([]);
          }

          //Set units
          if (result.payload.adblock.suggestions?.units) {
            setUnits(result.payload.adblock.suggestions?.units);
          } else {
            setUnits([]);
          }

          //We need to set the index so we can find out what product is selected later
          const productsTemp = result.payload.adblock.suggestions.products.map(
            (product, index) => ({
              ...product,
              brand_nm: product.brand_nm || product.brand?.brand_name,
              isValid: validateProduct(product),
              index,
              variantsData:
                product.ext_brand_i !== null
                  ? result.payload.adblock.suggestions?.brands.find(
                      (brand) => brand.ext_id === product.ext_brand_i
                    ).variants > 0
                    ? result.payload.adblock.suggestions?.brands
                        .find((brand) => brand.ext_id === product.ext_brand_i)
                        .variants.map((item) => ({
                          id: item.ext_id,
                          title: item.variety_nm,
                        }))
                    : []
                  : [],
              originsData:
                product.ext_brand_i !== null
                  ? result.payload.adblock.suggestions?.brands.find(
                      (brand) => brand.ext_id === product.ext_brand_i
                    ).origins.length > 0
                    ? result.payload.adblock.suggestions?.brands
                        .find((brand) => brand.ext_id === product.ext_brand_i)
                        .origins.map((item) => ({
                          id: item.ext_id,
                          title: item.origin_nm,
                        }))
                    : []
                  : [],
            })
          );

          setProductsData(productsTemp);
          if (productsTemp.length > 0) {
            currentProductIndex.setValue(0);
            setCurrentProduct(productsTemp[0]);
          }
        } else {
          setProductsData([]);
        }

        blkqty.setValue(result.payload.adblock.bulkqty_f);
        newItem.setValue(result.payload.adblock.newitem_f);
        selectedVarieties.setValue(result.payload.adblock.select_varieties_f);
        setAdblockFilename(result.payload.adblock.filename);
      } else {
        setProductsData([]);
        setQueueCount(0);
        setIsEndQueue(true);
      }
    } else {
      //TODO: system message
    }
    postPageEvents("ready", "");
    setIsFetching(false);
  };

  const navigateNext = async () => {
    fetchAdBlock();
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async () => {
    setIsSubmitting(true);
    const result = await rejectAdblock(currentAdBlockId, "products");

    if (result?.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    const int_keys = ["size_1", "size_2", "size_3", "size_4"];

    let products = productsData.map((product) => omit(product, ["index"]));
    products.forEach((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (value === "" && int_keys.indexOf(key) !== -1) {
          product[key] = null;
        }
      });
    });

    if (findDuplicates(products)) {
      duplicatedModal.openModal();
      return;
    }
    setIsSubmitting(true);
    let data = {
      adblock: {
        bulkqty_f: blkqty.value,
        newitem_f: newItem.value,
        select_varieties_f: selectedVarieties.value,
        event_nm: events.value.join(),
      },
      products: products,
      message_id: messageID,
    };

    const result = await approveProduct(currentAdBlockId, data);
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    const result = await skipTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "products"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    const result = await doNotEnterTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "products"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  /*
  ///////////////////////////////////////
  ////// Product Controls /////////////
  /////////////////////////////////////
  */

  const handleAddProduct = () => {
    postPageEvents("click", "Add Product");
    const newIndex =
      productsData.length > 0
        ? productsData[productsData.length - 1].index + 1
        : 0;
    setProductsData([
      ...productsData,
      {
        ext_brand_i: null,
        brand_nm: "",
        size_dimension_i: null,
        size_1: "",
        size_2: "",
        size_3: "",
        size_4: "",
        ext_size_unit_i: null,
        ext_size_unit_2_i: null,
        variant_id: null,
        variant_nm: "",
        origin_id: null,
        origin_nm: "",
        liner: false,
        index: newIndex,
        isValid: false,
      },
    ]);

    //Reset the optional fields
    setVariantsData([]);
    setOriginData([]);

    currentProductIndex.setValue(newIndex);
  };

  const handleRemoveProduct = (event, index) => {
    postPageEvents("click", "Remove Product");
    event.stopPropagation();
    let temp = [...productsData];

    if (!index) {
      index = temp.findIndex((product) => product.index === index);
    }

    temp.splice(index, 1);

    const newProductsData = temp.map((item, index) => ({
      ...item,
      isValid: validateProduct(item),
      index,
    }));
    console.log(
      "new products",
      newProductsData,
      currentProductIndex.value,
      index
    );
    setProductsData(newProductsData);
    if (newProductsData.length === 0) {
      setCurrentProduct({});
      currentProductIndex.setValue(0);
    } else if (index === 0) {
      currentProductIndex.setValue(newProductsData[0].index);
      setCurrentProduct(newProductsData[0]);
    } else if (index === currentProductIndex.value) {
      currentProductIndex.setValue(newProductsData[index - 1].index);
      setCurrentProduct(newProductsData[index - 1]);
    } else if (currentProductIndex.value === newProductsData.length) {
      currentProductIndex.setValue(
        newProductsData[newProductsData.length - 1].index
      );
      setCurrentProduct(newProductsData[newProductsData.length - 1]);
    } else {
      // Do nothing if we dont need to udpate offer
    }
  };

  const handleCloneProduct = (event, index) => {
    postPageEvents("click", "Clone Product");
    if (productsData.length > 0) {
      let temp = [...productsData];

      if (!index) {
        index = temp.findIndex(
          (offer) => offer.index === currentProductIndex.value
        );
      }

      const clonedOffer = { ...productsData[index] };
      const newIndex = productsData[productsData.length - 1].index + 1;
      setProductsData([
        ...productsData,
        { ...clonedOffer, index: newIndex, uuid: undefined },
      ]);
      currentProductIndex.setValue(newIndex);
    }
  };

  const handleProductSelect = (index) => {
    currentProductIndex.setValue(index);
  };

  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      if (result.payload.suggestions.products.length > 0) {
        //Set brands
        if (result.payload?.suggestions?.brands) {
          setBrands(result.payload?.suggestions?.brands);
        } else {
          setBrands([]);
        }

        //Set dimensions
        if (result.payload?.suggestions?.dimensions) {
          setUnitDimensions(result.payload?.suggestions?.dimensions);
        } else {
          setUnitDimensions([]);
        }

        //Set sizes
        if (result.payload?.suggestions?.sizes) {
          setSizes(result.payload?.suggestions?.sizes);
        } else {
          setSizes([]);
        }

        //Set units
        if (result.payload?.suggestions?.units) {
          setUnits(result.payload?.suggestions?.units);
        } else {
          setUnits([]);
        }

        //We need to set the index so we can find out what product is selected later
        const productsTemp = result.payload.suggestions.products.map(
          (product, index) => ({
            ...product,
            brand_nm: product.brand_nm || product.brand?.brand_name,
            isValid: validateProduct(product),
            index,
          })
        );

        setProductsData(productsTemp);

        if (productsTemp.length > 0) {
          currentProductIndex.setValue(0);
          setCurrentProduct(productsTemp[0]);
        }
      } else {
        setProductsData([]);
      }
    }
    setSuggestionsLoading(false);
  };

  /*
  ////////////////////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  ///////////////////////////////////////////////////
  */

  //This is where we update any of the actual data that needs to be captured, the other information is temp
  const handleChangeProductInfo = (key, value, title) => {
    let temp = [...productsData];
    temp[currentProductIndex.value][key] = value;

    temp[currentProductIndex.value].isValid = validateProduct(
      temp[currentProductIndex.value]
    );

    //We need to update the brand name as well
    if (key === "ext_brand_i") {
      temp[currentProductIndex.value]["brand_nm"] = title;
    }

    //We need to update the variant name as well
    if (key === "variant_id") {
      temp[currentProductIndex.value]["variant_nm"] = title;
    }

    //We need to update the origin name as well
    if (key === "origin_id") {
      temp[currentProductIndex.value]["origin_nm"] = title;
    }

    setProductsData(temp);
  };

  // This is specificly when a user clicks or selects the suggestion
  const handleSuggestionClick = (selectedItem) => {
    //We can set this here but we need to focus the other inputs after setting
    if (selectedItem === "Manual") {
      setIsManualEntry(true);
    }

    switch (activeProductInfo) {
      case "ext_brand_i":
        if (selectedItem === "Manual") {
          brand.setValue("");
          brand_nm.setValue("");
          setVariantsData([]);
          setOriginData([]);
          //setSizeData();
        } else {
          let temp = [...productsData];

          if (selectedItem?.variants) {
            const variantsData = selectedItem?.variants.map((item) => ({
              id: item.ext_id,
              title: item.variety_nm,
            }));
            temp[currentProductIndex.value]["variantsData"] = variantsData;
            setVariantsData(variantsData);
          }

          if (selectedItem?.origins) {
            const originsData = selectedItem?.origins.map((item) => ({
              id: item.ext_id,
              title: item.origin_nm,
            }));

            temp[currentProductIndex.value]["originsData"] = originsData;
            setOriginData(originsData);
          }

          // if (selectedItem?.sizes) {
          //   setSizeData(
          //     selectedItem?.sizes.map((item) => ({
          //       id: item.ext_id,
          //       title: item.size_c,
          //     }))
          //   );
          // }

          setProductsData(temp);

          brand.setValue(selectedItem.value);
          brand_nm.setValue(selectedItem.title);
        }
        break;
      case "size_dimension_i":
        if (selectedItem !== "Manual") {
          dimensions.setValue(selectedItem.value);
        }
        break;
      case "ext_size_unit_i":
        if (selectedItem !== "Manual") {
          unit.setValue(selectedItem.value);
        }
        break;
      case "ext_size_unit_2_i":
        if (selectedItem.value !== "Manual") {
          unit2.setValue(selectedItem.value);
        }
        break;
      case "size_1":
        if (selectedItem === "Manual") {
          size1.setValue(0);
        } else {
          size1.setValue(selectedItem.value);
        }
        break;
      case "size_2":
        if (selectedItem === "Manual") {
          size2.setValue(0);
        } else {
          size2.setValue(selectedItem.value);
        }
        break;
      case "size_3":
        if (selectedItem === "Manual") {
          size3.setValue(0);
        } else {
          size3.setValue(selectedItem.value);
        }
        break;
      case "size_4":
        if (selectedItem === "Manual") {
          size4.setValue(0);
        } else {
          size4.setValue(selectedItem.value);
        }
        break;
      case "liner_f":
        if (selectedItem !== "Manual") {
          liner.setValue(selectedItem.value);
        }
        break;
      default:
        break;
    }

    postPageEvents("click", selectedItem?.title);
    //If we are manual dont try to find the next offer info
    if (selectedItem !== "Manual") {
      handleChangeProductInfo(
        activeProductInfo,
        selectedItem.value,
        selectedItem.title
      );
      handleNextFormElement(selectedItem);
    }
  };

  // When the user selects a brand they, might have more fields depending upon the brand
  // We only do this if they are manually selecting a different brand
  const handleBrandSelect = async (name, e) => {
    //When deleting, no event comes back
    if (!e) {
      return;
    }

    var existingOne = false;
    if (currentProduct.suggestions && currentProduct.suggestions.brands) {
      existingOne = currentProduct.suggestions.brands.find(
        (brand) => brand.ext_id === e.ext_id
      );
    }

    let temp = [...productsData];

    if (existingOne) {
      const variantsData = (existingOne.variants || []).map((item) => ({
        id: item.ext_id,
        title: item.variety_nm,
      }));
      const originsData = (existingOne.origins || []).map((item) => ({
        id: item.ext_id,
        title: item.origin_nm,
      }));

      setVariantsData(variantsData);
      setOriginData(originsData);

      // setSizeData(
      //   (existingOne.sizes || []).map((item) => ({
      //     id: item.ext_id,
      //     title: item.size_c,
      //   }))
      // );

      temp[currentProductIndex.value]["variantsData"] = variantsData;
      temp[currentProductIndex.value]["originsData"] = originsData;
      setProductsData(temp);
    } else {
      handleChangeProductInfo("ext_brand_i", e.ext_id, e.brand_name);
      const res = await getBrandByIdApi(e.uuid);
      if (res.success) {
        if (res.results?.variants) {
          const variantsData = res.results.variants.map((item) => ({
            id: item.ext_id,
            title: item.variety_nm,
          }));
          temp[currentProductIndex.value]["variantsData"] = variantsData;
          setVariantsData(variantsData);
        }

        if (res.results?.origins) {
          const originsData = res.results.origins.map((item) => ({
            id: item.ext_id,
            title: item.origin_nm,
          }));
          temp[currentProductIndex.value]["originsData"] = originsData;
          setOriginData(originsData);
        }

        // if (res.results?.sizes && res.results?.sizes.length > 0) {
        //   setSizeData(
        //     res.results.sizes.map((item) => ({
        //       id: item.ext_id,
        //       title: item.size_c,
        //     }))
        //   );
        // } else {
        //   setSizeData(defaultSizeData);
        // }

        handleNextFormElement(e, res.results);
        setProductsData(temp);
      } else {
        //TODO: System error message
      }
    }
  };

  //Changes active product on click for forms
  const onFormElementClick = (params) => {
    setIsManualEntry(true);
    setActiveProductInfo(params);
  };

  //Changes active product on click for forms
  const handleBrandClick = (event, params) => {
    //Only trigger this if the user is clicking on the input
    if (event.target.tagName.toUpperCase() === "INPUT") {
      brand.setValue("");
      brand_nm.setValue("");

      //Reset the optional fields
      setVariantsData([]);
      setOriginData([]);

      handleChangeProductInfo("ext_brand_i", null, "");
      onFormElementClick(params);

      //Small hack to focus the element after we clear the brand information
      setTimeout(() => {
        var input = document.getElementById(params);
        if (input) {
          input.focus();
        }
      });
    }
  };

  const handleNextFormElement = (selectedObject, additionalBrandInfo) => {
    let tempWorkFlow = [...formWorkflow];

    if (
      variantsData.length > 0 ||
      (additionalBrandInfo && additionalBrandInfo.variants.length > 0)
    ) {
      tempWorkFlow.push("brand_variant");
    }

    if (
      originData.length > 0 ||
      (additionalBrandInfo && additionalBrandInfo.origins.length > 0)
    ) {
      tempWorkFlow.push("brand_origin");
    }

    //Since we dont know if we have variants or origins we add size_dimension_i after we
    // make a determination
    tempWorkFlow.push("size_dimension_i");

    //We need to just take the value if it is size dimension since it doesnt set right away
    let dimensionValue = dimensions.value;
    if (activeProductInfo === "size_dimension_i") {
      dimensionValue = selectedObject.value;
    }

    //Find the path we need to take
    if (dimensionValue) {
      let subSets =
        dimensionData.find((item) => item.id === dimensionValue)?.subSets || [];
      tempWorkFlow = tempWorkFlow.concat(subSets);
    }

    //Always apend the liner as the last element in the workflow
    tempWorkFlow.push("liner_f");

    //Find the current item
    const idx = tempWorkFlow.findIndex((item) => item === activeProductInfo);
    if (idx < tempWorkFlow.length - 1) {
      //Because our fields are dynamic, the fields rendering seems slower than the state render
      //This is a simple hack to make sure we always render the state, even after elements on
      //page render
      setTimeout(() => {
        if (isFetching) {
          setActiveProductInfo(tempWorkFlow[idx]);
        } else {
          setActiveProductInfo(tempWorkFlow[idx + 1]);
        }
      });
    }
  };

  const handleCreateBrand = (id, title, e) => {
    setCreateBrandName("");
    setShowCreateBrand(false);
    brand.setValue(params);
    brand_nm.setValue(title);
    handleBrandSelect(title, e);
  };

  const handleEventsChange = (value) => {
    events.setValue(value);
  };

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var selectedItem;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        selectedItem = "Manual";
      } else {
        selectedItem = currentSuggestions[parseInt(event.key) - 1];
      }

      handleSuggestionClick(selectedItem);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      setIsManualEntry(false);
    }
  });

  //Remove Products
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveProduct();
    // }
  });

  //Add Products
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddProduct();
    }
  });

  //Clone Products
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneProduct();
    // }
  });

  //Cycle through products
  useKeypress(["["], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      //If we go backwards to the start, go back to top.
      if (currentProductIndex.value === 0) {
        currentProductIndex.setValue(productsData.length - 1);
      } else {
        currentProductIndex.setValue(currentProductIndex.value - 1);
      }
    } else {
      //If we go forward to the end, go back to start.
      if (currentProductIndex.value === productsData.length - 1) {
        currentProductIndex.setValue(0);
      } else {
        currentProductIndex.setValue(currentProductIndex.value + 1);
      }
    }
  });

  //Approve Products
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      handleApprove();
    }
  });

  //Approve Products
  useKeypress(["w", "W"], (event) => {
    if (event.altKey) {
      handleDoNotEnterAdblock();
    }
  });

  //Approve Products--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //Approve Products
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {/* <BreadCrumb className="ml-1 my-auto">Circulars</BreadCrumb> */}
                <BreadCrumb className="ml-3 my-auto">{circularName}</BreadCrumb>
                <BreadCrumb className="ml-3 my-auto">
                  {currentPageId}
                </BreadCrumb>
                <HistoryDropdown className="ml-4" width={320} type="product" />
              </div>
              <PageInfo className="ml-auto my-auto ">
                {`Remaing in queue:  ${queueCount}`}{" "}
              </PageInfo>
              <>
                <Button
                  buttonTheme="dark"
                  width="160px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={handleDoNotEnterAdblock}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  DO NOT ENTER
                </Button>
                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={handleSkipAdblock}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  SKIP
                </Button>

                <Button
                  buttonTheme="dark"
                  width="100px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => rejectModal.openModal()}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  REJECT
                </Button>
                <Button
                  buttonTheme={`${allProductsValid ? "primary" : "dark"}`}
                  width="120px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={handleApprove}
                  isLoading={isSubmitting}
                  disabled={queueCount === 0}
                  eventType="flag"
                >
                  APPROVE
                </Button>
              </>
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onChange={handleEventsChange}
              />
              {productsData.length > 0 && (
                <div className="mt-3">
                  <MetaCheckBox {...blkqty}>Bulk Quantity</MetaCheckBox>
                  <MetaCheckBox {...newItem}>New Item</MetaCheckBox>
                  <MetaCheckBox {...selectedVarieties}>
                    Selected Varieties
                  </MetaCheckBox>
                </div>
              )}
            </MetadataSection>
          </div>
          <AdblockImage
            filename={adblockFilename}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            queueCount={queueCount}
            currentAdBlockData={currentAdBlockData}
            isEndQueue={isEndQueue}
          />

          <div className="d-flex flex-column">
            <ProductSelector
              title={`PRODUCTS ${
                productsData.length > 0
                  ? productsData.findIndex(
                      (product) => product.index === currentProductIndex.value
                    ) + 1
                  : 0
              }/${productsData.length}`}
              onAdd={handleAddProduct}
              isAddable={productsData.length > 0}
            >
              {queueCount > 0 ? (
                productsData.length === 0 ? (
                  <NoItems className="mt-2">
                    <div>There is no product available.</div>
                    <div>
                      {" "}
                      <span
                        className="cursor-pointer"
                        onClick={handleAddProduct}
                      >
                        <u>Create</u>
                      </span>{" "}
                      a new product or flag the adblock appropriately.
                    </div>
                  </NoItems>
                ) : (
                  <div className="d-flex">
                    <ItemsList>
                      {productsData.map((product, index) => (
                        <Items
                          key={index}
                          className="d-flex flex-row"
                          active={index === currentProductIndex.value}
                          onClick={() => handleProductSelect(index)}
                        >
                          <ProductSelectedBar
                            active={index === currentProductIndex.value}
                          ></ProductSelectedBar>
                          <ProductValidator valid={product.isValid}>
                            {product.isValid ? (
                              <FaCheckCircle />
                            ) : (
                              <FaMinusCircle />
                            )}
                          </ProductValidator>

                          {product.brand_nm ? (
                            // Range
                            product.size_dimension_i === 2 ? (
                              <div className="flex-1">
                                {product.brand_nm} {product.variant_nm}{" "}
                                {product.origin_nm} {product?.size_1}
                                {product?.size_2
                                  ? "-" + product?.size_2 + " "
                                  : ""}{" "}
                                {product?.ext_size_unit_i
                                  ? sizeData.find(
                                      (size) =>
                                        size.id === product.ext_size_unit_i
                                    )?.title
                                  : ""}
                              </div>
                              // Size Qty
                            ) : product.size_dimension_i === 5 ? (
                              <div className="flex-1">
                                {product.brand_nm} {product.variant_nm}{" "}
                                {product.origin_nm} {product?.size_1}
                                {product?.size_2
                                  ? "-" + product?.size_2 + " "
                                  : ""}{" "}
                                {product?.ext_size_unit_i
                                  ? sizeData.find(
                                      (size) =>
                                        size.id === product.ext_size_unit_i
                                    )?.title
                                  : ""}{" "}
                                {product?.size_3 ? "of " + product?.size_3 : ""}
                                {product?.size_4
                                  ? "-" + product?.size_4 + " "
                                  : ""}{" "}
                                {product?.ext_size_unit_2_i
                                  ? " " +
                                    sizeData.find(
                                      (size) =>
                                        size.id === product?.ext_size_unit_2_i
                                    ).title
                                  : ""}
                              </div>
                              // Single
                            ) : (
                              <div className="flex-1">
                                {product.brand_nm} {product.variant_nm}{" "}
                                {product.origin_nm} {product?.size_1}{" "}
                                {product?.ext_size_unit_i
                                  ? sizeData.find(
                                      (size) =>
                                        size.id === product.ext_size_unit_i
                                    )?.title
                                  : ""}
                              </div>
                            )
                          ) : (
                            <div className="flex-1">{`Unbranded Product ${
                              index + 1
                            }`}</div>
                          )}

                          <ButtonIcon
                            active={productsData.length > 0}
                            onClick={(event) =>
                              handleCloneProduct(event, product?.index)
                            }
                          >
                            <FaClone />
                          </ButtonIcon>

                          <ButtonIcon
                            active={productsData.length > 0}
                            onClick={(event) =>
                              handleRemoveProduct(event, product?.index)
                            }
                          >
                            <FaTrash />
                          </ButtonIcon>
                        </Items>
                      ))}
                    </ItemsList>
                  </div>
                )
              ) : null}
            </ProductSelector>
            <div className="d-flex flex-1">
              {showCreateBrand ? (
                <CreateBrand
                  onCancel={() => {
                    setShowCreateBrand(false);
                  }}
                  onOk={handleCreateBrand}
                  originName={createBrandName}
                />
              ) : (
                <SelectionContainer>
                  <SelectionHeader>
                    QUICK SELECTION
                    {queueCount > 0 ? (
                      <MdRefresh
                        size={18}
                        onClick={refreshAdblockSuggestions}
                      />
                    ) : null}
                  </SelectionHeader>

                  <SelectionContent>
                    {isManualEntry && (
                      <ManualEntry onExit={() => setIsManualEntry(false)} />
                    )}

                    {queueCount > 0 ? (
                      isFetching || suggestionsLoading ? (
                        <div>Loading...</div>
                      ) : productsData.length === 0 ? (
                        <div className="mt-4 pt-4 text-center">
                          No product available
                        </div>
                      ) : (
                        <>
                          <div className="text-blue-light mb-3">
                            {activeProductText}
                          </div>

                          {currentAdBlockData?.approved ? (
                            <AdblockApproved />
                          ) : currentSuggestions.length > 0 ? (
                            <>
                              {!isManualEntry && (
                                <Choice
                                  index={0}
                                  showIndex={!isManualEntry}
                                  data={{
                                    title: "Manual Entry",
                                    value: "Manual",
                                  }}
                                  active={false}
                                  onClick={handleSuggestionClick}
                                />
                              )}

                              {currentSuggestions.map((item, index) => (
                                <Choice
                                  key={`${index}key`}
                                  showIndex={!isManualEntry}
                                  index={index + (isManualEntry ? 0 : 1)}
                                  data={item}
                                  active={
                                    currentProduct &&
                                    currentProduct[activeProductInfo] ===
                                      item.value
                                  }
                                  onClick={() => handleSuggestionClick(item)}
                                />
                              ))}
                            </>
                          ) : (
                            <>
                              <NoSuggestions />
                            </>
                          )}
                        </>
                      )
                    ) : null}
                  </SelectionContent>
                </SelectionContainer>
              )}
              <InfoSection
                title="ADBLOCK PRODUCT INFORMATION"
                className="flex-1 flex-column"
              >
                {productsData.length === 0 ? (
                  <div className="mt-4 pt-4 text-center">
                    No product available
                  </div>
                ) : (
                  <ScrollView>
                    <form autoComplete="off">
                      <input
                        style={{ display: "none" }}
                        autoComplete="false"
                      ></input>

                      <ProductInfoContainer
                        className="mt-3"
                        active={["ext_brand_i"].includes(activeProductInfo)}
                      >
                        <ProductBrandAutoComplete
                          label="Brand"
                          idField="ext_id"
                          inputId="ext_brand_i"
                          disabled={!isManualEntry}
                          func={getProductBrandAutocompleteApi}
                          onClick={(event) =>
                            handleBrandClick(event, "ext_brand_i")
                          }
                          onFocus={() => onFormElementClick("ext_brand_i")}
                          onSelectValue={handleBrandSelect}
                          value={brand.value}
                          displayValue={brand_nm.value}
                          onChange={(e) => {
                            brand.setValue(e !== "Manual" ? e : null);
                            handleChangeProductInfo("ext_brand_i", e);
                          }}
                          onCreateNew={(name) => {
                            setShowCreateBrand(true);
                            setCreateBrandName(name);
                          }}
                        />
                      </ProductInfoContainer>

                      {variantsData.length !== 0 && (
                        <ProductInfoContainer
                          className="mt-3"
                          active={["brand_variant"].includes(activeProductInfo)}
                        >
                          <FilterableDropDown
                            label="Variant (optional)"
                            data={variantsData}
                            disabled={!isManualEntry}
                            value={variant.value}
                            displayValue={variant_nm.value}
                            onFocus={() => onFormElementClick("brand_variant")}
                            onClick={() => onFormElementClick("brand_variant")}
                            onSelectValue={(object) => {
                              handleNextFormElement({ value: object.id });
                            }}
                            onChange={(value, title) => {
                              variant.setValue(
                                value !== "Manual" ? value : null
                              );
                              handleChangeProductInfo(
                                "variant_id",
                                value,
                                title
                              );
                            }}
                          />
                        </ProductInfoContainer>
                      )}

                      {originData.length !== 0 && (
                        <ProductInfoContainer
                          className="mt-3"
                          active={["brand_origin"].includes(activeProductInfo)}
                        >
                          <FilterableDropDown
                            label="Origin (optional)"
                            disabled={!isManualEntry}
                            data={originData}
                            onFocus={() => onFormElementClick("brand_origin")}
                            onClick={() => onFormElementClick("brand_origin")}
                            value={origin.value}
                            displayValue={origin_nm.value}
                            onSelectValue={(object) => {
                              handleNextFormElement({ value: object.id });
                            }}
                            onChange={(value, title) => {
                              origin.setValue(
                                value !== "Manual" ? value : null
                              );
                              handleChangeProductInfo(
                                "origin_id",
                                value,
                                title
                              );
                            }}
                          />
                        </ProductInfoContainer>
                      )}

                      <ProductInfoContainer
                        className="mt-3"
                        active={["size_dimension_i"].includes(
                          activeProductInfo
                        )}
                      >
                        <DropDown2
                          label="Unit Dimensions"
                          data={dimensionData}
                          disabled={!isManualEntry}
                          onClick={() => onFormElementClick("size_dimension_i")}
                          onFocus={() => onFormElementClick("size_dimension_i")}
                          onSelectValue={(object) => {
                            handleNextFormElement({ value: object?.id });
                          }}
                          value={dimensions.value}
                          onChange={(e) => {
                            if (e !== "") {
                              dimensions.setValue(e !== "Manual" ? e : null);
                              handleChangeProductInfo("size_dimension_i", e);
                            }
                          }}
                        />
                      </ProductInfoContainer>

                      {dimensions.value === 2 ? (
                        // Range
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableDropDown
                                label="ext_size_unit"
                                data={sizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_1"
                                onClick={() => onFormElementClick("size_1")}
                                onFocus={() => onFormElementClick("size_1")}
                                disabled={!isManualEntry}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                          </div>
                        </>
                      ) : dimensions.value === 5 ? (
                        // Size Qty
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableDropDown
                                label="ext_size_unit"
                                data={sizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_1"
                                onClick={() => onFormElementClick("size_1")}
                                onFocus={() => onFormElementClick("size_1")}
                                disabled={!isManualEntry}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_2"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_2"
                                onClick={() => onFormElementClick("size_2")}
                                onFocus={() => onFormElementClick("size_2")}
                                disabled={!isManualEntry}
                                value={size2.value}
                                onChange={(e) => {
                                  size2.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_2", e);
                                }}
                              />
                            </ProductInfoContainer>
                          </div>

                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_2_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableDropDown
                                label="ext_size_unit_2"
                                data={sizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_2_i")
                                }
                                disabled={!isManualEntry}
                                value={unit2.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit2.setValue(e !== "Manual" ? e : null);
                                    handleChangeProductInfo(
                                      "ext_size_unit_2_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_3"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_3"
                                onClick={() => onFormElementClick("size_3")}
                                onFocus={() => onFormElementClick("size_3")}
                                disabled={!isManualEntry}
                                value={size3.value}
                                onChange={(e) => {
                                  size3.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_3", e);
                                }}
                              />
                            </ProductInfoContainer>
                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_4"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_4"
                                onClick={() => onFormElementClick("size_4")}
                                onFocus={() => onFormElementClick("size_4")}
                                disabled={!isManualEntry}
                                value={size4.value}
                                onChange={(e) => {
                                  size4.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_4", e);
                                }}
                              />
                            </ProductInfoContainer>
                          </div>
                        </>
                      ) : (
                        // Single
                        <>
                          <div className="d-flex">
                            <ProductInfoContainer
                              className="mt-3 flex-1"
                              active={["ext_size_unit_i"].includes(
                                activeProductInfo
                              )}
                            >
                              <SearchableDropDown
                                label="ext_size_unit"
                                data={sizeData}
                                onClick={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                onFocus={() =>
                                  onFormElementClick("ext_size_unit_i")
                                }
                                disabled={!isManualEntry}
                                value={unit.value}
                                onSelectValue={(object) => {
                                  handleNextFormElement({ value: object.id });
                                }}
                                onChange={(e) => {
                                  if (e !== "") {
                                    unit.setValue(e !== "Manual" ? e : null);
                                    handleChangeProductInfo(
                                      "ext_size_unit_i",
                                      e
                                    );
                                  }
                                }}
                              />
                            </ProductInfoContainer>

                            <ProductInfoContainer
                              className="mt-3 width-80"
                              active={["size_1"].includes(activeProductInfo)}
                            >
                              <Input2
                                label="size_1"
                                onClick={() =>
                                  onFormElementClick("unit_size_1")
                                }
                                onFocus={() =>
                                  onFormElementClick("unit_size_1")
                                }
                                disabled={!isManualEntry}
                                value={size1.value}
                                onChange={(e) => {
                                  size1.setValue(e !== "Manual" ? e : 0);
                                  handleChangeProductInfo("size_1", e);
                                }}
                              />
                            </ProductInfoContainer>
                          </div>
                        </>
                      )}

                      <ProductInfoContainer
                        className="mt-3"
                        active={activeProductInfo === "liner_f"}
                      >
                        <CheckBox
                          value={liner.value}
                          onChange={(e) => {
                            liner.setValue(e !== "Manual" ? e : false);
                            handleChangeProductInfo("liner_f", e);
                          }}
                        >
                          Is this product a liner?
                        </CheckBox>
                      </ProductInfoContainer>
                    </form>
                  </ScrollView>
                )}
              </InfoSection>
            </div>
          </div>
        </Content>

        <EndQueueModal {...endQueueModal} />
        <RejectWarningModal {...rejectModal} onReject={() => handleReject()} />
        <ErrorModal {...duplicatedModal}>Duplicated Products!</ErrorModal>
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  overflow: hidden;
  color: white;
  flex: 1;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const SelectionContainer = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Items = styled.div`
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primaryOutline
        : props.theme.palette.backgrounds};
  width: 100%;
  cursor: pointer;
  div {
    background-color: ${(props) => props.theme.palette.items};
    margin: 0 1px;
    padding: 6px 10px;
  }
`;

const ProductSelectedBar = styled.div`
  width: 4px;
  padding: 0 !important;
  margin: 0 !important;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds} !important;
`;

const ProductValidator = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.palette.success : props.theme.palette.error};
`;

const SelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  color: white;
`;

const ButtonIcon = styled.div`
  color: ${(props) => (props.active ? "white" : "#333")};
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const NoItems = styled.div`
  font-size: 14px;
`;

const BreadCrumb = styled.div`
  color: white;
  font-size: 14px;
`;
