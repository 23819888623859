import React from "react";
import { OfferInfoContainer, DropDown3 } from "components";
import { useMainContext } from "contexts";
export const InfoDropDown = ({
  label,
  inputId,
  data,
  className,
  shouldMoveNextOnSelect = true,
  onChange = () => {},
}) => {
  const { setIsManualEntry } = useMainContext();

  return (
    <OfferInfoContainer className={className} inputId={inputId}>
      <DropDown3
        inputId={inputId}
        label={label}
        data={data}
        shouldMoveNextOnSelect={shouldMoveNextOnSelect}
        onChange={onChange}
        onClick={() => setIsManualEntry(true)}
      />
    </OfferInfoContainer>
  );
};
