import React, { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import AuthReducer from "./AuthReducer";
import { navigate } from "@reach/router";
import { TOKEN_EXPIRATION } from "config";
import { clearAllCookies } from "utils";
import { isEqual } from "lodash";
import { LOGIN, LOGOUT, SYNC_REQUEST, SET_USER_MODE } from "./../types";
export const authInitState = {
  isLogined: false,
  userInfo: {},
};

export const AuthContext = createContext(authInitState);
export const useAuthContext = () => useContext(AuthContext);
const STORAGE_KEY = "addison-user-state";

export const AuthProvider = ({ children }) => {
  const initialState = {
    isLogined: false,
    userMode: "normal",
    userInfo: {},
    appInfo: {},
  };
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [state, dispatch] = useReducer(AuthReducer, value);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();

      if (
        localStorage.getItem("addison_token_expire") &&
        now.getTime() > parseInt(localStorage.getItem("addison_token_expire"))
      ) {
        logoutUser(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let parsed = typeof value === "string" ? JSON.parse(value) : value;
    if (!isEqual(parsed, state)) {
      dispatch({
        type: SYNC_REQUEST,
        payload: parsed,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    set(state);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const loginUser = async (payload) => {
    const now = new Date();
    localStorage.setItem(
      "addison_token_expire",
      now.getTime() + TOKEN_EXPIRATION
    );
    dispatch({ type: LOGIN, payload });
  };

  const logoutUser = (isExpire) => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("user");
    localStorage.removeItem("addison_token_expire");
    localStorage.removeItem("addison-user-state");
    localStorage.removeItem("addison-main-state");
    localStorage.removeItem("addison-circular-state");
    clearAllCookies();
    navigate(`/${isExpire ? "?token_expire=true" : ""}`);
  };

  const setUserMode = (payload) => {
    dispatch({
      type: SET_USER_MODE,
      payload,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginUser,
        logoutUser,
        setUserMode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
