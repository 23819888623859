/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { MainLayout } from "layouts";
import { useModal } from "hooks";
import {
  Button,
  Alert,
  PageDropDown,
  Link,
  ColorPick,
  AdblockToolButton,
  AdblockIncentives,
  AdblockSection,
  AdblockItem,
} from "components";
import { EndQueueModal, RetryCroppingModal } from "modals";
import { useParams, navigate } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import {
  getImage,
  getPageCrops,
  approvePage,
  getCircularById,
  rejectPage,
  postPageAutoCrop,
  getPageSections,
  savePageIncentiveString,
  saveCircularIncentiveString,
} from "api";
import styled from "styled-components";
import useKeypress from "react-use-keypress";
import "cropperjs/dist/cropper.css";
import { fabric } from "fabric";
import { useAuthContext, useMainContext } from "contexts";
import { format } from "date-fns";

const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const AdBlockPage = () => {
  const params = useParams();
  const blockRef = useRef();

  const {
    currentCircularId,
    currentPageId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    setSessionId,
  } = useMainContext();

  //Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isAdCropping, setIsAdCropping] = useState(false);

  //Circular data
  const [circularName, setCircularName] = useState("");

  //Page data
  const [pagesData, setpagesData] = useState([]);
  const [pageData, setpageData] = useState([]);

  //Adblock data
  const [adBlockData, setAdBlockData] = useState([]);
  const [type, setType] = useState("adblock");

  // Canvas information
  const [canvas, setCanvas] = useState("");

  const [activeCanvasObject, setActiveCanvasObject] = useState(null);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [canvasRatio, setCanvasRatio] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState("");

  //Error messages
  const [errorText, setErrorText] = useState("");

  //Modals and user information
  const alertModal = useModal();
  const retryCroppingModal = useModal();
  const endQueueModal = useModal();
  const { userInfo } = useAuthContext();

  // View info
  const [currentAdblockId, setCurrentAdblockId] = useState("");
  const [currentOutlineColor, setCurrentOutlineColor] = useState(
    OUTLINE_COLORS[0]
  );
  var started = false;
  var x = 0;
  var y = 0;

  // Incentive data
  const [incentiveData, setIncentiveData] = useState([]);
  const handleAddIncentive = () => {
    setIncentiveData([...incentiveData, { type: "page", text: "" }]);
  };
  const handleRemoveIncentive = (index) => {
    let temp = [...incentiveData];
    temp.splice(index, 1);
    setIncentiveData(temp);
  };
  const handleUpdateIncentive = (index, data) => {
    let temp = [...incentiveData];
    temp.splice(index, 1, data);
    setIncentiveData(temp);
  };

  // Section Data
  const handleSectionSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);
      await postPageEvents("click", `Section ${index}`);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Start Methods //////////////////
  /////////////////////////////////////
  */

  const init = async (type) => {
    // display dotted boundary when over rect
    canvas.on("mouse:over", function (e) {
      handleCanvisObjectMouseOver(canvas, e);
    });
    // remove dotted boundary when over rect
    canvas.on("mouse:out", function (e) {
      if (e?.target) {
        e.target.set("strokeDashArray", null);

        if (canvas.status === "crop") {
          e.target.hoverCursor = "crosshair";
        } else {
          e.target.hoverCursor = "default";
        }

        canvas.renderAll();
      }
    });
    // zoom when mouse wheel
    canvas.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvas.getHeight() > blockRef.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });
    // pan when alt key & mouse down
    canvas.on("mouse:down", function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else if (evt.button === 2) {
        var mouse = canvas.getPointer(evt);
        started = true;
        x = mouse.x;
        y = mouse.y;

        var today = new Date();
        this.isCreating = true;
        var rect = new fabric.Rect({
          width: 0,
          height: 0,
          left: x,
          top: y,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: type === "adblock" ? "transparent" : "#3BC7E320",
          cornerColor: "white",
          cornerStrokeColor: currentOutlineColor,
          id: "temp-" + today.getTime(),
          type,
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
        canvas.renderAll();
        canvas.setActiveObject(rect);
      }
    });

    canvas.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      } else if (this.isCreating) {
        // Resize the newly created crop while mouse draging
        if (!started) {
          return false;
        }

        let mouse = canvas.getPointer(opt.e);

        let w = mouse.x - x,
          h = mouse.y - y;

        if (!w || !h) {
          return false;
        }

        let rect = canvas.getActiveObject();
        if (rect) {
          if (opt.e.shiftKey) {
            rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
          } else {
            rect.set("width", w).set("height", h);
          }
          rect.setControlsVisibility({ mtr: false });
        }
        canvas.renderAll();
      }
    });

    canvas.on("mouse:up", function (opt) {
      const evt = opt.e;
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      this.isCreating = false;
      if (evt.button === 2) {
        if (started) {
          started = false;
        }
        // Remove if the rect is too small
        var objects = canvas.getObjects();
        objects.forEach((rect) => {
          if (
            rect &&
            (Math.abs(rect?.width) < 20 || Math.abs(rect.height) < 20)
          ) {
            canvas.remove(rect);

            // setCanvas(canvas);
          }
        });
        canvas.requestRenderAll();

        // Disable all objects unselectable
        canvas.discardActiveObject();
        objects = canvas.getObjects();
        //Create the adblocks list
        setCanvasObjects(
          objects.map((object, index) => {
            return {
              index: index,
              type: object?.type,
              uuid: object?.id,
              rejected: object?.rejected,
              approved: object?.approved,
            };
          })
        );
      }
    });

    //
    canvas.on("selection:created", function (opt) {
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      if (canvas.status === "move") {
        opt.target.hoverCursor = "move";
      }
    });

    canvas.on("selection:updated", function (opt) {
      console.log("selection:updated", opt.target.id);
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      if (canvas.status === "move") {
        opt.target.hoverCursor = "move";
      }
    });

    canvas.on("selection:cleared", function (opt) {
      console.log("selection:cleared", opt);
      setActiveCanvasObject(null);
    });
  };

  /*
  ///////////////////////////////////////
  ////// Watchers //////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    setCurrentPage("LR - Adblock");
    setPreviousTime(new Date());
    setIsLoading(false);
    setSessionId(String(new Date()));

    const canvi = new fabric.Canvas("canvas", {
      height: blockRef.current.offsetHeight,
      width: blockRef.current.offsetWidth,
      fireRightClick: true, // <-- enable firing of right click events
      stopContextMenu: true, // <--  prevent context menu from showing
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "white";
    fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 8;
    setCanvas(canvi);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canvas) {
      init(type);
    }

    return () => {};
  }, [type, canvas]);

  useEffect(() => {
    //If deeplink and we have both the circular and the page, go to them
    if (params?.circular !== "0" && params?.page !== "0") {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);

      //If we only know the circular like we normally do from the circular list page, select the first page
    } else if (params?.circular !== "0" && params?.page === "0") {
      setCurrentCircularId(params?.circular);
      setCurrentPageId("");
    }

    //TODO: we need to do something if the user gets to this page without any params

    return () => {};
  }, [params?.circular, params?.page]);

  useEffect(() => {
    if (currentCircularId) {
      fetchCircular();
    }
    return () => {};
  }, [currentCircularId]);

  /*
  ///////////////////////////////////////
  ////// Fetch Data //////////////////
  /////////////////////////////////////
  */

  const fetchCircular = async () => {
    const result = await getCircularById(currentCircularId);
    if (result.success) {
      setCircularName(
        result.payload.retailer.name +
          "_" +
          format(new Date(result.payload.circ_week_dt), "MM/dd/yyyy")
      );
    } else {
      //TODO: set error message about no circular matching
    }
  };

  const onPageSelect = async (params, pages = [], page) => {
    setCurrentPageId(params);

    navigate(`/adblock/${currentCircularId}/${params}`);
    if (page && page.filename) {
      setpageData(page);
      await fetchImage(page.filename, params);
      if (params) {
        setpagesData(pages);
      }
    }
    await postPageEvents("ready", "");
  };

  const navigateNext = async () => {
    const idx = pagesData.findIndex((item) => item.uuid === currentPageId);
    if (idx < pagesData.length - 1) {
      setCurrentPageId(pagesData[idx + 1]?.uuid);
      onPageSelect(pagesData[idx + 1]?.uuid, pagesData, pagesData[idx + 1]);
      canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      await postPageEvents("click", "Next");
    } else {
      endQueueModal.openModal();
    }
  };

  const fetchImage = async (id, pageId) => {
    setIsFetching(true);
    let cir = await getCircularById(currentCircularId);
    if (cir.success) {
      if (cir.payload.incentive_nm) {
        setIncentiveData([
          {
            type: "circular",
            text: cir.payload.incentive_nm,
          },
        ]);
      } else {
        setIncentiveData([]);
      }
    }
    const img = await getImage(id);
    if (img.success) {
      canvas.clear();
      canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      let vpt = canvas.viewportTransform;
      vpt[4] = 0;
      vpt[5] = 0;

      var background = new Image();
      background.onload = async function () {
        setIsFetching(true);
        var f_img = new fabric.Image(background);
        setCanvasRatio(canvas.height / background.height);
        canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.height / background.height,
          scaleY: canvas.height / background.height,
        });

        let results = await getPageSections(pageId);
        if (results.success) {
          // setAdBlockData(results.payload.adblocks);
          drawAdblocks(
            canvas.height / background.height,
            results.payload.sections,
            "section"
          );
        } else {
          //TODO: Warning, no adblocks created
        }

        results = await getPageCrops(pageId);
        if (results.success) {
          setAdBlockData(results.payload.adblocks);
          drawAdblocks(
            canvas.height / background.height,
            results.payload.adblocks,
            "adblock"
          );
        } else {
          //TODO: Warning, no adblocks created
        }
        //Create the adblocks list
        var objects = canvas.getObjects();
        if (objects) {
          const canvasObjects = objects.map((object, index) => {
            return {
              index: index,
              type: object.type,
              uuid: object?.id,
              rejected: object?.rejected,
              approved: object?.approved,
              text: "",
            };
          });
          setCanvasObjects(canvasObjects);
        } else {
          setCanvasObjects([]);
        }
        setIsFetching(false);
      };

      background.src = "data:image/png;base64," + img.payload;
    } else {
      //TODO: If image doesnt load show and error
    }
    setIsFetching(false);
  };

  const drawAdblocks = (canvasRatio, adBlockData, type) => {
    // Draw block rect data if available

    if (adBlockData.length > 0) {
      adBlockData.forEach((adblock) => {
        var fill_color = "transparent";
        var stroke_color = currentOutlineColor;
        //if status is reject or approve display something diffrent
        if (adblock.rejected) {
          fill_color = "rgba(255,132,132,0.35)";
          stroke_color = "#FF8484";
        } else if (adblock.approved) {
          fill_color = "rgba(99,206,107,0.25)";
          stroke_color = "#63CE6B";
        }

        const rect = new fabric.Rect({
          left: adblock.coords_x1 * canvasRatio,
          top: adblock.coords_y1 * canvasRatio,
          height: (adblock.coords_y2 - adblock.coords_y1) * canvasRatio,
          width: (adblock.coords_x2 - adblock.coords_x1) * canvasRatio,
          stroke: stroke_color,
          strokeWidth: 1,
          fill: type === "adblock" ? fill_color : "#3BC7E320",
          cornerColor: "white",
          cornerStrokeColor: stroke_color,
          id: adblock.uuid,
          rejected: adblock.rejected,
          approved: adblock.approved,
          type: type,
          text: type === "section" ? adblock?.event_nm || "" : "",
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
      });
      canvas.renderAll();
      setCanvas(canvas);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Page Flags //////////////////
  /////////////////////////////////////
  */

  const handleReject = async () => {
    setIsSubmitting(true);
    let data = {};
    try {
      await rejectPage(currentPageId, data);
      navigateNext();
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);
    const crops = canvas.getObjects();
    const adblocks = crops
      .filter((crop) => crop.type === "adblock")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }

        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
        };
      });
    const sections = crops
      .filter((crop) => crop.type === "section")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }
        let event_nm = "";
        const sec = canvasObjects.find((item) => item.uuid === crop.id);
        if (sec) {
          event_nm = sec.text;
        }
        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
          event_nm,
        };
      });
    if (incentiveData.filter(({ type }) => type === "page").length > 0) {
      await savePageIncentiveString(
        currentPageId,
        incentiveData
          .filter(({ type }) => type === "page")
          .map((item) => item.text)
          .join(",")
      );
    }
    if (incentiveData.filter(({ type }) => type === "circular").length > 0) {
      await saveCircularIncentiveString(
        currentCircularId,
        incentiveData
          .filter(({ type }) => type === "circular")
          .map((item) => item.text)
          .join(",")
      );
    }
    try {
      await approvePage(currentPageId, { adblocks, sections });
      setAdBlockData([]);
      setCanvasObjects([]);
      canvas.clear();
      navigateNext();
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  /*
  ///////////////////////////////////////
  ////// Auto Crop //////////////////
  /////////////////////////////////////
  */

  const handleAutoCrop = async (force = false) => {
    setIsAdCropping(true);
    const result = await postPageAutoCrop(currentPageId, force);
    if (result.success) {
      fetchImage(pageData.filename, currentPageId, result.payload.adblocks);
    } else {
      //TODO: set auto crop fail here
    }
    setIsAdCropping(false);
  };

  /*
  ///////////////////////////////////////
  ////// Canvas Tools //////////////////
  /////////////////////////////////////
  */

  const handleClickAdblockType = () => {
    setType("adblock");
  };
  const handleClickSectionType = () => {
    setType("section");
  };

  const handleCanvisObjectMouseOver = (canvi, event) => {
    if (event?.target && canvi.status !== "crop") {
      if (event.target === canvi.getActiveObject()) {
        event.target.hoverCursor = "move";
      }
      event.target.set("strokeDashArray", [5]);
      canvi.renderAll();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Adblock Methods  //////////////////
  /////////////////////////////////////
  */

  function handleListDelete(selected) {
    //Find the object in the list, create new list
    let newCanvasObjects = canvasObjects
      .filter((item) => item.uuid !== selected.uuid)
      .map((object, index) => {
        return {
          index: index,
          uuid: object?.uuid,
          type: object?.type,
          text: object?.text,
          rejected: object?.rejected,
          approved: object?.approved,
        };
      });
    const seletedIndex = canvasObjects.findIndex(
      (item) => item.uuid === selected.uuid
    );
    //select the object first
    canvas.setActiveObject(canvas.item(seletedIndex));
    setCurrentAdblockId("");
    // //delete the object
    canvas.remove(canvas.item(seletedIndex));
    //Update the new list after deletion
    canvas.requestRenderAll();

    setCanvasObjects(newCanvasObjects);
  }

  function handleSingleDelete() {
    //Find the object in the list, create new list
    let newCanvasObjects = canvasObjects.filter(
      (item) => item.uuid !== activeCanvasObject.id
    );
    newCanvasObjects = newCanvasObjects.map((object, index) => {
      return {
        index: index,
        uuid: object?.uuid,
      };
    });

    // //delete the object
    canvas.remove(activeCanvasObject);
    canvas.requestRenderAll();

    //Update the new list after deletion
    setCanvasObjects(newCanvasObjects);
  }

  const handleAdblockSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);

      await postPageEvents("click", `Adblock ${index}`);
    }
  };

  const handlePickColor = async (color) => {
    setCurrentOutlineColor(color);
    canvas.getObjects().map(function (blocks) {
      var stroke_color = color;
      //if status is reject or approve display something diffrent
      if (blocks.rejected) {
        stroke_color = "#FF8484";
      } else if (blocks.approved) {
        stroke_color = "#63CE6B";
      }

      return blocks.set({
        stroke: stroke_color,
        cornerStrokeColor: stroke_color,
      });
    });
    canvas.requestRenderAll();
    await postPageEvents("click", `Color ${color}`);
  };

  const handleClickAutoCrop = async () => {
    await postPageEvents("click", `Auto Crop`);
    if (adBlockData.length > 0) {
      retryCroppingModal.openModal();
    } else {
      handleAutoCrop(false);
    }
  };

  const handleChangeText = (id, text) => {
    setCanvasObjects(
      canvasObjects.map((obj) => {
        if (obj?.uuid === id) {
          return { ...obj, text };
        }
        return obj;
      })
    );
  };

  /*
  ///////////////////////////////////////
  ////// Keyboard events  //////////////////
  /////////////////////////////////////
  */

  useKeypress(["Escape"], (event) => {
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    setCurrentAdblockId("");
  });

  useKeypress(["Delete"], (event) => {
    if (currentAdblockId) {
      handleListDelete({ uuid: currentAdblockId }, selectedIndex);
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {!userInfo.user.is_superuser ? (
                  <>
                    <dvi className="ml-1 my-auto">Circulars</dvi>
                    <div className="ml-2 my-auto">{circularName}</div>
                  </>
                ) : (
                  <>
                    <Link href="/circular/" className="ml-1 my-auto">
                      Circulars
                    </Link>
                    <Link
                      href={`/circular/${currentCircularId}/0`}
                      className="ml-2 my-auto"
                    >
                      {circularName}
                    </Link>
                  </>
                )}

                <PageDropDown
                  className="ml-3"
                  id={currentCircularId}
                  onSelectValue={onPageSelect}
                  value={currentPageId}
                  isVisible={userInfo?.user?.is_superuser}
                />
              </div>
              <PageInfo className="ml-auto my-auto ">{`Page ${
                pagesData.findIndex((item) => item?.uuid === currentPageId) < 0
                  ? 0
                  : pagesData.findIndex(
                      (item) => item?.uuid === currentPageId
                    ) + 1
              }/${pagesData.length}`}</PageInfo>
              <Button
                buttonTheme={`${pageData.rejected ? "error" : "dark"}`}
                width="100px"
                size="small"
                className="ml-3 my-auto"
                onClick={handleReject}
                isLoading={isSubmitting}
                eventType="flag"
                disabled={isFetching || isLoading || pageData.crop_approved}
              >
                {pageData.rejected ? "REJECTED" : "REJECT"}
              </Button>
              <Button
                buttonTheme={`${pageData.crop_approved ? "green" : "dark"}`}
                width="120px"
                size="small"
                className="ml-2 my-auto"
                onClick={handleApprove}
                isLoading={isSubmitting}
                eventType="flag"
                disabled={isFetching || isLoading || pageData.rejected}
              >
                {pageData.crop_approved ? "APPROVED" : "APPROVE"}
              </Button>
            </div>
          )}
        </Header>

        <Content>
          <ToolContent>
            <AdblockToolButton
              onClick={handleClickAdblockType}
              type="Adblock"
              isActive={type === "adblock"}
            />
            <AdblockToolButton
              onClick={handleClickSectionType}
              type="Section"
              isActive={type === "section"}
            />
          </ToolContent>
          <div className="d-flex flex-1">
            <SelectionContainer>
              <SelectionHeader>
                PAGE ADBLOCK CROPPING
                <div className="ml-auto d-flex">
                  {OUTLINE_COLORS.map((color) => {
                    return (
                      <ColorPick
                        bgColor={color}
                        key={color}
                        active={color === currentOutlineColor}
                        onClick={() => handlePickColor(color)}
                      />
                    );
                  })}
                  {!isAdCropping ? (
                    <MdRefresh
                      className="refresh ml-2 my-auto"
                      size={18}
                      onClick={handleClickAutoCrop}
                    />
                  ) : null}
                  {activeCanvasObject ? (
                    <FaTrash
                      className="delete ml-2 my-auto mr-2"
                      onClick={handleSingleDelete}
                    />
                  ) : null}
                </div>
              </SelectionHeader>

              {isAdCropping || isFetching || isSubmitting ? (
                <CanvasOverlay>
                  {isAdCropping ? ( //TODO: we can rotate text and make it look like it is processing
                    <AutoCropping height={canvas.height} width={canvas.width}>
                      Recropping adblocks for this page, please do not refresh
                      or close the page. This process can take a minute to 90
                      seconds.
                    </AutoCropping>
                  ) : null}

                  {isFetching ? (
                    <SkeletonContainer>
                      <Skeleton height={canvas.height} width={canvas.width} />
                    </SkeletonContainer>
                  ) : null}
                </CanvasOverlay>
              ) : null}

              <CanvasContainer className="w-100" ref={blockRef}>
                <Canvas id="canvas" />
              </CanvasContainer>
            </SelectionContainer>
          </div>
          <RightSection>
            <AdblockIncentives
              onAdd={handleAddIncentive}
              onDelete={handleRemoveIncentive}
              onUpdate={handleUpdateIncentive}
              data={incentiveData}
            />
            <AdblocksContainer>
              <SelectionHeader>
                <span>{canvasObjects.length}</span>&nbsp; ADBLOCKS
              </SelectionHeader>
              <SelectionContent>
                {isLoading || isSubmitting || isFetching ? (
                  <Skeleton count={10} height={40} style={{ marginTop: 8 }} />
                ) : (
                  <>
                    {canvasObjects.map((item, index) =>
                      item.type === "adblock" ? (
                        <AdblockItem
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleAdblockSelect(item.uuid, index)}
                        />
                      ) : item.type === "section" ? (
                        <AdblockSection
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleSectionSelect(item.uuid, index)}
                          onChangeText={(e) => handleChangeText(item.uuid, e)}
                        />
                      ) : null
                    )}
                  </>
                )}
              </SelectionContent>
            </AdblocksContainer>
          </RightSection>
        </Content>
        <Alert {...alertModal} title="Error" size="small">
          {errorText}
        </Alert>
        <EndQueueModal circularId={currentCircularId} {...endQueueModal} />
        <RetryCroppingModal
          onOk={() => handleAutoCrop(true)}
          {...retryCroppingModal}
        />
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 14px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const ToolContent = styled.div`
  width: 64px;
  color: white;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.backgrounds};
  margin: 2px 2px;
  padding: 8px 0;
`;

const SkeletonContainer = styled.div`
  margin-top: -4px;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const SelectionContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  position: relative;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;
  margin-bottom: 5px;
  display: flex;
  svg {
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SelectionContent = styled.div`
  text-transform: uppercase;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const AdblocksContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const CanvasContainer = styled.div`
  height: calc(100% - 30px) !important;
  background-color: ${(props) => props.theme.palette.items};
`;

const CanvasOverlay = styled.div`
  height: calc(100% - 30px) !important;
  position: absolute;
  z-index: 1;
`;

const AutoCropping = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  background-color: ${(props) => props.theme.palette.items};
  padding: 20px;
  color: ${(props) => props.theme.palette.white};
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;
