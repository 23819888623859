import { API } from "utils";
import { COUNT_PER_PAGE } from "config";

export const postPageEvents = async (data) => {
  try {
    const response = await API().post(`api/user/page-events`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getAllUsers = async ({
  page = 0,
  per_page = COUNT_PER_PAGE,
  sort_by = "username",
  sort_dir = "ASC",
}) => {
  try {
    const response = await API().get("api/user/all", {
      params: {
        page,
        per_page,
        sort_by,
        sort_dir
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const postCreateUser = async (data) => {
  try {
    const response = await API().post(`api/user/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const postUpdateUser = async (data) => {
  try {
    const response = await API().put(`api/user/${data.id}/update`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

