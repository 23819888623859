/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocalstorageState } from "rooks";
import { postPageEvents } from "api";
import { last, indexOf } from "lodash";
import { INIT_OFFER_METADATA } from "config";

export const MainInitState = {
  currentCircularId: null,
  currentPageId: null,
  currentAdBlockId: null,
  currentUserId: "",
  currentPage: "",
  previousTime: "",
};

export const MainContext = createContext(MainInitState);
export const useMainContext = () => useContext(MainContext);
const STORAGE_KEY = "addison-main-state";

export const MainProvider = ({ children }) => {
  const [currentCircularId, setCurrentCircularId] = useState(null);
  const [currentPageId, setCurrentPageId] = useState(null);
  const [currentAdBlockId, setCurrentAdBlockId] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [previousTime, setInnerPreviousTime] = useState("");
  const [buffer, setBuffer] = useState([]);
  const [activeField, setActiveField] = useState("");
  const [fieldNames, setFieldNames] = useState([]);
  const [currentInfo, setCurrentInfo] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [currentInfoType, setCurrentInfoType] = useState("");
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [metaData, setMetaData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [value, set] = useLocalstorageState(STORAGE_KEY, MainInitState);

  useEffect(() => {
    set({
      currentInfo,
      fieldNames,
      activeField,
      currentCircularId,
      currentPageId,
      currentAdBlockId,
      currentUserId,
      currentPage,
      buffer,
      previousTime,
      currentInfoType,
      sessionId,
      metaData,
      isManualEntry,
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentInfo,
    fieldNames,
    activeField,
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    currentUserId,
    currentPage,
    buffer,
    previousTime,
    currentInfoType,
    sessionId,
    metaData,
    isManualEntry,
  ]);

  const handlePageEvents = (event_type, element_name, event_dt) => {
    if (currentCircularId && currentPageId) {
      setBuffer([
        ...buffer,
        {
          circular_uuid: currentCircularId,
          page_uuid: currentPageId,
          adblock_uuid: currentAdBlockId,
          page_name: currentPage,
          user_id: currentUserId,
          session_uuid: sessionId,
          event_type: event_type,
          element_name: element_name,
          event_dt: event_dt || Math.floor(Date.now() / 1000),
        },
      ]);
      setInnerPreviousTime(new Date());
    }
  };

  const pushPageEvents = async () => {
    await postPageEvents(buffer);
    setBuffer([]);
  };

  const moveNextField = (value) => {
    if (activeField && fieldNames.length > 0) {
      if (activeField === last(fieldNames)) {
        setActiveField("");
      } else {
        let count = 1;
        if (
          (activeField === "coupon_f" ||
            activeField === "rebate_f" ||
            activeField === "min_qty_dollar_f") &&
          !value
        ) {
          count = 2;
        }
        setActiveField(fieldNames[indexOf(fieldNames, activeField) + count]);
      }
    }
  };
  const movePreviousField = (value) => {
    if (activeField && fieldNames.length > 0) {
      if (activeField === fieldNames[0]) {
        setActiveField("");
      } else {
        let count = 1;
        const idx = fieldNames.findIndex((item) => item === activeField);
        const prevField = fieldNames[idx - 1];
        if (
          (prevField === "coupon_amount" && currentInfo[prevField]) ||
          (prevField === "rebate_amount" && currentInfo[prevField]) ||
          (prevField === "min_qty" && currentInfo[prevField])
        ) {
          count = 2;
        }
        setActiveField(fieldNames[indexOf(fieldNames, activeField) - count]);
      }
    }
  };

  const handleSetFieldNames = (e = []) => {
    setFieldNames(e);
    if (e?.length > 0) {
      setActiveField(e[0]);
    }
  };

  const updateInfo = (key, value) => {
    setCurrentInfo({ ...currentInfo, [key]: value });
  };

  const updateInfos = (data) => {
    setCurrentInfo({ ...currentInfo, ...data });
  };

  const updateMetaData = (key, value) => {
    setMetaData(
      metaData.map((item) => (item.key === key ? { ...item, value } : item))
    );
  };

  const updateActiveInfo = (value) => {
    setCurrentInfo({ ...currentInfo, [activeField]: value });
  };

  const setMetaDataFromPayload = (payload) => {
    let temp = INIT_OFFER_METADATA;
    console.log("meta data", [...temp]);
    setMetaData(
      temp.map((item) => ({
        ...item,
        value: payload[item.key] ? true : false,
      }))
    );
    console.log(
      "updated metadata",
      payload,
      temp.map((item) => ({
        ...item,
        value: payload[item.key] ? true : false,
      }))
    );
  };

  const getMetaData = () => {
    let result = {};
    metaData.forEach((item) => {
      result[item.key] = item.value;
    });
    return result;
  };

  const handleSetActiveField = (params) => {
    setActiveField(params);
  };

  const handleClear = () => {
    setBuffer([]);
    setCurrentCircularId(null);
    setCurrentPageId(null);
    setCurrentAdBlockId(null);
    setCurrentUserId("");
    setCurrentPage("");
    setInnerPreviousTime("");
    setActiveField("");
    setFieldNames([]);
    setCurrentInfo({});
    setSessionId({});
    setSessionId("");
    setCurrentInfoType("");
    setIsManualEntry(false);
    setMetaData([]);
    setIsSubmitting(false);
  };

  return (
    <MainContext.Provider
      value={{
        currentCircularId,
        currentPageId,
        currentAdBlockId,
        currentUserId,
        currentPage,
        previousTime,
        activeField,
        setCurrentCircularId,
        setCurrentPageId,
        setCurrentAdBlockId,
        setPreviousTime: (e) => setInnerPreviousTime(e || new Date()),
        setCurrentUserId,
        setCurrentPage,
        postPageEvents: handlePageEvents,
        pushPageEvents,
        setActiveField: handleSetActiveField,
        fieldNames,
        setFieldNames: handleSetFieldNames,
        moveNextField,
        setCurrentInfo,
        currentInfo,
        updateInfo,
        updateActiveInfo,
        activeFieldValue: currentInfo[activeField],
        setCurrentInfoType,
        currentInfoType,
        setSessionId,
        setIsManualEntry,
        isManualEntry,
        setMetaData,
        metaData,
        updateMetaData,
        setMetaDataFromPayload,
        movePreviousField,
        getMetaData,
        isSubmitting,
        setIsSubmitting,
        clear: handleClear,
        updateInfos,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
