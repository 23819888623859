import axios from "axios";
import { API } from "utils";
export const login = async (user) => {
  try {
    const response = await API().post("/api/auth/login", user);
    if (response.data.token) {
      const authHeader = "Bearer " + response.data.token.access_token;
      localStorage.setItem("user", authHeader);
      axios.defaults.headers.common["Authorization"] = authHeader;
      return { success: true, result: response.data };
    }
    return { success: false };
  } catch (e) {
    return { success: false, result: e };
  }
};

export const logout = async () => {
  localStorage.removeItem("user");
  try {
    await API().post("/api/authlogout");
    return "success";
  } catch (e) {
    return e;
  }
};

export const register = async (user) => {
  try {
    const formData = new FormData();
    formData.append("username", user.username);
    formData.append("password", user.password);
    await API().post("register", formData);

    return "success";
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (password) => {
  try {
    await API().post("/api/user/update-password", {
      password,
      password_again: password,
    });
    return { success: false };
  } catch (e) {
    return { success: false, result: e };
  }
};
