import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";
export const PageInfo = ({ className, pageData = [], adblockData = [] }) => {
  const { currentPageId, currentAdBlockId } = useMainContext();
  return (
    <Container className={className}>
      {` Page: ${
        pageData.findIndex((item) => item?.uuid === currentPageId) < 0
          ? 0
          : pageData.findIndex((item) => item?.uuid === currentPageId) + 1
      }/${pageData.length} - Adblocks ${
        adblockData.findIndex((item) => item?.uuid === currentAdBlockId) < 0
          ? 0
          : adblockData.findIndex((item) => item?.uuid === currentAdBlockId) + 1
      }/${adblockData.length}`}
    </Container>
  );
};

const Container = styled.div`
  font-size: 16px;
  color: white;
`;
