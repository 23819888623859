import styled from "styled-components";

const ColorPick = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor} !important;
  border: 2px solid
    ${(props) => (props.active ? "white" : props.theme.palette.backgrounds)} !important;
  cursor: pointer;
  margin: auto 3px;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
`;

export { ColorPick };
