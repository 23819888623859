import { API } from "utils";

export const getPageById = async (id) => {
  try {
    const response = await API().get(`api/pages/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteSectionById = async (id) => {
  try {
    const response = await API().get(`api/pages/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPageAdblocks = async (id) => {
  try {
    const response = await API().get(`api/pages/${id}/adblocks`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPageSections = async (id) => {
  try {
    const response = await API().get(`api/pages/${id}/sections`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addSection2Page = async (id, data) => {
  try {
    const response = await API().post(`api/pages/${id}/add-section`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const pageRetryOcr = async (id) => {
  try {
    const response = await API().post(`api/pages/${id}/retry-ocr`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const pageRetryAutoAdcrop = async (id) => {
  try {
    const response = await API().post(`api/pages/${id}/retry-auto-adcrop`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const pageRetryImageDownload = async (id) => {
  try {
    const response = await API().post(`api/pages/${id}/retry-image-download`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateAdblockSuggestions = async (adblockId, data) => {
  try {
    const response = await API().post(
      `api/adblocks/${adblockId}/update-suggestions`,
      data
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addAdblock2Page = async (id, data) => {
  try {
    const response = await API().post(`api/pages/${id}/add-adblock`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const approvePage = async (id, data) => {
  await API().post(`api/pages/${id}/approve`, data);
};

export const rejectPage = async (id, data) => {
  await API().post(`api/pages/${id}/reject`, data);
};

export const getPageCrops = async (id) => {
  try {
    const response = await API().get(`api/pages/${id}/adblocks-crops`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getNextPageCrop = async (id) => {
  try {
    const response = await API().get(`api/pages/next-page-crop`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const postPageAutoCrop = async (id, force = false) => {
  try {
    const response = await API().post(
      `api/pages/${id}/retry-auto-adcrop?force=${force}`
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
