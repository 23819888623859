/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";

export const OfferUpTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const variation = useInput("& Up");
  const type = useInput("$");
  const val = useInput("5");

  const variationData = [
    { id: "& Up", title: "& Up" },
    { id: "& below", title: "& below" },
    { id: "Starting @", title: "Starting @" },
    { id: "less than", title: "less than" },
    { id: "up to", title: "up to" },
    { id: "starting under", title: "starting under" },
    { id: "save up to", title: "save up to" },
  ];

  const typeData = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
  ];

  const handleUpdateValue = (variation, type, val) => {
    if (variation && type && val) {
      switch (variation) {
        case "& Up":
          onChange(`${type}${val} & Up`);
          break;
        case "& below":
          onChange(`${type}${val} & Below`);
          break;
        case "Starting @":
          onChange(`Starting @ ${type}${val}`);
          break;
        case "less than":
          onChange(`Less Than ${type}${val}`);
          break;
        case "up to":
          onChange(`Up To ${type}${val}`);
          break;
        case "starting under":
          onChange(`Starting under ${type}${val}`);
          break;
        case "save up to":
          onChange(`Save Up To ${type}${val}`);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (value) {
      if (value.indexOf("& Up") > 0) {
        const part = value.split("&")[0];
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("& Up");
      } else if (value.indexOf("& Below") > 0) {
        const part = value.split("&")[0];
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("& below");
      } else if (value.startsWith("Starting @ ")) {
        const part = value.split("@")[1].trim();
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("Starting @");
      } else if (value.startsWith("Less Than ")) {
        const part = value.split("Than")[1].trim();
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("less than");
      } else if (value.startsWith("Up To ")) {
        const part = value.split("To")[1].trim();
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("up to");
      } else if (value.startsWith("Starting under ")) {
        const part = value.split("under")[1].trim();
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("starting under");
      } else if (value.startsWith("Save Up To ")) {
        const part = value.split("To")[1].trim();
        type.setValue(part[0]);
        val.setValue(part.slice(1).trim());
        variation.setValue("save up to");
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 120, marginRight: 20 }}>
          <DropDown2
            label="Variation"
            value={variation.value}
            width={120}
            onChange={(e) => {
              variation.setValue(e);
              handleUpdateValue(e, type.value, val.value);
            }}
            data={variationData}
          />
        </div>
        <div style={{ width: 60, marginRight: 20 }}>
          <DropDown2
            label="Type"
            value={type.value}
            width={60}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(variation.value, e, val.value);
            }}
            data={typeData}
          />
        </div>
        <div style={{ width: 120, marginRight: 20 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(variation.value, type.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
