import React from "react";
import styled from "styled-components";

export const Editor = ({ value, onChange = () => {} }) => {
  return <IDE value={value} onChange={(e) => onChange(e.target.value)}></IDE>;
};

const IDE = styled.textarea`
  background: black;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }
`;
