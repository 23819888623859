/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveNextShoppingTripTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const val = useInput("7.00");
  const type = useInput("$");
  const isPurchase = useInput(false);
  const wType = useInput("");
  const wVal = useInput("");
  const suffix = useInput("");
  const typeData = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
  ];
  const typeData2 = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];
  const handleUpdateValue = (v, t, isW, wv, wt, suf) => {
    onChange(
      `Save ${t === "$" ? `${t}${v}` : `${v}${t}`} on next shopping visit${
        isW ? ` w/purchase${wt === "Unit" ? `/${wv}` : ` ${wt}${wv}`}` : ``
      }${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    if (value.startsWith("Save")) {
      let main = value.slice(4).trimStart();
      const nextIndex = main.indexOf("on next shopping visit");
      const vt = main.slice(0, nextIndex).trim();
      if (vt) {
        if (vt.startsWith("$")) {
          type.setValue("$");
          val.setValue(vt.slice(1));
        } else if (vt.endsWith("%")) {
          type.setValue("%");
          val.setValue(vt.slice(0, -1));
        }
      } else {
        type.setValue("");
        val.setValue("");
      }
      main = main.slice(nextIndex + 22).trimStart();
      if (main.startsWith("w/purchase/")) {
        isPurchase.setValue(true);
        wType.setValue("Unit");
        main = main.slice(11).trimStart();
        const sufIndex = main.indexOf(" ");
        if (sufIndex > 0) {
          wVal.setValue(main.slice(0, sufIndex).trim());
          suffix.setValue(main.slice(sufIndex + 1).trimStart());
        } else {
          wVal.setValue(main.trim());
          suffix.setValue("");
        }
      } else if (main.startsWith("w/purchase ")) {
        isPurchase.setValue(true);
        wType.setValue("$");
        main = main.slice(11).trimStart();
        const sufIndex = main.indexOf(" ");
        if (sufIndex > 0) {
          wVal.setValue(main.slice(1, sufIndex).trim());
          suffix.setValue(main.slice(sufIndex + 1).trimStart());
        } else {
          wVal.setValue(main.slice(1).trim());
          suffix.setValue("");
        }
      } else {
        suffix.setValue(main.trimStart());
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-2">Save</div>

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                e,
                type.value,
                isPurchase.value,
                wVal.value,
                wType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                val.value,
                e,
                isPurchase.value,
                wVal.value,
                wType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div className="mt-auto mb-2 ml-1" style={{ fontSize: 12 }}>
          on next shopping visit
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          label="w/purchase"
          className="mt-auto mb-2"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              val.value,
              type.value,
              e,
              wVal.value,
              wType.value,
              suffix.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={wVal.value}
            disabled={!isPurchase.value}
            onChange={(e) => {
              wVal.setValue(e);
              handleUpdateValue(
                val.value,
                type.value,
                isPurchase.value,
                e,
                wType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData2}
            width={70}
            value={wType.value || "Unit"}
            disabled={!isPurchase.value}
            onChange={(e) => {
              wType.setValue(e);
              handleUpdateValue(
                val.value,
                type.value,
                isPurchase.value,
                wVal.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 80, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                val.value,
                type.value,
                isPurchase.value,
                wVal.value,
                wType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
