/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { Button, SearchInput, ScrollView } from "components";
import { Transition } from "react-transition-group";
import { useInput } from "hooks";
import ReactModal from "react-modal";
import { FaUpload, FaSearch, FaList, FaDownload, FaBell } from "react-icons/fa";

const customStyles = (state) => {
  return {
    content: {
      padding: 0,
      borderRadius: 0,
      border: "none",
      height: "calc(100% - 50px)",
      width: "fit-content",
      top: 50,
      left: "calc(100% + 20px)",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.50)",
      transform:
        state === "entering" || state === "entered"
          ? "translateX(-440px)"
          : state === "exiting" || state === "exited"
          ? "translateX(0px)"
          : "translateX(0px)",
      transition: "all 300ms ease-in-out",
      opacity: state === "entering" || state === "entered" ? 1 : 0,
    },
    overlay: {
      background: "#0A0A0Aa0",
      transition: "all 300ms ease-in-out",
    },
  };
};
export const AlertsModal = ({ open, onClose, onBuy, userName }) => {
  const search = useInput("");
  return (
    <Transition in={open} timeout={300}>
      {(state) => (
        <ReactModal
          closeTimeoutMS={300}
          isOpen={open}
          style={customStyles(state)}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick
        >
          <Container>
            <Header>
              <div className="my-auto">
                <FaBell color="white" size={20} />
              </div>
              <span className="my-auto ml-2">{userName ?? ""} Alerts</span>
              {/* <Button className="ml-auto my-auto" width="100px" size="small">
                Mark all read
              </Button> */}
            </Header>
            <Content>
              <div className="text-center">Coming soon...</div>
              {/* <div className="d-flex">
                <SearchInput
                  className="w-100"
                  placeholder="Search Alerts"
                  {...search}
                />
                <Button
                  className="ml-2"
                  buttonTheme="dark"
                  width="100px"
                  size="normal"
                >
                  Search
                </Button>
              </div>
              <ScrollView>
                <Alert
                  type="upload"
                  name="MWF Midnight Walmart Tires"
                  error="Failed Scrapper Pilot"
                  date="04/03/2021 04:20 P<"
                />
                <Alert
                  type="scrape"
                  name="MWF Midnight Walmart Tires"
                  error="Failed Scrapper Pilot"
                  date="04/03/2021 04:20 P<"
                />
                <Alert
                  type="download"
                  name="MWF Midnight Walmart Tires"
                  error="Failed Scrapper Pilot"
                  date="04/03/2021 04:20 P<"
                />
                <Alert
                  type="parse"
                  name="MWF Midnight Walmart Tires"
                  error="Failed Scrapper Pilot"
                  date="04/03/2021 04:20 P<"
                />
              </ScrollView> */}
            </Content>
          </Container>
        </ReactModal>
      )}
    </Transition>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 420px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 0 20px;
`;
const Content = styled.div`
  padding: 20px 20px 16px 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Alert = ({ date, name, error, type }) => {
  return (
    <AlertContainer>
      <Date>{date}</Date>
      <div className="d-flex">
        <Icon>
          <IconError>!</IconError>
          {type === "upload" ? (
            <FaUpload />
          ) : type === "scrape" ? (
            <FaSearch />
          ) : type === "parse" ? (
            <FaList />
          ) : type === "download" ? (
            <FaDownload />
          ) : null}
        </Icon>
        <div>
          <Name>{name}</Name>
          <Error>{error}</Error>
        </div>
      </div>
      <div className="d-flex">
        <View className="ml-auto">View Issue</View>
      </div>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  transition: all 300ms ease;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  margin-top: 8px;
  padding-left: 8px;
  padding-bottom: 2px;
  &:hover {
    background: ${(props) => props.theme.palette.itemsHover};
  }
`;
const Icon = styled.div`
  margin-right: 8px;
  width: 30px;
  display: flex;
  position: relative;
  svg {
    font-size: 24px;
    margin: auto;
  }
`;
const IconError = styled.div`
  position: absolute;
  background: ${(props) => props.theme.palette.error};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: black;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  right: 0;
`;
const Date = styled.div`
  font-size: 8px;
  color: ${(props) => props.theme.palette.secondary};
  text-align: right;
`;
const View = styled.div`
  color: white;
  font-size: 8px;
  text-decoration: underline;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Name = styled.div`
  color: white;
  font-size: 16px;
`;
const Error = styled.div`
  color: ${(props) => props.theme.palette.error};
  font-size: 12px;
`;
