/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from "react";
import classNames from "classnames";
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const DropDown2 = ({
  id = "",
  data = [],
  onChange = () => {},
  label,
  className,
  backgroundColor = "#eee",
  width = 160,
  onClick = () => {},
  onFocus = () => {},
  onSelectValue = () => {},
  inputId,
  value,
  disabled = false,
  defaultValue,
}) => {
  const containerRef = useRef(null);
  const { postPageEvents } = useMainContext();

  const handleChange = (params, item) => {
    onChange(params);
    onSelectValue(item);

    containerRef.current && containerRef.current.blur();
    postPageEvents("click", id || label);
  };

  const handleKeyPress = (event) => {
    if (!isNaN(event.key) && event.key > 0 && event.key <= data.length) {
      onChange(data[event.key - 1].id);
      onSelectValue(data[event.key - 1].id);
      containerRef.current && containerRef.current.blur();
    }
  };

  useEffect(() => {
    if (disabled) {
      // handleChange("");
    }
    return () => {};
  }, [disabled]);

  return (
    <div
      className={classNames("d-block", className)}
      onClick={() => {
        onClick();
        postPageEvents("click", id || label);
      }}
      onFocus={() => {
        onFocus();
        postPageEvents("focus", id || label);
      }}
    >
      {label && <Label>{label}</Label>}
      <Container
        ref={containerRef}
        width={width}
        tabIndex={0}
        onKeyPress={handleKeyPress}
      >
        <div className={`menu-button ${disabled ? "disabled" : ""}`}>
          <span className="mr-auto">
            {data.length > 0
              ? data.find((item) => item.id === value)?.title
              : ""}
          </span>
          <AiFillCaretDown
            color="white"
            className="menu-button__drop-down"
            alt="drop-down"
          />
        </div>
        {!disabled && (
          <div className="menu-dropdown">
            {data.map((item, index) => {
              return (
                <div
                  className={classNames(
                    "menu-item",
                    item.id === value ? "active" : ""
                  )}
                  key={`key${index}`}
                  onClick={() => handleChange(item.id, item)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
};

const Label = styled.label`
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.palette.secondary};
  display: flex;
  margin-bottom: 0;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  outline: 0;
  text-align: left;
  width: 100%;
  min-width: ${(props) => `${props.width}px`};
  height: 32px;
  .menu-button {
    line-height: 32px;
    border-radius: 0px;
    border-bottom: 1px solid #2b2b2b;
    color: white;
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: auto 0;
    padding: 0px 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    transition: all 300ms ease;
    svg {
      margin: auto 0 auto auto;
      width: 12px;
    }
    &__drop-down {
      width: 8px;
      transition: all 300ms ease;
      margin: auto 0 auto 8px;
    }
    &:hover {
      background: #555454;
    }
    &.disabled {
      color: gray;
      &__drop-down {
        color: gray;
      }
    }
  }

  &:focus .menu-button {
    pointer-events: none;
    border-color: #fff;
  }

  /* If :focus detected the dropdown menu is displayed by making it visible */
  &:focus .menu-dropdown {
    opacity: 1;
    /* visibility: visible; */
    display: block;
  }
  &:focus .menu-button__drop-down {
    transform: rotate(180deg);
  }

  /* style for the dropdown box */
  .menu-dropdown {
    width: 100%;
    margin: 3px 0 0 0;
    padding: 8px 0;
    pointer-events: auto;
    position: absolute;
    z-index: 2;
    opacity: 0;
    left: 0px;
    top: 32px;
    /* visibility: hidden; */
    display: none;
    // transition: all 300ms ease;
    color: white;
    background: #191919;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    max-height: 220px;
    overflow: auto;
    .hidden {
      visibility: hidden;
    }
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 14px;
      background: ${(props) => props.theme.palette.backgrounds};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.palette.backgrounds};
      border: 1px solid ${(props) => props.theme.palette.itemsHover};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.itemsHover};
      cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }

  /* style the links in the dropdown */
  .menu-dropdown > .menu-item {
    border: none;
    outline: 0;
    display: block;
    color: white;
    background: #191919;
    text-decoration: none;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    transition: all 300ms ease;
    &:hover {
      background: #555454;
      border-radius: 0px;
      color: white;
    }
    &.active::after {
      content: " ";
      position: absolute;
      width: 12px;
      height: 6px;
      border-left: 2px solid #3bc7e3;
      border-bottom: 2px solid #3bc7e3;
      transform: rotate(-45deg);
      top: 11px;
      transition: all 300ms ease;
      right: 12px;
    }
  }
`;
