/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
export const OfferSaveTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const start = useInput(10);
  const end = useInput("");
  const amount = useInput("");
  const type = useInput("%");
  const typeData = [
    { id: "%", title: "%" },
    { id: "$", title: "$" },
  ];

  const handleUpdateValue = (start, end, amount, type) => {

    switch (type) {
      case "%":

          if(end) {
            onChange(`Save ${start}% - ${end}% ${amount}`);
          }else{
            onChange(`Save ${start}% ${amount}`);
          }

        break;
      case "$":

          if(end) {
            onChange(`Save $${start} - $${end} ${amount}`);
          }else{
            onChange(`Save $${start} ${amount}`);
          }

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (value) {
      let clean_value = value.replace('Save ', '');
      clean_value = clean_value.replace(' - ', '-');
      clean_value = clean_value.replace(' ', '*');

      const first_split = clean_value.split("*");
      const second_split = first_split[0].replace(/\s/g, '').split("-");
      const assumed_type = first_split[0].indexOf("$") > -1 ? "$" : "%";

      if(first_split.length > 1 && second_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue(second_split[1].replace(assumed_type, ''));
        type.setValue(assumed_type);
        amount.setValue(first_split[1]);
      } else if (first_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue("");
        type.setValue(assumed_type);
        amount.setValue(first_split[1]);
      } else if (second_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue(second_split[1].replace(assumed_type, ''));
        type.setValue(assumed_type);
        amount.setValue("");
      } else {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue("");
        type.setValue(assumed_type);
        amount.setValue("");
      }
    }
    return () => {};
  }, [value]);

  return (
    <BuilderContainer className={`${className} mt-1`}>
      <Title>Offer Text Builder</Title>
      <Container>
        <div style={{ width: 70 }}>
          <Input2
            label="Start"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              handleUpdateValue(e, end.value, amount.value, type.value);
            }}
          />
        </div>
        <div className="mt-auto mb-1 p-2 text">-</div>
        <div style={{ width: 70, marginRight:10 }}>
          <Input2
            label="End"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(start.value, e, amount.value, type.value);
            }}
          />
        </div>

        <div className="ml-auto" >
          <DropDown2
            label="Type"
            data={typeData}
            width={60}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(start.value, end.value, amount.value, e);
            }}
          />
        </div>

      </Container>
      <Container>
        <div className="w-100">
            <Input2
              label="Amount"
              value={amount.value}
              onChange={(e) => {
                amount.setValue(e.replace(" ", ""));
                handleUpdateValue(start.value, end.value, e, type.value);
              }}
            />
        </div>
      </Container>
    </BuilderContainer>
  );
};

const BuilderContainer = styled.div`
  padding:8px 0;
  .text {
    font-size:12px;
  }
`;

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
  margin:2px 0;
`;
