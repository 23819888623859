/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { DropDown2 } from "components";
import { useInput } from "hooks";
import { AGREEMENT_VALUES } from "config";
export const OfferAgreementTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const val = useInput("Items(s)");

  const handleUpdateValue = (v) => {
    onChange(`w/${v}`);
  };

  useEffect(() => {
    if (value.startsWith("w/")) {
      val.setValue(value.slice(2).trim());
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1">w/</div>

        <div style={{ width: 300, marginLeft: 10 }}>
          <DropDown2
            label="Agreement"
            data={AGREEMENT_VALUES}
            width={300}
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
