import React from "react";
import {
  IncentiveAirMilesTextBuilder,
  IncentiveCouponTextBuilder,
  IncentiveDownloadTextBuilder,
  IncentiveEnterPurchaseSavingsTextBuilder,
  IncentiveNextShoppingTripTextBuilder,
  IncentiveRewardsTextBuilder,
  IncentiveAdditionalCouponTextBuilder,
  IncentiveCreditCard1TextBuilder,
  IncentiveCreditCard2TextBuilder,
  IncentiveFreeItemTextBuilder,
  IncentiveGasTextBuilder,
  IncentiveTradeInSavingsTextBuilder,
  IncentiveExtraSavingsTextBuilder,
} from "components";

export const IncentiveTextBuilder = ({
  type = "",
  value,
  onChange = () => {},
}) => {
  return (
    <>
      {type === "Air Miles" && (
        <IncentiveAirMilesTextBuilder
          value={type === "Air Miles" ? value : "Earn 2000 air miles"}
          onChange={onChange}
        />
      )}
      {type === "Clearance" && <></>}

      {type === "Coupons" && (
        <IncentiveCouponTextBuilder
          value={type === "Coupons" ? value : "Save 10%"}
          onChange={onChange}
        />
      )}

      {type === "Download" && (
        <IncentiveDownloadTextBuilder
          value={type === "Download" ? value : "$5 & Up"}
          onChange={onChange}
        />
      )}
      {type === "Enter Purchase Savings" && (
        <IncentiveEnterPurchaseSavingsTextBuilder
          value={type === "Enter Purchase Savings" ? value : "2/$2"}
          onChange={onChange}
        />
      )}
      {type === "Extra Coupons" && <></>}
      {type === "Extra Savings" && (
        <IncentiveExtraSavingsTextBuilder
          value={
            type === "Extra Savings" ? value : "Save $10.00 on item w/purchase"
          }
          onChange={onChange}
        />
      )}
      {type === "Free Shipping" && <></>}
      {type === "Next Shopping Trip" && (
        <IncentiveNextShoppingTripTextBuilder
          value={
            type === "Next Shopping Trip"
              ? value
              : "Save $7.00 on next shopping visit"
          }
          onChange={onChange}
        />
      )}
      {type === "Pharmacy Services" && <></>}
      {type === "Rewards" && (
        <IncentiveRewardsTextBuilder
          value={type === "Rewards" ? value : "w/purchase/$10.00"}
          onChange={onChange}
        />
      )}
      {type === "Rollback" && <></>}
      {type === "Saturdate" && <></>}
      {type === "Sweepstakes" && <></>}
      {type === "Additional Coupon" && (
        <IncentiveAdditionalCouponTextBuilder
          value={
            type === "Additional Coupons"
              ? value
              : "$2 Coupon Available Through Sunday Paper"
          }
          onChange={onChange}
        />
      )}
      {type === "Credit Card 1" && (
        <IncentiveCreditCard1TextBuilder
          value={
            type === "Credit Card 1"
              ? value
              : "No Interest for 12 months w/store credit card"
          }
          onChange={onChange}
        />
      )}
      {type === "Credit Card 2" && (
        <IncentiveCreditCard2TextBuilder
          value={
            type === "Credit Card 2" ? value : "Save $10.00 w/store credit card"
          }
          onChange={onChange}
        />
      )}
      {type === "Earn Free Item" && <></>}
      {type === "Free Item" && (
        <IncentiveFreeItemTextBuilder
          value={type === "Free Item" ? value : "Free 2 Items"}
          onChange={onChange}
        />
      )}
      {type === "Gas" && (
        <IncentiveGasTextBuilder
          value={type === "Gas" ? value : "Earn $0.05 per gallon"}
          onChange={onChange}
        />
      )}
      {type === "Trade-in savings" && (
        <IncentiveTradeInSavingsTextBuilder
          value={
            type === "Trade-in savings"
              ? value
              : "$10.00 or more trade in savings"
          }
          onChange={onChange}
        />
      )}
    </>
  );
};
