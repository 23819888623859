import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const QuickSelectionChoice = ({
  index,
  data,
  active,
  onClick = () => {},
  showIndex,
}) => {
  const { postPageEvents } = useMainContext();
  const handleClick = () => {
    onClick(data?.value);
    postPageEvents("click", data?.title);
  };
  return (
    <ChoiceContainer active={active} onClick={() => handleClick()}>
      {showIndex && <div className="bullet">{index}</div>}
      <div className="title">{data?.title}</div>
    </ChoiceContainer>
  );
};
const ChoiceContainer = styled.div`
  width: 100%;
  margin: 2px 0;
  display: flex;
  width: 100%;
  padding: 8px;
  background: ${(props) =>
    props.active ? props.theme.palette.items : props.theme.palette.gray};
  cursor: pointer;
  transition: all 300ms ease;
  overflow: auto;
  min-height: 40px;
  &:hover {
    opacity: 0.7;
  }
  .bullet {
    min-width: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.gray};
    border: 1px solid ${(props) => props.theme.palette.secondary};
    color: white;
    font-size: 16px;
    text-align: center;
    margin: auto 0 auto 8px;
  }
  .title {
    color: white;
    font-size: 16px;
    margin-left: 16px;
    /* line-height: 48px; */
  }

  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
