import { API } from "utils";
import { COUNT_PER_PAGE } from "config";

export const getStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPageStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages/page");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getAdblockStagesApi = async () => {
  try {
    const response = await API().get("api/data/stages/adblock");
    return { success: true, payload: response.data?.stages };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getRetailersApi = async () => {
  try {
    const response = await API().get("api/data/retailers");
    return { success: true, payload: response.data?.retailers };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getOfferTypesApi = async () => {
  try {
    const response = await API().get("api/data/offer-types");
    return { success: true, payload: response.data?.offer_types };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductCategoriesApi = async () => {
  try {
    const response = await API().get("api/data/product-categories");
    return { success: true, payload: response.data?.product_categories };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductSizesApi = async () => {
  try {
    const response = await API().get("api/data/product-sizes");
    return { success: true, payload: response.data?.product_sizes };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductDimensionApi = async () => {
  try {
    const response = await API().get("api/data/product-dimensions");
    return { success: true, payload: response.data?.dimensions };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getProductBrandAutocompleteApi = async (name) => {
  try {
    const response = await API().get("api/data/brands/autocomplete", {
      params: { name },
    });
    return {
      success: true,
      results: response.data?.brands.map((item) => ({
        ...item,
        name: item.brand_name,
      })),
    };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
export const getRetailerAutocompleteApi = async (name) => {
  try {
    const response = await API().get("api/data/retailers/autocomplete", {
      params: {
        name,
      },
    });
    return { success: true, results: response.data?.retailers };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailersList = async ({
  page = 0,
  per_page = COUNT_PER_PAGE,
  sort_by = "name",
  sort_dir = "ASC",
}) => {
  try {
    const response = await API().get("api/data/retailers", {
      params: {
        page,
        per_page,
        sort_by,
        sort_dir
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getRetailerByIdApi = async (id) => {
  try {
    const response = await API().get(`api/data/retailer/${id}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdActivate = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/activate`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const postRetailerByIdDeactivate = async (id) => {
  try {
    const response = await API().post(`api/data/retailer/${id}/deactivate`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getOfferValuesApi = async (data) => {
  try {
    const response = await API().post(`api/data/get-offer-values`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getBrandByIdApi = async (id) => {
  try {
    const response = await API().get(`api/data/brand/${id}`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getQueueCountApi = async (id) => {
  try {
    const response = await API().get(`api/data/queue-count`);
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const createBrandApi = async (data) => {
  try {
    const response = await API().post(`api/data/brands/add`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const eventAutocompleteApi = async (name) => {
  try {
    const response = await API().get(
      `api/data/events/autocomplete?name=${name}`
    );
    return { success: true, results: response.data.events };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getPageCroppingStats = async (fromDate, toDate) => {
  try {
    const response = await API().get(
      `api/stats/stats/page-cropping?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getAblockTimingStats = async (fromDate, toDate) => {
  try {
    const response = await API().get(
      `api/stats/stats/adblock-timings?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};

export const getRetailerTimingStats = async (fromDate, toDate) => {
  try {
    const response = await API().get(
      `api/stats/stats/retailers?from_date=${fromDate}&to_date=${toDate}`
    );
    return { success: true, results: response.data };
  } catch (e) {
    return { success: false, results: [], error: e };
  }
};
