import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useCircularContext } from "contexts";

import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const stages = [
  { label: "Accepted", name: "ACCEPTED" },
  { label: "Adblock Processing", name: "ADBLOCK_PROCESSING" },
  { label: "Page Processing", name: "PAGE_PROCESSING" },
  { label: "Offer Processing", name: "OFFER_PROCESSING" },
  { label: "Product Processing", name: "PRODUCT_PROCESSING" },
  { label: "Reassembly", name: "REASSEMBLY" },
  { label: "Complete", name: "COMPLETE" }
];

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  const {
    stage = [],
    setFilter,
    circDateFrom,
    circDateTo,
  } = useCircularContext();

  const handleFilter = (params) => {
    if (stage && stage.includes(params)) {
      setFilter({ stage: stage.filter((a) => a !== params) });
    } else {
      setFilter({ stage: [...(stage || []), params] });
    }
  };

  return (

    <FilterContainer>
      <AccordionSection>
        {isLoading ? (
          <SkeletonTheme color="#202020" highlightColor="#444">
            <Skeleton count={16} height={24} className="my-2" />
          </SkeletonTheme>
        ) : (
          <>
            <div className="title">Filters</div>
            <Accordion
              title="Stages"
              desc=""
              className="mb-3"
              open={stage && stage?.length > 0}
            >
              {stages.map(({ label, name }) => (
                <div key={name} className="m-3">
                  <CheckBox
                    value={(stage || []).includes(name)}
                    onChange={() => handleFilter(name)}
                  >
                    {label}
                  </CheckBox>
                </div>
              ))}
            </Accordion>

            {/* <Accordion
              title="Created Date"
              className="mb-3"
              open={creationDateFrom || creationDateTo}
            >
              <div className="py-3">
                <DateRange
                  open={creationDateFrom || creationDateTo}
                  startDate={creationDateFrom}
                  endDate={creationDateTo}
                  onChange={(dates) => {
                    setFilter({
                      creationDateFrom: dates[0] || undefined,
                      creationDateTo: dates[1] || undefined,
                    });
                  }}
                  onClear={() => {
                    setFilter({
                      creationDateFrom: undefined,
                      creationDateTo: undefined,
                    });
                  }}
                />
              </div>
            </Accordion> */}
            <Accordion
              title="Circulation Date"
              className="mb-3"
              open={circDateFrom || circDateTo}
            >
              <div className="py-3">
                <DateRange
                  open={circDateFrom || circDateTo}
                  startDate={circDateFrom}
                  endDate={circDateTo}
                  onChange={(dates) => {
                    setFilter({
                      circDateFrom: dates[0],
                      circDateTo: dates[1],
                    });
                  }}
                  onClear={(dates) => {
                    setFilter({
                      circDateFrom: undefined,
                      circDateTo: undefined,
                    });
                  }}
                />
              </div>
            </Accordion>
          </>
        )}
      </AccordionSection>
    </FilterContainer>
  );
};


const FilterContainer = styled.div`
  overflow: auto;
  border-bottom:2px solid ${(props) => props.theme.palette.background};
`;

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  min-width: 240px;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .title {
    color:white;
    font-size:12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform:uppercase;
  }
`;
