import React from "react";
import styled from "styled-components";
import { EmptyHeader, Footer } from "components";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <StyledLayout>
          <EmptyHeader />
          <Content>
            <div className="mx-auto my-auto">
              <h2>Sorry, the application has encountered an error.</h2>
              <div>Please retry and refresh the page.</div>
              <div>If problems persist, please contact support.</div>
              <details style={{ whiteSpace: "pre-wrap" }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details>
            </div>
          </Content>
          <Footer />
        </StyledLayout>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: none;
  background: #0a0a0a;
`;

const Content = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  color: white;
  display: flex;
  flex-direction: column;
`;

export { ErrorBoundary };
