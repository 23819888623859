import axios from "axios";
import https from "https";
import { API_URL } from "config";
import { navigate } from "@reach/router";

export const API = (opts = {}, optsHeader = {}) => {
  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const Authorization = localStorage.getItem("user");
  const defaultOptions = {
    ...opts,
    headers: {
      Authorization,
      // Accept: "*/*",
      // "Accept-Enconding": "gzip, deflate, br",
      // Connection: "keep-alive",
      // "Cache-Control": "no-cache",
      // "Postman-Token": "42e6c291-9a09-c29f-f28f-11872e2490a5",
      // "Content-Type": "multipart/form-data",
      ...optsHeader,
    },
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    trailingSlash: true,
  };

  const axiosApi = axios.create({
    baseURL: API_URL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    ...defaultOptions,
    // adapter: cache.adapter,
  });
  axios.trailingSlash = true;

  axiosApi.interceptors.response.use(null, (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("user");
      navigate(`/login?redirect=${window.location.href}`);
    }
    return Promise.reject(error);
  });
  return axiosApi;
};
