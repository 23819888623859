/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveExtraSavingsTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const type = useInput("$");
  const val = useInput("10.00");
  const isPurchase = useInput(true);
  const pVal = useInput("");
  const pType = useInput("");
  const suffix = useInput("");

  const typeData = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];
  const typeData1 = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
  ];

  const formatFirstLine = (v, t) => {
    let result = "";
    switch (t) {
      case "$":
        result = `$${v}`;
        break;
      case "%":
        result = `${v}%`;
        break;
      default:
        break;
    }
    return result ? ` ${result}` : "";
  };
  const formatPurchase = (isP, pV, pT) => {
    if (isP) {
      switch (pT) {
        case "$":
          return ` w/purchase $${pV}`;
        case "Unit":
          return ` w/purchase ${pV}Unit`;
        default:
          return ` w/purchase ${pV}Unit`;
      }
    }
    return "";
  };
  const handleUpdateValue = (isUT, v, t, isP, pv, pt, suf) => {
    onChange(
      `Save${isUT ? " Up To" : ""}${formatFirstLine(
        v,
        t
      )} on item${formatPurchase(isP, pv, pt)}${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    if (value.startsWith("Save")) {
      let main = value.slice(4).trim();
      if (main.startsWith("Up To")) {
        main = main.slice(5).trim();
        isUpTo.setValue(true);
      } else {
        isUpTo.setValue(false);
      }
      const itIdx = main.indexOf("on item");
      const vt = main.slice(0, itIdx).trim();
      if (vt.startsWith("$")) {
        type.setValue("$");
        val.setValue(vt.slice(1));
      } else if (vt.endsWith("%")) {
        type.setValue("%");
        val.setValue(vt.slice(0, -1));
      }
      main = main.slice(itIdx + 7).trim();
      if (main.startsWith("w/purchase")) {
        main = main.slice(11).trim();
        const sufIdx = main.indexOf(" ");
        let vt = "";
        if (sufIdx > 0) {
          vt = main.slice(0, sufIdx).trim();
          suffix.setValue(main.slice(sufIdx).trim());
        } else {
          vt = main.trim();
          suffix.setValue("");
        }

        if (vt.startsWith("$")) {
          pType.setValue("$");
          pVal.setValue(vt.slice(1));
        } else if (vt.endsWith("Unit")) {
          pType.setValue("Unit");
          pVal.setValue(vt.slice(0, -4));
        }
      } else {
        suffix.setValue(main.trim());
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-2 mr-2">Save</div>
        <ToggleButton
          className="mt-auto mb-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              val.value,
              type.value,
              isPurchase.value,
              pVal.value,
              pType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                type.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 60, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData1}
            width={70}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                e,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div className="mt-auto mb-2 ml-3">on item</div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          className="mt-auto mb-2"
          label="w/purchase"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              isUpTo.value,
              val.value,
              type.value,
              e,
              pVal.value,
              pType.value,
              suffix.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={pVal.value}
            disabled={!isPurchase.value}
            onChange={(e) => {
              pVal.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                e,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            defaultValue="Unit"
            disabled={!isPurchase.value}
            value={pType.value || "Unit"}
            onChange={(e) => {
              pType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                pVal.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 90, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                type.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
