import React, { useRef, useEffect } from "react";
import { OfferInfoContainer, Input2 } from "components";
import { useMainContext } from "contexts";

export const InfoInput = ({
  label,
  inputId,
  shouldMoveNextOnEnter = true,
  className,
}) => {
  const {
    setActiveField,
    activeField,
    moveNextField,
    updateInfo,
    currentInfo,
    setIsManualEntry
  } = useMainContext();
  const inputRef = useRef();

  useEffect(() => {
    if (inputId === activeField && inputRef?.current) {
      inputRef.current.focus();
    }

    return () => {};
  }, [inputId, activeField]);

  const handleChange = (value) => {
    updateInfo(inputId, value);
  };

  return (
    <OfferInfoContainer inputId={inputId} className={className}>
      <Input2
        label={label}
        inputId={inputId}
        shouldMoveNextOnEnter={shouldMoveNextOnEnter}
        ref={inputRef}
        value={currentInfo[inputId]}
        onChange={handleChange}
        onFocus={() => setActiveField(inputId)}
        onEnter={() => shouldMoveNextOnEnter && moveNextField()}
        onClick={()=>setIsManualEntry(true)}
      />
    </OfferInfoContainer>
  );
};
