import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, navigate } from "@reach/router";
import { useAuthContext } from "contexts";
import { TAB_DATA } from "config";
import { IconButton } from "components";
import { ProfileModal } from "modals";
import { useModal } from "hooks";
import { abbreviateName } from "utils";
//import { IoMdNotifications } from "react-icons/io";

export const Header = ({ props, active = "" }) => {
  const location = useLocation();
  const [viewName, setViewName] = useState("");
  const profileModal = useModal();
  //const alertsModal = useModal();
  const { userInfo } = useAuthContext();

  const handleNavigateToHome = () => {
    navigate("/home");
  };

  const handleLogoClick = (event) => {
    if (event.button === 1) {
      window.open("/home");
    }
  };

  useEffect(() => {
    const pathSplit = location.pathname.split("/");
    var name = pathSplit[1];
    if (pathSplit.length === 2 && pathSplit[1] === "circular") {
      name = "Circulars";
    }
    setViewName(name);
  }, [location]);

  return (
    <StyledHeader>
      <Logo
        className="my-auto"
        onMouseDown={handleLogoClick}
        onClick={handleNavigateToHome}
      >
        {" "}
        ADDISON
      </Logo>

      <ViewName>- {viewName}</ViewName>

      <div className="d-flex ml-auto">
        {userInfo.user?.is_superuser
          ? TAB_DATA.map(({ label, url }) => (
              <Link key={label} href={url} active={active === label}>
                {label}
              </Link>
            ))
          : null}
        <IconButton
          className="my-auto"
          onClick={() => profileModal.openModal()}
          text={abbreviateName(userInfo?.user?.username)}
          avatar={userInfo?.avatar}
        />
      </div>
      <ProfileModal {...profileModal} userName={userInfo?.user?.username} />
      {/* <AlertsModal {...alertsModal} /> */}
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
  border-bottom: 4px solid ${(props) => props.theme.palette.canvas};
  min-width: 1660px;
`;

const Logo = styled.div`
  height: 30px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
`;

const ViewName = styled.span`
  margin: 8px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
`;

const Link = styled.a`
  color: ${(props) =>
    props.active ? props.theme.palette.primaryHover : "white"};
  text-decoration: underline;
  font-size: 20px;
  margin: auto 24px auto 0;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
    color: white;
  }
`;
