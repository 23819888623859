
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { format } from "date-fns";
import { useModal } from "hooks";
import { CreateModifyUserModal } from "modals";

export const TableRow = ({ isLoading, data }) => {

  const modifyUserModal = useModal();

  const handleModifyUser = () => {
    modifyUserModal.openModal();
  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data.is_active}>
      <div className="inner">
        <RowItem>{data?.email}</RowItem>
        <RowItem>{data?.username}</RowItem>
        <RowItem>{data?.full_name}</RowItem>
        <RowItem>{data?.is_superuser === true ? "Yes": "No"}</RowItem>
        <RowItem>{data?.is_active === true ? "Yes": "No"}</RowItem>
        <RowItem>{data?.created_at
            ? format(new Date(data.created_at), "MM/dd/yyyy hh:mm a")
            : ""}</RowItem>
        <RowItem>
          <Link onClick={handleModifyUser}>Edit</Link>
        </RowItem>
      </div>
      <CreateModifyUserModal data={data} {...modifyUserModal} />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;

  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === true
        ? props.theme.palette.success
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    position:relative;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      width: 250px;
    }
    & > div:nth-child(2) {
      width: 250px;
    }
    & > div:nth-child(3) {
      width: 250px;
    }
    & > div:nth-child(4) {
      width: 200px;
    }
    & > div:nth-child(5) {
      width: 150px;
    }
    & > div:nth-child(6) {
      width: 350px;
    }
    & > div:nth-child(7) {
      flex: 1;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  span {
    color:${(props) => props.theme.palette.error};
  }
`;

const Link = styled.div`
  position:absolute;
  right:20px;
  top:35px;
  text-decoration: underline;
  &:hover {
    color:${(props) => props.theme.palette.primaryHover}
  }
  cursor:pointer;
`;
