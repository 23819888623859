/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveDownloadTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const free = useInput(false);
  const day = useInput("");
  const dayData = [
    { id: "", title: "None" },
    { id: "Monday", title: "Monday" },
    { id: "Tuesday", title: "Tuesday" },
    { id: "Wednesday", title: "Wednesday" },
    { id: "Thursday", title: "Thursday" },
    { id: "Friday", title: "Friday" },
    { id: "Saturday", title: "Saturday" },
    { id: "Sunday", title: "Sunday" },
  ];

  const handleUpdateValue = (isFree, type) => {
    onChange(`${isFree ? "Free" : ""} ${type} Download`);
  };

  useEffect(() => {
    if (value.endsWith("Download")) {
      let main = value.slice(0, -8);
      if (main.startsWith("Free")) {
        main = main.slice(4).trim();
        free.setValue(true);
      } else {
        free.setValue(false);
      }
      const parts = main.split(" ");
      day.setValue(parts[0].trim());
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <ToggleButton
          className="mt-auto mb-2"
          label="Free"
          {...free}
          value={free.value}
          onChange={(e) => {
            free.setValue(e);
            handleUpdateValue(e, day.value);
          }}
        />
        <div className="ml-2" style={{ width: 120 }}>
          <DropDown2
            label="Type"
            data={dayData}
            width={120}
            value={day.value}
            onChange={(e) => {
              day.setValue(e);
              handleUpdateValue(free.value, e);
            }}
          />
        </div>
        <div className="ml-2 mt-auto mb-2">Download</div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
