import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import {
  FaCheck,
  FaCropAlt,
  FaTags,
  FaBarcode,
} from "react-icons/fa";
import { VscCombine } from "react-icons/vsc";
import { MdFindInPage } from "react-icons/md";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const Progress = ({ isLoading, data }) => {

  //Send the user to the linked item
  const handleLink = (path) => {
    navigate(path);
  };

  //stages
  // ACCEPTED - this is the default since we cant have a circular with out is accepted
  // PAGE_PROCESSING
  // ADBLOCK_RPOCESSING
  // OFFER_PROCESSING
  // PRODUCT_PROCESSING
  // REASSEMBLY
  // COMPLETE
  if(data && data.stage) {
    switch (data.stage) {
      case 'PAGE_PROCESSING':
        data.statusValue = 1;
        break;
      case 'ADBLOCK_PROCESSING':
        data.statusValue = 2;
        break;
      case 'PRODUCT_PROCESSING':
        data.statusValue = 3;
        break;
      case 'OFFER_PROCESSING':
        data.statusValue = 4;
        break;
      case 'REASSEMBLY':
        data.statusValue = 5;
        break;
      case 'COMPLETE':
        data.statusValue = 7;
        break; 
      default:
        //ACCEPTED this is the default
        data.statusValue = 0
  
    }
  }

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <div className="d-flex">
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={30} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={30} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={30} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={30} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
        <div style={{ marginTop: 22 }}>
          <Skeleton width={30} height={5} />
        </div>
        <Skeleton circle width={50} height={50} />
      </div>
    </SkeletonTheme>
  ) : (
    <div className="d-flex">
      <Circle type="page" index={1} status={data.statusValue} disabled={true} />
      <ProcessLine width={30} index={1} status={data.statusValue} />

      <Circle type="adblock" index={2} status={data.statusValue} clickEvent={(e) => handleLink('/adblock/' + data?.uuid + '/0')} />
      <ProcessLine width={30} index={2} status={data.statusValue} />

      <Circle type="product" index={3} status={data.statusValue} clickEvent={(e) => handleLink('/product/' + data?.uuid + '/0/0')} />
      <ProcessLine width={30} index={3} status={data.statusValue} />

      <Circle type="offer" index={4} status={data.statusValue} clickEvent={(e) => handleLink('/offer/' + data?.uuid + '/0/0')} />
      <ProcessLine width={30} index={4} status={data.statusValue} />

      <Circle type="reassembly" index={5} status={data.statusValue} clickEvent={(e) => handleLink('/circular/' + data?.uuid + '/0')} />
      <ProcessLine width={30} index={5} status={data.statusValue} />

      <Circle type="complete" index={6} status={data.statusValue} clickEvent={(e) => handleLink('/circular/' + data?.uuid + '/0')} />
    </div>
  );
};

const Circle = ({ type = "page", status = 0, index = 0, clickEvent, disabled=false }) => {

  return (
    <div>
      <CircleContainer
       title={type.charAt(0).toUpperCase() + type.slice(1)}
       className={disabled ? "disabled" : "active"} 
       status={status > index ? "completed" : status === index ? "active" : "none"} 
       onClick={clickEvent} >
        {type === "page" ? (
          <MdFindInPage />
        ) : type === "adblock" ? (
          <FaCropAlt />
        ) : type === "offer" ? (
          <FaTags />
        ) : type === "product" ? (
          <FaBarcode />
        ) : type === "reassembly" ? (
          <VscCombine />
        ) : type === "complete" ? (
          <FaCheck />
        ) : null}
      </CircleContainer>
    </div>
  );
};

const ProcessLine = ({ status = 0, index = 0, width = 30 }) => {

  return (

      <Line
       status={status > index ? "completed" : status === index ? "active" : "none"} 
       width={width}
      >
      </Line>

  );
};

const CircleContainer = styled.div`

  width: 50px;
  height: 50px;
  border: 2px solid
    ${(props) =>
      props.status === "none"
        ? props.theme.palette.secondary
        : props.theme.palette.accent};
  background: ${(props) =>
    props.status === "completed"
      ? props.theme.palette.accent
      : props.theme.palette.secondary};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;

  &.active {
    cursor:pointer;
    &:hover {
      background: ${(props) => props.theme.palette.white};
      border: 2px solid ${(props) => props.theme.palette.info};
    }
  }

  svg {
    margin: auto;
    color: ${(props) =>
      props.status === "done"
        ? props.theme.palette.white
        : props.theme.palette.items};
    font-size: 24px;
  }
`;

const Line = styled.div`
  height: 5px;
  margin: 23px 2px auto 2px;
  width: ${(props) => props.width}px;
  background: ${(props) =>
    props.status === "completed"
      ? props.theme.palette.accent
      : props.theme.palette.secondary};
`;
