import Raven from 'raven-js';
import { version } from '../package.json';

function configSentry() {
  return Raven.config('https://d75cbc2e8acb4e689ba788ddd13ddd79@o1009700.ingest.sentry.io/6253009', {
    release: version,
    environment: 'dev',
  }).install();
}

export default configSentry();