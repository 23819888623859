/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const Input2 = React.forwardRef(
  (
    {
      id = "",
      className,
      label,
      inputId,
      onBlur = () => {},
      value,
      onChange = () => {},
      onFocus = () => {},
      onClick = () => {},
      onKeyPress = () => {},
      onEnter = () => {},
      disabled = false,
      placeholder,
      forceValue = false,
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const { postPageEvents } = useMainContext();

    const handleBlur = (event) => {
      setFocused(false);
      onBlur(event);
    };

    const handleFocus = (event) => {
      setFocused(true);
      onFocus(onFocus);
      postPageEvents("focus", id || label);
    };

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.target.blur();
        onEnter(event);
      }
      if (onKeyPress) {
        onKeyPress(event);
      } else {
      }
    };

    const handleChange = (value) => {
      onChange(value);
    };

    useEffect(() => {
      if (disabled && !forceValue) {
        // handleChange("");
      }
      return () => {};
    }, [disabled]);

    return (
      <Container className={className} onClick={onClick}>
        {label && <Label htmlFor={label}>{label}</Label>}

        <div className="d-flex position-relative">
          <StyledInput
            id={inputId}
            ref={ref}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            onChange={({ target: { value } }) => handleChange(value)}
            disabled={disabled}
            placeholder={placeholder}
            focus={inputId === value}
            onKeyPress={handleKeyPress}
          />
          <Underline focused={focused ? 1 : 0} />
        </div>
      </Container>
    );
  }
);
const Container = styled.div`
  position: relative;
`;
const Label = styled.label`
  font-size: ${(props) => props.theme.font.size.s};
  color: ${(props) => props.theme.palette.secondary};
  display: flex;
  margin-bottom: 0;
`;
const StyledInput = styled.input`
  display: block;
  color: ${(props) => (props.disabled ? "gray" : "white")};
  box-shadow: inset 0 -1px 0 0 ${(props) => props.theme.palette.gray};
  transition: background-color 5000s linear 0s;
  width: 100%;
  font-size: ${(props) => props.theme.font.size.m};
  line-height: ${(props) => props.theme.font.size.m};
  height: 32px;
  padding: 4px;
  overflow-x: hidden;
  background-color: transparent;
  border: none;
  &:-internal-autofill-selected {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  /* Needs to be a single selector to work in safari */
  &:-webkit-autofill {
    -webkit-text-fill-color: var(--colorTextBody);
    box-shadow: 0 0 0px 1000px rgb(var(--rgbText) / 0.1) inset;
  }

  outline: none;
  &:focus {
    outline: none;
  }

  &::-webkit-contacts-auto-fill-button:hover {
    background-color: rgb(var(--rgbPrimary));
  }
`;
const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
