/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Button, Input2, ToggleButton } from "components";
import { Formik, Form } from "formik";
import { useInput } from "hooks";
import * as Yup from "yup";
import { postCreateUser, postUpdateUser } from "api";
import { useUsersContext } from "contexts";
import { useMutation } from "react-query";
import styled from "styled-components";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Please enter your name"),
  password: Yup.string()
    .required("Please enter your password")
    .matches(
      /^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, and one number"
    ),
});

export const CreateModifyUserModal = ({
  open,
  onClose,
  onCreate = () => {},
  data = {},
}) => {
  const [error, setError] = useState(false);
  const username = useInput("");
  const full_name = useInput("");
  const email = useInput("");
  const password = useInput("");
  const is_superuser = useInput(false);
  const is_active = useInput(true);
  const uuid = useInput(null);

  const {
    getUsers,
  } = useUsersContext();

  const createMutation = useMutation((data) => postCreateUser(data));
  const updateMutation = useMutation((data) => postUpdateUser(data));

  //Prepopulate the fields if they are passed
  useEffect(() => {
    if (open) {
      username.setValue(data?.username || "");
      full_name.setValue(data?.full_name || "");
      email.setValue(data?.email || "");
      password.setValue(data?.password || "");
      is_superuser.setValue(data?.is_superuser || false);
      is_active.setValue(data?.is_active || false);
      uuid.setValue(data?.uuid || null);
    }

    return () => {};
  }, [open]);

  const handleCreate = async () => {
    if (data?.username) {

        const response = await updateMutation.mutateAsync({
          username: username.value,
          full_name: full_name.value,
          email: email.value,
          password: password.value,
          is_superuser: is_superuser.value,
          is_active: is_active.value,
          id: data?.uuid,
        });

        if(!response.success) {
          setError(true);
        }else{
          getUsers();
          onClose();
        }

    } else {

        const response = await createMutation.mutateAsync({
          username: username.value,
          full_name: full_name.value,
          email: email.value,
          password: password.value,
          is_superuser: is_superuser.value,
          is_active: is_active.value,
        });

        if(!response.success) {
          setError(true);
        }else{
          getUsers();
          onClose();
        }

    }

  };


  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={data?.username ? "Update User" : "Create User"}
    >
      <Formik
        initialValues={{
          password: "",
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {}}
        
      >
          <Form>
          {error ? 
                  ( 
                  <Error>
                  <div>An Error has occured, please check your information.</div>
                  <ul>
                    <li>User Name must be unique.</li>
                    <li>Email must be unique.</li>
                  </ul>
                  </Error>) : null
                }
            <div className="d-flex">
              <div className="flex-1 mr-4">

                <Input2 label="User Name" name="username" forceValue={true} disabled={uuid.value !== null? true : false } {...username} />
                <Input2 label="Email" name="email" {...email} className="mb-4 mt-3" />
                <ToggleButton
                  className="mt-auto mr-2"
                  label="Super User"
                  value={is_superuser.value}
                  onChange={(e) => {
                    is_superuser.setValue(e);;
                  }}
                />
                <ToggleButton
                  className="mt-auto"
                  label="Active"
                  value={is_active.value}
                  onChange={(e) => {
                    is_active.setValue(e);
                  }}
                />
              </div>

              <div className="flex-1">
                <Input2 label="Full Name" name="full_name" {...full_name}  />
                <Input2 className="mt-3" label="Password" placeholder={uuid.value !== null ? "Leave blank for no change" : ""} name="password" {...password} />
              </div>
            </div>

            <div className="d-flex mt-4">
              <Button
                size="small"
                buttonTheme="primary"
                width="fit-content"
                className="ml-auto"
                type="button"
                isLoading={createMutation.isLoading}
                onClick={handleCreate}
                disabled={
                  !(
                    username.value &&
                    email.value
                  )
                }
              >
                {data?.username ? "Update User" : "Create User"}
              </Button>
            </div>
          </Form>
      </Formik>
    </Modal>
  );
};


const Error = styled.div`
  color: ${(props) => props.theme.palette.error};
  font-size: 14px;
  display:flex;
  div, ul {
    flex:1 1;
  }
`;
