import { API } from "utils";

export const getCircularIncentives = async (id) => {
  try {
    const response = await API().get(`api/circulars/${id}/incentives`);
    return response.data.incentives || [];
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const saveCircularIncentives = async (id, data = []) => {
  try {
    const response = await API().post(`api/circulars/${id}/save-incentive`, {
      incentive_nm: data
        .map((a) =>
          a?.incentive_limit
            ? `${a.incentive_text} (${a.incentive_limit})`
            : a.incentive_text
        )
        .join(","),
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const saveCircularIncentiveString = async (id, data = "") => {
  try {
    const response = await API().post(`api/circulars/${id}/save-incentive`, {
      incentive_nm: data,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const savePageIncentives = async (id, data = []) => {
  try {
    const response = await API().post(`api/pages/${id}/save-incentive`, {
      incentive_nm: data
        .map((a) =>
          a?.incentive_limit
            ? `${a.incentive_text} (${a.incentive_limit})`
            : a.incentive_text
        )
        .join(","),
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const savePageIncentiveString = async (id, data = "") => {
  try {
    const response = await API().post(`api/pages/${id}/save-incentive`, {
      incentive_nm: data,
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const updateAdblockIncentives = async (id, data = []) => {
  try {
    const response = await API().post(`api/adblocks/${id}/update-incentive`, {
      incentive_nm: data
        .map((a) =>
          a?.incentive_limit
            ? `${a.incentive_text} (${a.incentive_limit})`
            : a.incentive_text
        )
        .join(","),
    });
    return response.data;
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
