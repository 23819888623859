/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2 } from "components";
import { useInput } from "hooks";
export const OfferPurchaseQtyValTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const start = useInput("2");
  const end = useInput("");
  const innerVal = useInput("10");

  const handleUpdateValue = (s, e, v) => {
    if (s && e) {
      onChange(`w/purchase/${s}-${e}/$${v}`);
    } else if (s) {
      onChange(`w/purchase/${s}/$${v}`);
    }
  };

  useEffect(() => {
    if (
      value &&
      value.startsWith("w/purchase/") &&
      value.slice(11).length > 0
    ) {
      const main = value.slice(11);
      const splitIdx = main.indexOf("/$");
      const qtyRange = main.slice(0, splitIdx);
      innerVal.setValue(main.slice(splitIdx + 2).trim());
      if (qtyRange.indexOf("-") > 0) {
        const qtyIdx = qtyRange.indexOf("-");
        start.setValue(qtyRange.slice(0, qtyIdx).trim());
        end.setValue(qtyRange.slice(qtyIdx + 1).trim());
      } else {
        start.setValue(qtyRange.trim());
        end.setValue("");
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1" style={{ fontSize: 13 }}>
          w/purchase/
        </div>
        <div style={{ width: 90, marginRight: 10, marginLeft: 10 }}>
          <Input2
            label="Quantity Start"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              handleUpdateValue(e, end.value, innerVal.value);
            }}
          />
        </div>
        <div style={{ width: 80, marginRight: 10 }}>
          <Input2
            label="Quantity End"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(start.value, e, innerVal.value);
            }}
          />
        </div>

        <div className="ml-auto" style={{ width: 50 }}>
          <Input2
            label="Value"
            value={innerVal.value}
            onChange={(e) => {
              innerVal.setValue(e);
              handleUpdateValue(start.value, end.value, e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
