/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2 } from "components";
import { useInput } from "hooks";
export const OfferPurchaseProductTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const val = useInput("");

  const handleUpdateValue = (v) => {
    onChange(`w/purchase/${v}`);
  };

  useEffect(() => {
    if (value && value.startsWith("w/purchase/")) {
      val.setValue(value.slice(11).trim());
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1">w/purchase/</div>
        <div style={{ width: 200, marginLeft: 10 }}>
          <Input2
            label="Product(s)"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(e);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
