/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  PageDropDown,
  AdBlockDropDown,
  Card,
  OfferSelector,
  AdblockLocation,
  Link,
  AdblockImage,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  QuickSelectionChoice,
  OfferItem,
  IncentiveItem,
  OfferTextBuilder,
  IncentiveTextBuilder,
  NoSuggestions,
  QuickSelection,
  MetaData,
  PageInfo,
  FlagButtonGroup,
  EmptyOfferSelector,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { IncentiveInfo, OfferInfo, EmptyInfo } from "fragments";
import { useMainContext } from "contexts";
import { EndQueueModal, OfferDeletionModal } from "modals";
import {
  getImage,
  approveOffer,
  getOfferTypesApi,
  updateAdblockSuggestions,
  skipAdblock,
  doNotEnterAdblock,
  getCircularById,
  getPageAdblocks,
  getCircularIncentives,
  saveCircularIncentives,
  savePageIncentives,
  getPageById,
  rejectAdblock,
} from "api";
import { validateIncentive, validateOffer } from "utils";
import { useInput, useModal } from "hooks";
import { useParams, navigate } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { omit } from "lodash";
import useKeypress from "react-use-keypress";
import { useAuthContext } from "contexts";
import { format } from "date-fns";
import {
  OFFER_SUGGESTION_TITLE,
  INCENTIVE_SUGGESTION_TITLE,
  OFFER_INIT_SUGGESTIONS,
  OFFER_INIT_INFO,
  INCENTIVE_INIT_SUGGESTION,
  INCENTIVE_INIT_INFO,
  INCENTIVE_INFO_FIELDS,
  OFFER_INFO_FIELDS,
  INIT_OFFER_METADATA,
  EXTRA_INCENTIVE_SUGGESTIONS,
} from "config";

export const OfferPage = () => {
  const params = useParams();
  const blockRef = useRef();
  const {
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    activeField,
    setActiveField,
    setFieldNames,
    moveNextField,
    updateActiveInfo,
    currentInfo,
    setCurrentInfo,
    setSessionId,
    activeFieldValue,
    updateInfo,
    setMetaData,
    setMetaDataFromPayload,
    isManualEntry,
    setIsManualEntry,
    movePreviousField,
    getMetaData,
    isSubmitting,
    setIsSubmitting,
    updateInfos,
  } = useMainContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);
  //Adblock Flags
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const [isDoNotEnter, setIsDoNotEnter] = useState(false);

  //Base List Data
  const [pageData, setPageData] = useState([]);
  const [adblockData, setAdblockData] = useState([]);
  const [offerTypeData, setOfferTypeData] = useState([]);
  const [circularName, setCircularName] = useState("");

  //Currently Viewed Information
  const [currentAdblockData, setCurrentAdblockData] = useState({});
  const [isDeepLinking, setIsDeepLinking] = useState(false);
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");

  //Offer list from adblock
  const [offerData, setOfferData] = useState([]);
  const currentOfferIndex = useInput(0);
  const [allOffersValid, setAllOffersValid] = useState(false);
  const [offerSuggestionData, setOfferSuggestionData] = useState(
    OFFER_INIT_SUGGESTIONS
  );

  //Incentives list from adblock
  const [incentiveData, setIncentiveData] = useState([]);
  const [incentiveSuggestionData, setIncentiveSuggestionData] = useState(
    INCENTIVE_INIT_SUGGESTION
  );

  //Adblock data
  const events = useInput([]);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });

  //Form Flow
  const [activeOfferInfoText, setActiveOfferInfoText] = useState("");

  //Suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  //Modals and user information
  const endQueueModal = useModal();
  const deleteOfferModal = useModal();
  const { userInfo } = useAuthContext();
  const [deletingOffer, setDeletingOffer] = useState({});
  const [currentItemType, setCurrentItemType] = useState("offer");

  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  const init = async () => {
    setCurrentPage("LR - Offer");
    setPreviousTime(new Date());
    setIsLoading(true);
    setSessionId(String(new Date()));
    setOfferData([]);
    setIncentiveData([]);
    setImageHeight(blockRef.current.offsetHeight);
    setImageWidth(blockRef.current.offsetWidth);
    setMetaData(INIT_OFFER_METADATA);

    const data = await getOfferTypesApi();
    setOfferTypeData(
      data.payload.map((item) => ({
        ...item,
        id: item.name,
        title: item.name,
      }))
    );
    setOfferSuggestionData({
      ...offerSuggestionData,
      offer_type: data.payload
        .sort((a, b) => a.ext_id - b.ext_id)
        .slice(0, 9)
        .map((item) => {
          return {
            title: item.name,
            value: item.name,
          };
        }),
    });
    setIsLoading(false);
  };

  const initInfo = () => {
    setActiveField("");
    setMetaData(INIT_OFFER_METADATA);
  };

  /*
  ///////////////////////////////////////
  ////// Watchers /////////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      params?.circular !== "0" &&
      params?.page !== "0" &&
      params?.adblock !== "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);
      setCurrentAdBlockId(params?.adblock);
      setIsDeepLinking(true);
    } else if (
      params?.circular !== "0" &&
      params?.page === "0" &&
      params?.adblock === "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId("");
      setCurrentAdBlockId("");
    } else if (
      params?.circular !== "0" &&
      params?.page !== "0" &&
      params?.adblock === "0"
    ) {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);
      setCurrentAdBlockId("");
    }

    return () => {};
  }, [params?.circular, params?.page, params?.adblock]);

  useEffect(() => {
    if (currentAdBlockId) {
      setOfferData([]);
      setIncentiveData([]);
      fetchAdBlock(currentAdBlockId, adblockData, true);
    }
    return () => {};
  }, [currentAdBlockId, adblockData]);

  useEffect(() => {
    if (currentCircularId) {
      fetchCircular();
    }
    return () => {};
  }, [currentCircularId]);

  useEffect(() => {
    if (
      isManualEntry &&
      activeField !== "" &&
      activeField.indexOf("_f") === -1 &&
      activeField !== "offer_type"
    ) {
      //Setting the timeout prevents the keypress from populating an input
      setTimeout(() => {
        if (document.getElementById(activeField))
          document.getElementById(activeField).focus();
      });
    }
    return () => {};
  }, [isManualEntry]);

  useEffect(() => {
    setAllOffersValid(
      offerData.every(function (e) {
        return e?.isValid;
      }) &&
        incentiveData.every(function (e) {
          return e?.isValid;
        })
    );
    return () => {};
  }, [offerData, incentiveData]);

  useEffect(() => {
    if (activeField && currentItemType) {
      setCurrentSuggestions(
        currentItemType === "offer"
          ? offerSuggestionData[activeField]
          : incentiveSuggestionData[activeField] || []
      );
      if (
        currentInfo["offer_type"] === "w/purchase case" &&
        activeField === "offer_text"
      ) {
        setCurrentSuggestions([
          {
            value: "w/purchase whole case",
            title: "w/purchase whole case",
          },
          {
            value: "w/purchase half case",
            title: "w/purchase half case",
          },
        ]);
      } else if (
        currentInfo["offer_type"] === "w/purchase product" &&
        activeField === "offer_text"
      ) {
        let products = [];
        if (
          currentAdblockData?.products &&
          currentAdblockData?.products.length > 0
        ) {
          products =
            currentAdblockData?.products.map((p) => ({
              title: p.brand_nm,
              value: p.brand_nm,
            })) || [];
        } else if (
          currentAdblockData?.suggestions?.products &&
          currentAdblockData?.suggestions?.products.length > 0
        ) {
          products =
            currentAdblockData?.suggestions?.products.map((p) => ({
              title: p.brand_nm,
              value: p.brand_nm,
            })) || [];
        }
        setCurrentSuggestions(products);
      } else if (
        [
          "Clearance",
          "Extra Coupons",
          "Free Shipping",
          "Pharmacy Services",
          "Rollback",
          "Saturdate",
          "Sweepstakes",
          "Earn Free Item",
          "w/agreement",
        ].includes(currentInfo["incentive_type"]) &&
        activeField === "incentive_text"
      ) {
        setCurrentSuggestions(
          EXTRA_INCENTIVE_SUGGESTIONS[currentInfo["incentive_type"]]
        );
      }
      setActiveOfferInfoText(
        currentItemType === "offer"
          ? OFFER_SUGGESTION_TITLE[activeField]
          : INCENTIVE_SUGGESTION_TITLE[activeField]
      );
    }

    return () => {};
  }, [activeField]);

  useEffect(() => {
    if (Object.keys(currentInfo).length > 0) {
      if (currentItemType === "offer") {
        let temp = [...offerData];
        temp[currentOfferIndex.value] = {
          ...currentInfo,
          isValid: validateOffer(currentInfo),
        };
        setOfferData(temp);
      } else if (currentItemType === "incentive") {
        // let temp = [...incentiveData];
        // setIncentiveData(
        //   temp.map((item) => {
        //     if (item.index === currentInfo.index)
        //       return {
        //         ...currentInfo,
        //         isValid: validateIncentive(currentInfo),
        //       };
        //     return { ...item };
        //   })
        // );

        let temp2 = [...incentiveData];
        temp2[currentInfo.index] = {
          ...currentInfo,
          isValid: validateIncentive(currentInfo),
        };
        console.log("incentive ===", currentInfo, temp2);
        setIncentiveData(temp2);
      }
    }
    return () => {};
  }, [currentInfo]);

  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchCircular = async () => {
    const cirIncentiveData = await getCircularIncentives(currentCircularId);
    setIncentiveSuggestionData({
      ...incentiveSuggestionData,
      incentive_text: cirIncentiveData.map((item) => ({
        title: item,
        value: item,
      })),
    });
    const result = await getCircularById(currentCircularId);
    if (result.success) {
      //setCircularData(result.payload);
      setCircularName(
        result.payload.retailer.name +
          "_" +
          format(new Date(result.payload.circ_week_dt), "MM/dd/yyyy")
      );
    } else {
      //TODO: set error message about no circular matching
    }
  };

  const onPageSelect = async (params, data = [], initAfter = true) => {
    setCurrentPageId(params);
    setPageData(data);
    const currentPage = data.find((page) => page.uuid === params);
    if (currentPage) {
      const img = await getImage(currentPage.filename);
      setPageWidth(currentPage.img_width);
      setPageHeight(currentPage.img_height);
      setPageImg(img.payload);
    }
    if (!isDeepLinking) {
      setCurrentAdBlockId("");
    }
  };

  const fetchAdBlock = async (
    adblockId,
    blockData = [],
    showLoading = true
  ) => {
    setIsFetching(true && showLoading);

    setIsApproved(false);
    setIsRejected(false);
    setIsDoNotEnter(false);
    setIsSkipped(false);
    setIncentiveData([]);

    const payload = adblockData.find((item) => item.uuid === currentAdBlockId);
    console.log("payload", adblockData, payload, currentAdBlockId);
    if (payload) {
      setIsManualEntry(true);
      setCurrentAdblockData(payload);
      setIsDoNotEnter(payload?.do_not_enter_f);
      setIsSkipped(payload?.skipped);
      setIsRejected(payload?.rejected);
      setAdblockCoords({
        coords_x1: payload.coords_x1,
        coords_x2: payload.coords_x2,
        coords_y1: payload.coords_y1,
        coords_y2: payload.coords_y2,
      });
      events.setValue(payload?.event_nm ? payload?.event_nm.split(",") : []);
      let offersData = [];
      //This means the adblcok is approved
      if (payload.offers.length > 0) {
        setIsApproved(true);
        setIsManualEntry(true);

        offersData = payload.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else if (
        payload?.suggestions &&
        payload?.suggestions?.offers.length > 0
      ) {
        offersData = payload.suggestions.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else {
        initInfo();
      }

      setOfferData(offersData);
      let tempIncentives = [];
      if (payload?.incentive_text) {
        tempIncentives = [
          ...payload?.incentive_text.split(",").map((item) => ({
            incentive_text: item,
            incentive_attach_to: "Adblock",
          })),
        ];
      }

      const pageData = await getPageById(currentPageId);
      if (pageData?.success && pageData.payload?.incentive_nm) {
        tempIncentives = [
          ...tempIncentives,
          ...pageData.payload?.incentive_nm.split(",").map((item) => ({
            incentive_text: item,
            incentive_attach_to: "Page",
          })),
        ];
      }
      const circularData = await getCircularById(currentCircularId);
      if (circularData?.success && circularData.payload?.incentive_nm) {
        tempIncentives = [
          ...tempIncentives,
          ...circularData.payload?.incentive_nm.split(",").map((item) => ({
            incentive_text: item,
            incentive_attach_to: "Circular",
          })),
        ];
      }
      tempIncentives = tempIncentives.map((item, index) => ({
        ...item,
        index,
      }));
      setIncentiveData(tempIncentives);
      if (offersData.length > 0) {
        setCurrentInfo(offersData[0]);
        currentOfferIndex.setValue(0);
        setCurrentItemType("offer");
        setFieldNames(OFFER_INFO_FIELDS);
        setOfferSuggestionData({
          ...offerSuggestionData,
          offer_text: offersData.slice(0, 9).map((item, index) => {
            return {
              title: item.offer_text,
              value: item.offer_text,
            };
          }),
        });
      }
      setMetaDataFromPayload(payload);
      setAdblockFilename(payload.filename);

      postPageEvents("ready", "");
      navigate(`/offer/${currentCircularId}/${currentPageId}/${adblockId}`);
    }
    setIsFetching(false && showLoading);
  };

  const handleAdblockSelect = (params, data = []) => {
    setIsDeepLinking(false);
    if (!params && data.length === 0) {
      setCurrentAdBlockId("");
      setAdblockData([]);
      setCurrentSuggestions([]);
      setOfferData([]);
      initInfo();
      setIsFetching(false);
      navigate(`/offer/${currentCircularId}/${currentPageId}/0`);
    } else {
      setCurrentAdBlockId(params);
      setAdblockData(data);
      fetchAdBlock(params, data);
    }
  };

  const onAdblockDataLoad = (params = []) => {
    setAdblockData(params);
  };

  const refetchAdblocks = async () => {
    const result = await getPageAdblocks(currentPageId);
    const newData = result?.payload?.adblocks;
    setAdblockData(newData);
  };
  const navigateNext = () => {
    //Turn manual entry off when going to next set
    setIsManualEntry(false);

    const pageIndex = pageData.findIndex((item) => item.uuid === currentPageId);
    const adblockIndex = adblockData.findIndex(
      (item) => item.uuid === currentAdBlockId
    );
    if (adblockIndex < adblockData.length - 1) {
      setCurrentAdBlockId(adblockData[adblockIndex + 1].uuid);
    } else if (
      adblockIndex === adblockData.length - 1 &&
      pageIndex < pageData.length - 1
    ) {
      setCurrentPageId(pageData[pageIndex + 1].uuid);
      setCurrentAdBlockId("");
    } else if (
      adblockIndex === adblockData.length - 1 &&
      pageIndex === pageData.length - 1
    ) {
      endQueueModal.openModal();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async () => {
    setIsSubmitting(true);

    let result = await rejectAdblock(currentAdBlockId, "offer");
    if (result.success) {
      // Update adblocks
      result = await getPageAdblocks(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);

      navigateNext();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);

    const int_keys = [
      "reg_price",
      "limit",
      "coupon_amount",
      "rebate_amount",
      "min_qty",
    ];

    let offers = offerData.map((offer) => omit(offer, ["index", "isValid"]));
    offers.forEach((offer) => {
      Object.entries(offer).forEach(([key, value]) => {
        if (value === "" && int_keys.indexOf(key) !== -1) {
          offer[key] = null;
        }
      });
    });

    let data = {
      adblock: {
        ...getMetaData(),
        event_nm: events.value.join(),
        incentive_text: incentiveData
          .filter(
            (a) => validateIncentive(a) && a?.incentive_attach_to === "Adblock"
          )
          .map((a) =>
            a?.incentive_limit
              ? `${a.incentive_text} (${a.incentive_limit})`
              : a.incentive_text
          )
          .join(","),
      },
      offers: offers,
    };

    await saveCircularIncentives(
      currentCircularId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Circular"
      )
    );
    await savePageIncentives(
      currentPageId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Page"
      )
    );
    let result = await approveOffer(currentAdBlockId, data);
    if (result.success) {
      //Update adblocks
      result = await getPageAdblocks(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      setIsApproved(true);
      navigateNext();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    let result = await skipAdblock(currentAdBlockId, {}, "offers");
    if (result.success) {
      // Update adblocks
      result = await getPageAdblocks(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      navigateNext();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    let result = await doNotEnterAdblock(currentAdBlockId, {}, "offers");
    if (result.success) {
      // Update adblocks
      result = await getPageAdblocks(currentPageId);
      setAdblockData(result?.payload?.adblocks || []);
      navigateNext();
    } else {
      //TODO: Show error message
    }
    setIsSubmitting(false);
  };

  const handleAddItem = (type) => {
    switch (type) {
      case "offer":
        handleAddOffer();
        break;
      case "incentive":
        handleAddIncentive();
        break;

      default:
        break;
    }
    setCurrentItemType(type);
  };

  /*
  ///////////////////////////////////////
  ////// Offer Controls /////////////
  /////////////////////////////////////
  */

  const handleAddOffer = () => {
    const newIndex =
      offerData.length > 0 ? offerData[offerData.length - 1].index + 1 : 0;
    const newOffer = {
      ...OFFER_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setOfferData([...offerData, newOffer]);
    currentOfferIndex.setValue(newIndex);
    setCurrentInfo(newOffer);
    setFieldNames(OFFER_INFO_FIELDS);
    setCurrentItemType("offer");
    console.log("offer index", newIndex);
  };

  const handleRemoveOffer = (event, index) => {
    if (!isApproved) {
      const idx = offerData.findIndex((offer) => offer.index === index);
      console.log("delete offer idx", idx);
      const newOffersData = offerData
        .filter((item) => item?.index !== index)
        .map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));

      setOfferData(newOffersData);

      if (currentItemType === "offer") {
        if (newOffersData.length === 0) {
          setCurrentInfo({});
          currentOfferIndex.setValue(0);
          if (incentiveData.length > 0) {
            setCurrentInfo(incentiveData[0]);
            setCurrentItemType("incentive");
          }
        } else {
          const newIdx = newOffersData.length > idx ? idx : idx - 1;
          currentOfferIndex.setValue(newOffersData[newIdx].index);
          console.log("current info 111 ===", newOffersData[newIdx]);
          setCurrentInfo(newOffersData[newIdx]);
        }
      }
    } else {
      setDeletingOffer(offerData.find((offer) => offer.index === index));
      deleteOfferModal.openModal();
    }
  };

  const handleCloneOffer = (event, index) => {
    event.stopPropagation();
    if (offerData.length > 0) {
      const newIndex = offerData[offerData.length - 1].index + 1;
      setOfferData([
        ...offerData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentOfferIndex.setValue(newIndex);
    }
  };

  const handleOfferSelect = (index) => {
    currentOfferIndex.setValue(index);
    setCurrentItemType("offer");
    setFieldNames(OFFER_INFO_FIELDS);
    setActiveField(OFFER_INFO_FIELDS[0]);
    setCurrentInfo(offerData[index]);
  };
  ///////////////////////////////////////
  ////// Incentive Controls /////////////
  /////////////////////////////////////

  const handleAddIncentive = () => {
    const newIndex =
      incentiveData.length > 0
        ? incentiveData[incentiveData.length - 1].index + 1
        : 0;
    const newIncentive = {
      ...INCENTIVE_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setIncentiveData([...incentiveData, newIncentive]);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setCurrentInfo(newIncentive);
  };

  const handleRemoveIncentive = (event, index) => {
    const idx = incentiveData.findIndex(
      (incentive) => incentive.index === index
    );
    const newIncentivesData = incentiveData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));
    let newCurrentInfo = {};
    if (currentItemType === "incentive") {
      if (newIncentivesData.length === 0) {
        newCurrentInfo = { index: 0 };
        setCurrentItemType("");
        if (offerData.length > 0) {
          console.log("offerData", offerData);
          setCurrentItemType("offer");
          currentOfferIndex.setValue(offerData[0].index);
          setCurrentInfo(offerData[0]);
        }
      } else {
        const newIdx =
          newIncentivesData.length === 1
            ? 0
            : newIncentivesData.length > idx
            ? idx
            : idx - 1;
        newCurrentInfo = newIncentivesData[newIdx];
        setCurrentInfo(newCurrentInfo);
      }
    }
    setIncentiveData(newIncentivesData);
  };

  const handleCloneIncentive = (event, index) => {
    event.stopPropagation();

    if (incentiveData.length > 0) {
      const newIndex = incentiveData[incentiveData.length - 1].index + 1;
      setIncentiveData([
        ...incentiveData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
    }
  };

  const handleIncentiveSelect = (index) => {
    console.log("incentive select", index, { ...incentiveData[index] });
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setActiveField(INCENTIVE_INFO_FIELDS[0]);
    setCurrentInfo(incentiveData[index]);
  };

  useEffect(() => {
    if (offerData.length === 0 && incentiveData.length === 0) {
      setCurrentItemType("");
      setActiveField("");
    }
    // else if (offerData.length === 0 && incentiveData.length > 0) {
    //   setCurrentItemType("incentive");
    // } else if (offerData.length > 0 && incentiveData.length === 0) {
    //   setCurrentItemType("offer");
    // }
    return () => {};
  }, [offerData, incentiveData]);

  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    postPageEvents("click", "Refresh Quick Selection");
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      let offers = [];
      //This means the adblock is approved
      if (result.payload.offers.length > 0) {
        setIsApproved(true);
        offers = result.payload.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else if (result.payload.suggestions.offers.length > 0) {
        offers = result.payload.suggestions.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      }
      setOfferData(offers);
      if (offers.length > 0) {
        setCurrentInfo(offers[0]);
        currentOfferIndex.setValue(0);
        setCurrentItemType("offer");
        setFieldNames(OFFER_INFO_FIELDS);
      }
    }
    setSuggestionsLoading(false);
  };

  /*
  ///////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  /////////////////////////////////////
  */

  const handleSuggestionClick = (value, event) => {
    if (value === "Manual") {
      setIsManualEntry(true);
    } else {
      if (activeField === "offer_type") {
        updateInfos({
          offer_text: INIT_OFFER_TEXT[value.toLowerCase()],
          offer_type: value,
        });
      } else {
        updateActiveInfo(value);
      }
      moveNextField();
    }
  };

  /*
  ///////////////////////////////////////////////
  ////// Incentive Info Change //////////////////
  ///////////////////////////////////////////////
  */

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var value, title;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        value = "Manual";
      } else {
        value = currentSuggestions[parseInt(event.key) - 1].value;
        title = currentSuggestions[parseInt(event.key) - 1].title;
      }

      handleSuggestionClick(value, title);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      ////setInputFocused(false);
      setIsManualEntry(false);
    }
    if (document.activeElement) {
      document.activeElement.blur();
    }
  });

  //Remove Offers
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveOffer(event);
    // }
  });

  //Add Offers
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddOffer();
    }
  });

  //Clone Offers
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneOffer();
    // }
  });

  //Cycle through offers
  useKeypress(["["], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      //If we go backwards to the start, go back to top.
      if (currentOfferIndex.value === 0) {
        currentOfferIndex.setValue(offerData.length - 1);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value - 1);
      }
    } else {
      //If we go forward to the end, go back to start.
      if (currentOfferIndex.value === offerData.length - 1) {
        currentOfferIndex.setValue(0);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value + 1);
      }
    }
  });

  //Approve offers
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      handleApprove();
    }
  });

  //Approve offers
  useKeypress(["w", "W"], (event) => {
    if (event.altKey) {
      handleDoNotEnterAdblock();
    }
  });

  //Approve offers--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //Approve offers
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  //Move to next field item, only if not in manual entry
  useKeypress(["Tab"], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      movePreviousField();
    } else {
      moveNextField();
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {!userInfo.user.is_superuser ? (
                  <>
                    <div className="ml-1 my-auto">Circulars</div>
                    <div className="ml-2 my-auto">{circularName}</div>
                  </>
                ) : (
                  <>
                    <Link href="/circular" className="ml-1 my-auto">
                      Circulars
                    </Link>
                    <Link
                      href={`/circular/${currentCircularId}/0`}
                      className="ml-2 my-auto"
                    >
                      {circularName}
                    </Link>
                  </>
                )}

                <PageDropDown
                  className="ml-3"
                  id={currentCircularId}
                  onSelectValue={onPageSelect}
                  value={currentPageId}
                  isVisible={userInfo?.user?.is_superuser}
                />

                <AdBlockDropDown
                  className="ml-3"
                  id={currentPageId}
                  onSelectValue={handleAdblockSelect}
                  value={currentAdBlockId}
                  isVisible={userInfo?.user?.is_superuser}
                  adblockData={adblockData}
                  onLoad={onAdblockDataLoad}
                />
              </div>

              <PageInfo
                className="ml-auto my-auto "
                pageData={pageData}
                adblockData={adblockData}
              />
              <FlagButtonGroup
                isSubmitting={isSubmitting}
                isFetching={isSubmitting}
                onDoNotEnter={handleDoNotEnterAdblock}
                onSkip={handleSkipAdblock}
                onReject={handleReject}
                onApprove={handleApprove}
                isDoNotEnter={isDoNotEnter}
                isSkipped={isSkipped}
                isRejected={isRejected}
                isApproved={isApproved}
                isAllOfferValid={allOffersValid}
              />
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onRefresh={refetchAdblocks}
                onChange={(e) => events.setValue(e)}
              />
              {(offerData.length > 0 || incentiveData.length) && (
                <MetaData className="mt-3" />
              )}
            </MetadataSection>
          </div>
          <AdblockImage
            type="offer"
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            filename={adblockFilename}
            currentAdBlockData={currentAdblockData}
            circularId={currentCircularId}
            pageId={currentPageId}
          />
          <div className="d-flex flex-column">
            <OfferSelector
              title={`OFFERS & INCENTIVES ${
                currentOfferIndex.value +
                1 +
                (currentItemType === "incentive" ? currentInfo.index + 1 : 0)
              }/${offerData.length + incentiveData.length}`}
              onAdd={handleAddItem}
              isAddable
            >
              {offerData.length === 0 && incentiveData.length === 0 ? (
                <EmptyOfferSelector
                  onCreateOffer={handleAddOffer}
                  onCreateIncentive={handleAddIncentive}
                />
              ) : (
                <div className="d-flex">
                  <ItemsList>
                    {offerData.map((offer, index) => (
                      <OfferItem
                        key={`offer${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentOfferIndex.value &&
                          currentItemType === "offer"
                        }
                        onClick={() => handleOfferSelect(index)}
                        onRemove={handleRemoveOffer}
                        onClone={handleCloneOffer}
                        data={offer}
                        index={index}
                      />
                    ))}
                    {incentiveData.map((incentive, index) => (
                      <IncentiveItem
                        key={`incentive${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentInfo.index &&
                          currentItemType === "incentive"
                        }
                        onClick={() => handleIncentiveSelect(index)}
                        onRemove={handleRemoveIncentive}
                        onClone={handleCloneIncentive}
                        data={incentive}
                        index={index}
                        incentiveData={incentiveData}
                        isApproved={isApproved}
                      />
                    ))}
                  </ItemsList>
                </div>
              )}
            </OfferSelector>
            <div
              className="d-flex flex-1"
              style={{ height: "calc(100% - 195px)" }}
            >
              <QuickSelection
                onRefreshSuggestion={() => refreshAdblockSuggestions()}
                isRefresh={adblockData.length > 0}
              >
                {isManualEntry && !isApproved && (
                  <ManualEntry onExit={() => setIsManualEntry(false)} />
                )}
                {((activeField === "offer_text" && isManualEntry) ||
                  (activeField === "offer_text" &&
                    currentSuggestions.length === 0)) && (
                  <OfferTextBuilder
                    type={currentInfo?.offer_type}
                    value={currentInfo?.offer_text}
                    onChange={(e) => {
                      updateInfo("offer_text", e);
                    }}
                  />
                )}
                {((activeField === "incentive_text" && isManualEntry) ||
                  (activeField === "incentive_text" &&
                    currentSuggestions.length === 0)) && (
                  <IncentiveTextBuilder
                    type={currentInfo?.incentive_type}
                    value={currentInfo?.incentive_text}
                    onChange={(e) => {
                      updateInfo("incentive_text", e);
                    }}
                  />
                )}
                {
                  //TODO: this part should have its own loading state
                  isFetching || suggestionsLoading ? (
                    <div>Loading...</div>
                  ) : offerData.length === 0 && incentiveData.length === 0 ? (
                    <div className="mt-4 pt-4 text-center">
                      No offer/incentive available
                    </div>
                  ) : (
                    <>
                      <div className="text-blue-light mb-3">
                        {activeOfferInfoText}
                      </div>

                      {isApproved ? (
                        <AdblockApproved />
                      ) : currentSuggestions &&
                        currentSuggestions.length > 0 ? (
                        <>
                          {!isManualEntry && (
                            <QuickSelectionChoice
                              index={0}
                              data={{
                                title: "Manual Entry",
                                value: "Manual",
                              }}
                              active={false}
                              showIndex={!isManualEntry}
                              onClick={handleSuggestionClick}
                            />
                          )}

                          {currentSuggestions
                            .slice(
                              0,
                              isManualEntry ? currentSuggestions.length : 9
                            )
                            .filter((item) => item.title)
                            .map((item, index) => (
                              <QuickSelectionChoice
                                key={`${index}key`}
                                showIndex={!isManualEntry}
                                index={index + (isManualEntry ? 0 : 1)}
                                data={item}
                                active={activeFieldValue === item.value}
                                onClick={handleSuggestionClick}
                              />
                            ))}
                        </>
                      ) : (
                        <>
                          <NoSuggestions />
                        </>
                      )}
                    </>
                  )
                }
              </QuickSelection>

              {currentItemType === "offer" ? (
                <OfferInfo offerTypeData={offerTypeData} />
              ) : currentItemType === "incentive" ? (
                <IncentiveInfo />
              ) : (
                <EmptyInfo />
              )}
            </div>
          </div>
        </Content>
        <EndQueueModal
          {...endQueueModal}
          circularId={currentCircularId}
          goto="circular"
        />
        <OfferDeletionModal
          {...deleteOfferModal}
          adblockId={currentAdBlockId}
          pageId={currentPageId}
          onDelete={async (data) => {
            setAdblockData(data);
          }}
          deletingOffer={deletingOffer}
        />
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 14px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 120px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
