/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import { navigate } from "@reach/router";

export const EndQueueModal = ({
  open,
  onClose,
  goto = "product",
  circularId,
}) => {
  const handleGotoOffers = () => {
    switch (goto) {
      case "product":
        navigate(`/product/${circularId}/0/0`);
        break;
      case "offer":
        navigate(`/offer/${circularId}/0/0`);
        break;
      case "circular":
        navigate(`/circular/${circularId}/0`);
        break;

      default:
        break;
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"End of Queue"}>
      <div>
        <div>You have reached the end of the queue.</div>
        <div>
          Click the button below to be redirected to the{" "}
          {goto === "product"
            ? "Products"
            : goto === "offer"
            ? "Offers"
            : "Circular"}{" "}
          view.
        </div>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleGotoOffers()}
        >
          {goto === "product"
            ? "Go to Products"
            : goto === "offer"
            ? "Go to Offers"
            : "Go to Circular"}
        </Button>
      </div>
    </Modal>
  );
};
