import React from "react";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
export const ProductSelector = ({
  title,
  children,
  className,
  onAdd = () => {},
  isAddable = false,
}) => {
  return (
    <Container className={className}>
      <Header>
        <div>{title}</div>
        {isAddable && (
          <div className="d-flex ml-auto">
            <Icon onClick={onAdd}>
              <FaPlus />
            </Icon>
          </div>
        )}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  color: white;
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
`;
const Icon = styled.div`
  margin: auto 4px;
  transition: all 300ms ease;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  &:hover {
    opacity: 0.7;
  }
`;
