import React from "react";
import styled from "styled-components";

export const Card = ({ title, children, className, isCollapsable }) => {
  return (
    <Container className={className}>
      {title && (
        <Header>
          <div>{title}</div>
        </Header>
      )}
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
`;
