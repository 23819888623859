export const OFFER_SUGGESTION_TITLE = {
  offer_type: "What is the offer type?",
  offer_text: "What is the offer text for this offer?",
  reg_price: "What is this offers regular price?",
  limit: "Is there a limit to purchases?",
  coupon_f: "Does the offer have a coupon?",
  coupon_amount: "What is the coupon amount?",
  rebate_f: "Does the offer have a rebate?",
  rebate_amount: "What is the rebate amount?",
  min_qty_dollar_f: "Does the offer have minimum qualifier?",
  min_qty: "What is the minimum qualifier amount?",
  must_buy_f: "Does the offer have must buy amounts?",
  qty_off_total_purchase_f: "Does the offer apply to the total purchase?",
  mix_and_match_f: "Does the offer allow mix and match?",
  include_in_net_cost_f: "Does the offer include net cost?",
  liner_f: "Does the offer have a liner?",
};
export const OFFER_INFO_FIELDS = [
  "offer_type",
  "offer_text",
  "reg_price",
  "limit",
  "coupon_f",
  "coupon_amount",
  "rebate_f",
  "rebate_amount",
  "min_qty_dollar_f",
  "min_qty",
  "must_buy_f",
  "qty_off_total_purchase_f",
  "mix_and_match_f",
  "include_in_net_cost_f",
  "liner_f",
];

export const INCENTIVE_INFO_FIELDS = [
  "incentive_type",
  "incentive_text",
  "incentive_attach_to",
  "incentive_limit",
  "incentive_include_net_cost",
];

export const INCENTIVE_SUGGESTION_TITLE = {
  incentive_type: "What is the incentive type?",
  incentive_attach_to: "What is the incentive attached to?",
  incentive_text: "What is the incentive text?",
  incentive_limit: "What is the incentive limit?",
  incentive_include_net_cost: "Is it included in Net Cost?",
};
const YES_NO_SUGGESTION = [
  { title: "No", value: false },
  { title: "Yes", value: true },
];

export const INCENTIVE_TYPE_DATA = [
  {
    id: "Additional Coupon",
    title: "Additional Coupon",
  },
  {
    id: "Air Miles",
    title: "Air Miles",
  },
  {
    id: "Clearance",
    title: "Clearance",
  },
  {
    id: "Coupons",
    title: "Coupons",
  },
  {
    id: "Credit Card 1",
    title: "Credit Card 1",
  },
  {
    id: "Credit Card 2",
    title: "Credit Card 2",
  },
  {
    id: "Download",
    title: "Download",
  },
  {
    id: "Earn Free Item",
    title: "Earn Free Item",
  },
  {
    id: "Enter Purchase Savings",
    title: "Enter Purchase Savings",
  },
  {
    id: "Extra Coupons",
    title: "Extra Coupons",
  },
  {
    id: "Extra Savings",
    title: "Extra Savings",
  },
  {
    id: "Free Item",
    title: "Free Item",
  },
  {
    id: "Free Shipping",
    title: "Free Shipping",
  },
  {
    id: "Gas",
    title: "Gas",
  },
  {
    id: "Next Shopping Trip",
    title: "Next Shopping Trip",
  },
  {
    id: "Pharmacy Services",
    title: "Pharmacy Services",
  },
  {
    id: "Rewards",
    title: "Rewards",
  },
  {
    id: "Rollback",
    title: "Rollback",
  },
  {
    id: "Saturdate",
    title: "Saturdate",
  },
  {
    id: "Sweepstakes",
    title: "Sweepstakes",
  },
  {
    id: "Trade-in savings",
    title: "Trade-in savings",
  },
];

export const INCENTIVE_ATTACH_TO_DATA = [
  { id: "Adblock", title: "Adblock" },
  { id: "Page", title: "Page" },
  { id: "Circular", title: "Circular" },
];

export const OFFER_INIT_SUGGESTIONS = {
  offer_type: [],
  offer_text: [],
  reg_price: [],
  limit: [],
  coupon_f: YES_NO_SUGGESTION,
  coupon_amount: [],
  rebate_f: YES_NO_SUGGESTION,
  rebate_amount: [],
  min_qty_dollar_f: YES_NO_SUGGESTION,
  min_qty: [],
  must_buy_f: YES_NO_SUGGESTION,
  qty_off_total_purchase_f: YES_NO_SUGGESTION,
  mix_and_match_f: YES_NO_SUGGESTION,
  include_in_net_cost_f: YES_NO_SUGGESTION,
  liner_f: YES_NO_SUGGESTION,
};

export let OFFER_INIT_INFO = {
  offer_type: "",
  offer_text: "",
  reg_price: "",
  limit: "",
  coupon_f: false,
  coupon_amount: "",
  rebate_f: false,
  rebate_amount: "",
  min_qty_dollar_f: false,
  min_qty: "",
  must_buy_f: false,
  qty_off_total_purchase_f: false,
  mix_and_match_f: false,
  include_in_net_cost_f: false,
  liner_f: false,
};

export const INCENTIVE_INIT_SUGGESTION = {
  incentive_type: INCENTIVE_TYPE_DATA.sort((a, b) => a.ext_id - b.ext_id)
    .slice(0, 9)
    .map((item) => {
      return {
        title: item.title,
        value: item.title,
      };
    }),
  incentive_attach_to: [
    { title: "Adblock", value: "Adblock" },
    { title: "Page", value: "Page" },
    { title: "Circular", value: "Circular" },
  ],
  incentive_text: [],
  incentive_limit: [],
  incentive_include_net_cost: YES_NO_SUGGESTION,
};

export const INCENTIVE_INIT_INFO = {
  incentive_type: "",
  incentive_attach_to: "Adblock",
  incentive_text: "",
  incentive_limit: "",
  incentive_include_net_cost: false,
};

export const INIT_OFFER_METADATA = [
  {
    label: "Brand Equity",
    key: "brand_equity_f",
    value: false,
  },
  {
    label: "Coupon",
    key: "coupon_f",
    value: false,
  },
  {
    label: "Inc Discount",
    key: "inc_discount_f",
    value: false,
  },
  {
    label: "Limited Time",
    key: "limitedtime_f",
    value: false,
  },
  {
    label: "Loyalty",
    key: "loyalty_f",
    value: false,
  },
  {
    label: "Meal Deal",
    key: "meal_deal_f",
    value: false,
  },
  {
    label: "Online Only",
    key: "online_only_f",
    value: false,
  },
  {
    label: "Show Math",
    key: "show_math_f",
    value: false,
  },
  {
    label: "Sugar Tax",
    key: "sugar_tax_f",
    value: false,
  },
];

export const INIT_OFFER_TEXT = {
  "price ea": "1.00",
  bogo: "B1G1F",
  save: "Save 10%",
  "& up": "$5 & Up",
  "x for": "2/$2",
  "w/purchase case": "w/purchase whole case",
  other: "",
  "w/purchase product": "w/purchase/",
  "w/purchase/$": "w/purchase/$10.00",
  "w/purchase/qty": "w/purchase/2",
  "w/purchase/qty/$": "w/purchase/2/$10",
  "free with": "Free w/coupon",
  coupon: "",
  recipe: "",
  "w/agreement": "w/Item(s)",
};

export const INIT_INCENTIVE_TEXT = {
  "Air Miles": "Earn 2000 air miles",
  Clearance: "Clearance",
  Coupons: "Receive $ Coupons 1.00",
  Download: "Free Download",
  "Enter Purchase Savings": "Save $10.00 off entire purchase",
  "Extra Coupons": "Double Coupons",
  "Free Shipping": "Free Shipping",
  "Earn Free Item": "Earn free item within specified time frame",
  "Next Shopping Trip": "Save $7.00 on next shopping visit",
  "Pharmacy Services": "We do Treatments",
  Rewards: "Earn 10X Bonus Card Points",
  Rollback: "Rollback",
  Saturdate: "Join Us For A SaturDate",
  Sweepstakes: "Enter to Win",
  "Additional Coupon": "$2 Coupon Available Through Sunday Paper",
  "Credit Card 1": "No Interest for 12 months w/store credit card",
  "Credit Card 2": "Save $10.00 w/store credit card",
  "Free Item": "Free 2 Unit",
  Gas: "Earn $0.05 per gallon",
  "Trade-in savings": "$10.00 or more trade in savings",
  "Extra Savings": "Save $10.00 on item w/purchase",
};

export const EXTRA_INCENTIVE_SUGGESTIONS = {
  Clearance: [
    {
      title: "Clearance",
      value: "Clearance",
    },
  ],
  "Extra Coupons": [
    {
      title: "Double Coupons",
      value: "Double Coupons",
    },
    {
      title: "Triple Coupons",
      value: "Triple Coupons",
    },
    {
      title: "Quadruple Coupons",
      value: "Quadruple Coupons",
    },
    {
      title: "All Coupons = 1 set price",
      value: "All Coupons = 1 set price",
    },
  ],
  "Free Shipping": [
    {
      title: "Free Shipping",
      value: "Free Shipping",
    },
  ],
  "Pharmacy Services": [
    {
      title: "Automatic Refills",
      value: "Automatic Refills",
    },
    {
      title: "Compounding",
      value: "Compounding",
    },
    {
      title: "Medication Therapy Management",
      value: "Medication Therapy Management",
    },
    {
      title: "Open Enrollment",
      value: "Open Enrollment",
    },
    {
      title: "Pharmacy Consults",
      value: "Pharmacy Consults",
    },
    {
      title: "Pharmacy Services",
      value: "Pharmacy Services",
    },
    {
      title: "Price Matching",
      value: "Price Matching",
    },
    {
      title: "Health Screenings",
      value: "Health Screenings",
    },
    {
      title: "A1C Screenings",
      value: "A1C Screenings",
    },
    {
      title: "Allergy Screening",
      value: "Allergy Screening",
    },
    {
      title: "Blood Pressure Checks",
      value: "Blood Pressure Checks",
    },
    {
      title: "Body mas Index Screening (BMI)",
      value: "Body mas Index Screening (BMI)",
    },
    {
      title: "Bone Density Screenings",
      value: "Bone Density Screenings",
    },
    {
      title: "Cholesterol Screenings",
      value: "Cholesterol Screenings",
    },
    {
      title: "Diabetes Screenings",
      value: "Diabetes Screenings",
    },
    {
      title: "Foot Care Screenings",
      value: "Foot Care Screenings",
    },
    {
      title: "Heart Disease Screenings",
      value: "Heart Disease Screenings",
    },
    {
      title: "Heart Health Screenings",
      value: "Heart Health Screenings",
    },
    {
      title: "HIV Screenings",
      value: "HIV Screenings",
    },
    {
      title: "Liver Function Screenings",
      value: "Liver Function Screenings",
    },
    {
      title: "Mammography Screenings",
      value: "Mammography Screenings",
    },
    {
      title: "Physicals",
      value: "Physicals",
    },
    {
      title: "Prostate Screenings",
      value: "Prostate Screenings",
    },
    {
      title: "Prostate Screenings",
      value: "Prostate Screenings",
    },
    {
      title: "Thyroid Screenings",
      value: "Thyroid Screenings",
    },
    {
      title: "Immunizations",
      value: "Immunizations",
    },
    {
      title: "Educational Clinics",
      value: "Educational Clinics",
    },
    {
      title: "Treatments",
      value: "Treatments",
    },
  ],
  Rollback: [
    {
      title: "Rollback",
      value: "Rollback",
    },
  ],
  Saturdate: [
    {
      title: "Join Us For A SaturDate",
      value: "Join Us For A SaturDate",
    },
  ],
  Sweepstakes: [
    {
      title: "Enter to Win",
      value: "Enter to Win",
    },
  ],
  "Earn Free Item": [
    {
      title: "Earn free item within specified time frame",
      value: "Earn free item within specified time frame",
    },
  ],
  "w/agreement": [],
};

export const AGREEMENT_VALUES = [
  {
    title: "Item(s)",
    id: "Item(s)",
  },
  {
    title: "gift card",
    id: "gift card",
  },
  {
    title: "delivery",
    id: "delivery",
  },
  {
    title: "store service",
    id: "store service",
  },
  {
    title: "Immunizations",
    id: "Immunizations",
  },
  {
    title: "Treatments",
    id: "Treatments",
  },
  {
    title: "Physicals",
    id: "Physicals",
  },
  {
    title: "Pharmacy Services",
    id: "Pharmacy Services",
  },
  {
    title: "Price Matching",
    id: "Price Matching",
  },
  {
    title: "Immunization",
    id: "Immunization",
  },
  {
    title: "Pharmacy Service",
    id: "Pharmacy Service",
  },
  {
    title: "Pharmacist Consults",
    id: "Pharmacist Consults",
  },
  {
    title: "Mammography Screenings",
    id: "Mammography Screenings",
  },
  {
    title: "Blood Glucose Screening",
    id: "Blood Glucose Screening",
  },
  {
    title: "Blood Pressure Screening",
    id: "Blood Pressure Screening",
  },
  {
    title: "Compounding",
    id: "Compounding",
  },
  {
    title: "Open Enrollment",
    id: "Open Enrollment",
  },
  {
    title: "Memory Screenings",
    id: "Memory Screenings",
  },
  {
    title: "Health Risk Appraisal",
    id: "Health Risk Appraisal",
  },
  {
    title: "Heart Rate Check",
    id: "Heart Rate Check",
  },
  {
    title: "Wellness Monitoring",
    id: "Wellness Monitoring",
  },
  {
    title: "Cholesterol Screening",
    id: "Cholesterol Screening",
  },
  {
    title: "Bone Density Screening",
    id: "Bone Density Screening",
  },
  {
    title: "Waist Circumference Screening",
    id: "Waist Circumference Screening",
  },
  {
    title: "Body Mass Index Screening",
    id: "Body Mass Index Screening",
  },
  {
    title: "Medicine Disposal",
    id: "Medicine Disposal",
  },
  {
    title: "Breast Cancer Screening",
    id: "Breast Cancer Screening",
  },
  {
    title: "Cervical Cancer Screening",
    id: "Cervical Cancer Screening",
  },
  {
    title: "Colorectal Cancer Screening",
    id: "Colorectal Cancer Screening",
  },
  {
    title: "Diabetes Testing",
    id: "Diabetes Testing",
  },
  {
    title: "Education Clinics",
    id: "Education Clinics",
  },
  {
    title: "Automatic Refills",
    id: "Automatic Refills",
  },
  {
    title: "heart health screenings",
    id: "heart health screenings",
  },
  {
    title: "Medication Therapy Management",
    id: "Medication Therapy Management",
  },
  {
    title: "Bone Density Screenings",
    id: "Bone Density Screenings",
  },
  {
    title: "Vascular Screening",
    id: "Vascular Screening",
  },
  {
    title: "Educational Clinics",
    id: "Educational Clinics",
  },
  {
    title: "Heart Health Screenings",
    id: "Heart Health Screenings",
  },
  {
    title: "Foot Care Screenings",
    id: "Foot Care Screenings",
  },
  {
    title: "A1C Screenings",
    id: "A1C Screenings",
  },
  {
    title: "Heart Disease Screenings",
    id: "Heart Disease Screenings",
  },
  {
    title: "Stroke & Aneurysm Screenings",
    id: "Stroke & Aneurysm Screenings",
  },
  {
    title: "Prostate Screenings",
    id: "Prostate Screenings",
  },
  {
    title: "Cholesterol Screenings",
    id: "Cholesterol Screenings",
  },
  {
    title: "Diabetes Screenings",
    id: "Diabetes Screenings",
  },
  {
    title: "Thyroid Screenings",
    id: "Thyroid Screenings",
  },
  {
    title: "Liver Function Screenings",
    id: "Liver Function Screenings",
  },
  {
    title: "Body Mass Index Screening (BMI)",
    id: "Body Mass Index Screening (BMI)",
  },
  {
    title: "Blood Pressure Checks",
    id: "Blood Pressure Checks",
  },
  {
    title: "Coupon Available Through Sunday Paper",
    id: "Coupon Available Through Sunday Paper",
  },
  {
    title: "Video Value Coupon",
    id: "Video Value Coupon",
  },
  {
    title: "Free Shipping",
    id: "Free Shipping",
  },
  {
    title: "Site To Store Available",
    id: "Site To Store Available",
  },
  {
    title: "Free Shipping on Returns",
    id: "Free Shipping on Returns",
  },
  {
    title: "In-Store Returns Available",
    id: "In-Store Returns Available",
  },
  {
    title: "Same Day Pick-Up",
    id: "Same Day Pick-Up",
  },
  {
    title: "Health Screenings",
    id: "Health Screenings",
  },
  {
    title: "w/purchase/",
    id: "w/purchase/",
  },
  {
    title: "w/purchase/any",
    id: "w/purchase/any",
  },
  {
    title: "participating products",
    id: "participating products",
  },
  {
    title: "participating items",
    id: "participating items",
  },
  {
    title: "or more",
    id: "or more",
  },
  {
    title: "or less",
    id: "or less",
  },
  {
    title: "Allergy Screening",
    id: "Allergy Screening",
  },
  {
    title: "w/coupon",
    id: "w/coupon",
  },
  {
    title: "w/rebate",
    id: "w/rebate",
  },
  {
    title: "w/promo code",
    id: "w/promo code",
  },
  {
    title: "w/mail in rebate",
    id: "w/mail in rebate",
  },
  {
    title: "w/purchase/both",
    id: "w/purchase/both",
  },
  {
    title: "off other selected",
    id: "off other selected",
  },
  {
    title: "when you Like us on Facebook",
    id: "when you Like us on Facebook",
  },
  {
    title: "when you Follow us on Twitter",
    id: "when you Follow us on Twitter",
  },
  {
    title: "when you visit our website",
    id: "when you visit our website",
  },
  {
    title: "per serving",
    id: "per serving",
  },
  {
    title: "per serving, serves",
    id: "per serving, serves",
  },
  {
    title: "serves",
    id: "serves",
  },
  {
    title: "Recipe",
    id: "Recipe",
  },
  {
    title: "w/text message",
    id: "w/text message",
  },
  {
    title: "w/digital coupon",
    id: "w/digital coupon",
  },
  {
    title: "HIV Screening",
    id: "HIV Screening",
  },
  {
    title: "eSavers Coupon",
    id: "eSavers Coupon",
  },
  {
    title: "Qualified Upgrade",
    id: "Qualified Upgrade",
  },
  {
    title: "2 Year Activation",
    id: "2 Year Activation",
  },
  {
    title: "2 Year Activation and Trade-in Gift Card",
    id: "2 Year Activation and Trade-in Gift Card",
  },
  {
    title: "2 Year Activation on Both Devices",
    id: "2 Year Activation on Both Devices",
  },
  {
    title: "2 Year Activation or Upgrade",
    id: "2 Year Activation or Upgrade",
  },
  {
    title: "2 Year Agreement",
    id: "2 Year Agreement",
  },
  {
    title: "2 Year Agreement or 2 Year Qualified Upgrade",
    id: "2 Year Agreement or 2 Year Qualified Upgrade",
  },
  {
    title: "2 Year Agreement or Upgrade",
    id: "2 Year Agreement or Upgrade",
  },
  {
    title: "2 Year Contract",
    id: "2 Year Contract",
  },
  {
    title: "2 Year Contract and $100.00 Trade-in",
    id: "2 Year Contract and $100.00 Trade-in",
  },
  {
    title: "2 Year Plan",
    id: "2 Year Plan",
  },
  {
    title: "2 Year Qualified Upgrade",
    id: "2 Year Qualified Upgrade",
  },
  {
    title: "2 Year Service Agreement",
    id: "2 Year Service Agreement",
  },
  {
    title: "2 Year Service Plan",
    id: "2 Year Service Plan",
  },
  {
    title: "Agreement",
    id: "Agreement",
  },
  {
    title: "New 2 Year Activation",
    id: "New 2 Year Activation",
  },
  {
    title: "Qualifying 2 Year Agreement",
    id: "Qualifying 2 Year Agreement",
  },
  {
    title: "Qualifying 2 Year Plan and Data",
    id: "Qualifying 2 Year Plan and Data",
  },
  {
    title: "Qualifying New 2 Year Agreement",
    id: "Qualifying New 2 Year Agreement",
  },
  {
    title: "Select 2 Year Voice and Data Plans",
    id: "Select 2 Year Voice and Data Plans",
  },
  {
    title: "Service Agreement",
    id: "Service Agreement",
  },
  {
    title: "Upgrade 2 Year Activation",
    id: "Upgrade 2 Year Activation",
  },
  {
    title: "Upgrade",
    id: "Upgrade",
  },
  {
    title: "Auto-Pay",
    id: "Auto-Pay",
  },
  {
    title: "w/Mperks digital coupon",
    id: "w/Mperks digital coupon",
  },
  {
    title: "2 Year Activation And Trade-in",
    id: "2 Year Activation And Trade-in",
  },
  {
    title: "w/easy rebate",
    id: "w/easy rebate",
  },
  {
    title: "Installation",
    id: "Installation",
  },
  {
    title: "w/in-store coupon",
    id: "w/in-store coupon",
  },
  {
    title: "w/Load2Card digital coupon",
    id: "w/Load2Card digital coupon",
  },
  {
    title: "2 Year Contract Extension",
    id: "2 Year Contract Extension",
  },
  {
    title: "2 Year Plan and Trade-In",
    id: "2 Year Plan and Trade-In",
  },
  {
    title: "Qualifying 2 Year Plan",
    id: "Qualifying 2 Year Plan",
  },
  {
    title: "2 Year Premium Plus Plan",
    id: "2 Year Premium Plus Plan",
  },
  {
    title: "w/For U digital coupon",
    id: "w/For U digital coupon",
  },
  {
    title: "w/MyMixx digital coupon",
    id: "w/MyMixx digital coupon",
  },
  {
    title: "w/purchase/",
    id: "w/purchase/",
  },
  {
    title: "Meal Suggestion",
    id: "Meal Suggestion",
  },
  {
    title: "w/ClickList digital coupon",
    id: "w/ClickList digital coupon",
  },
  {
    title: "Free Shipping on Returns",
    id: "Free Shipping on Returns",
  },
  {
    title: "Free Return Label Printing",
    id: "Free Return Label Printing",
  },
  {
    title: "In-Store Returns Available",
    id: "In-Store Returns Available",
  },
  {
    title: "Same Day Pick-Up",
    id: "Same Day Pick-Up",
  },
  {
    title: "Site to Store Available",
    id: "Site to Store Available",
  },
  {
    title: "Curbside Pick-Up Available",
    id: "Curbside Pick-Up Available",
  },
  {
    title: "Free Shipping",
    id: "Free Shipping",
  },
  {
    title: "Free Delivery",
    id: "Free Delivery",
  },
  {
    title: "Immunizations-Chicken Pox",
    id: "Immunizations-Chicken Pox",
  },
  {
    title: "Immunizations-Japanese Encephalitis",
    id: "Immunizations-Japanese Encephalitis",
  },
  {
    title: "Immunizations-Flu",
    id: "Immunizations-Flu",
  },
  {
    title: "Immunizations-Hepatitis",
    id: "Immunizations-Hepatitis",
  },
  {
    title: "Immunizations-Hepatitis A",
    id: "Immunizations-Hepatitis A",
  },
  {
    title: "Immunizations-Hepatitis B",
    id: "Immunizations-Hepatitis B",
  },
  {
    title: "Immunizations-HPV",
    id: "Immunizations-HPV",
  },
  {
    title: "Immunizations-Measles",
    id: "Immunizations-Measles",
  },
  {
    title: "Immunizations-Meningitis",
    id: "Immunizations-Meningitis",
  },
  {
    title: "Immunizations-MMR",
    id: "Immunizations-MMR",
  },
  {
    title: "Immunizations-Pneumonia",
    id: "Immunizations-Pneumonia",
  },
  {
    title: "Immunizations-Rabies",
    id: "Immunizations-Rabies",
  },
  {
    title: "Immunizations-Shingles",
    id: "Immunizations-Shingles",
  },
  {
    title: "Immunizations-TDAP",
    id: "Immunizations-TDAP",
  },
  {
    title: "Immunizations-Tetanus",
    id: "Immunizations-Tetanus",
  },
  {
    title: "Immunizations-Typhoid",
    id: "Immunizations-Typhoid",
  },
  {
    title: "Immunizations-Whooping Cough",
    id: "Immunizations-Whooping Cough",
  },
  {
    title: "Immunizations-Polio",
    id: "Immunizations-Polio",
  },
  {
    title: "Immunizations-Yellow Fever",
    id: "Immunizations-Yellow Fever",
  },
  {
    title: "Immunizations-Cervical Cancer",
    id: "Immunizations-Cervical Cancer",
  },
  {
    title: "Immunizations-TD",
    id: "Immunizations-TD",
  },
  {
    title: "Immunizations-Covid-19",
    id: "Immunizations-Covid-19",
  },
];
