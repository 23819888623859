import React from "react";
import styled from "styled-components";

export const AdblockApproved = () => {
  return (
    <Container className="text-center">
      <h3 className="mt-4">Adblock Approved</h3>
      <div className="mt-3">
        To update adblock please use the form on the right.
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 120px;
  color: #aaa;
  font-size: 14px;
  h3 {
    font-size: 24px;
  }
`;
