import React, { useState } from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

export const Card = ({
  title,
  children,
  className,
  isScrollable = false,
  isCollapsable,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Container className={className}>
      {title && (
        <Header
          onClick={() => isCollapsable && setIsCollapsed((state) => !state)}
        >
          <div>{title}</div>
          {isCollapsable && (
            <div className="ml-auto">
              {isCollapsed ? (
                <FaCaretUp color="white" />
              ) : (
                <FaCaretDown color="white" />
              )}
            </div>
          )}
        </Header>
      )}
      {isCollapsed ? null : isScrollable ? (
        <ScrollContent>{children}</ScrollContent>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 3px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: 100%;
`;

const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  margin: 8px 0;
  flex: 1;
  max-height: 100%;
  margin-right: 4px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
