/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, ToggleButton } from "components";
import { useInput } from "hooks";

export const OfferXForTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const amount = useInput(2);
  const val = useInput(2);
  const isPurchase = useInput(false);
  const purchaseAmount = useInput("");
  const product = useInput("");

  const handleUpdateValue = (amount, value, isP, purchase, product) => {
    if (amount && value) {
      if (isP) {
        onChange(
          `${amount}/$${value} w/purchase/${purchase}${
            product ? ` ${product}` : ""
          }`
        );
      } else {
        onChange(`${amount}/$${value}${product ? ` ${product}` : ""}`);
      }
    }
  };

  useEffect(() => {
    if (value) {
      const pIdx = value.indexOf("w/purchase/");
      if (pIdx > 0) {
        isPurchase.setValue(true);
        let v = value.slice(0, pIdx).trim();
        const splits = v.split("/$");
        if (splits.length === 2) {
          amount.setValue(splits[0].trim());
          val.setValue(splits[1].trim());
        }
        const p = value.slice(pIdx + 11).trim();

        const sufIdx = p.indexOf(" ");
        if (sufIdx > 0) {
          purchaseAmount.setValue(p.slice(0, sufIdx));
          product.setValue(p.slice(sufIdx + 1).trim());
        } else {
          product.setValue("");
          purchaseAmount.setValue(p);
        }
      } else {
        isPurchase.setValue(false);
        const sufIdx = value.indexOf(" ");
        let v = "";
        if (sufIdx > 0) {
          v = value.slice(0, sufIdx);
          product.setValue(value.slice(sufIdx + 1).trim());
        } else {
          v = value;
          product.setValue("");
        }
        const splits = v.split("/$");
        if (splits.length === 2) {
          amount.setValue(splits[0].trim());
          val.setValue(splits[1].trim());
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 100, marginRight: 10 }}>
          <Input2
            label="Amount"
            value={amount.value}
            onChange={(e) => {
              amount.setValue(e);
              handleUpdateValue(
                e,
                val.value,
                isPurchase.value,
                purchaseAmount.value,
                product.value
              );
            }}
          />
        </div>
        <div className="mt-auto mb-1">for</div>
        <div style={{ width: 100, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                amount.value,
                e,
                isPurchase.value,
                purchaseAmount.value,
                product.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-2">
        <ToggleButton
          label="w/purchase"
          className="mt-auto mb-2"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              amount.value,
              val.value,
              e,
              purchaseAmount.value,
              product.value
            );
          }}
        />

        <div style={{ width: 110, marginLeft: 10, marginRight: 10 }}>
          <Input2
            label="Purchase Amount"
            disabled={!isPurchase.value}
            value={purchaseAmount.value}
            onChange={(e) => {
              purchaseAmount.setValue(e);
              handleUpdateValue(
                amount.value,
                val.value,
                isPurchase.value,
                e,
                product.value
              );
            }}
          />
        </div>
        <div className="mt-auto mb-2">for</div>
        <div style={{ width: 90, marginLeft: 10 }}>
          <Input2
            label="Product"
            value={product.value}
            onChange={(e) => {
              product.setValue(e);
              handleUpdateValue(
                amount.value,
                val.value,
                isPurchase.value,
                purchaseAmount.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
