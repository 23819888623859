/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveAirMilesTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const airMiles = useInput("2000");
  const isPurchase = useInput(false);
  const val = useInput("");
  const type = useInput("");
  const suffix = useInput("");

  const typeData = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];

  const formatPurchase = (isP, v, t) => {
    if (isP) {
      if (t === "$") {
        return ` w/purchase/$${v}`;
      } else {
        return ` w/purchase/${v}`;
      }
    } else {
      return "";
    }
  };

  const handleUpdateValue = (
    isUpto,
    airMiles,
    isPurchase,
    val,
    type,
    suffix
  ) => {
    onChange(
      `Earn${isUpto ? " Up To" : ""} ${
        airMiles ? `${airMiles} air miles` : ""
      }${formatPurchase(isPurchase, val, type)}${suffix ? ` ${suffix}` : ""}`
    );
  };

  useEffect(() => {
    if (value.startsWith("Earn")) {
      let main = value.slice(4);
      if (main.startsWith("Up To")) {
        isUpTo.setValue(true);
        main = main.slice(5);
      } else {
        isUpTo.setValue(false);
      }
      if (main.length > 0) {
        const airmilesIndex = main.indexOf("air miles");
        if (airmilesIndex > 0) {
          airMiles.setValue(main.slice(0, airmilesIndex).trim());
          main = main.slice(airmilesIndex + 9);
        } else {
          airMiles.setValue("");
        }
        if (main.trim().startsWith("w/purchase/")) {
          main = main.trimStart();
          isPurchase.setValue(true);
          main = main.slice(11);
          const suffixIndex = main.indexOf(" ");
          if (suffixIndex >= 0) {
            suffix.setValue(main.slice(suffixIndex + 1));
            let purchaseVal = main.slice(0, suffixIndex).trim();
            if (purchaseVal.startsWith("$")) {
              type.setValue("$");
              purchaseVal = purchaseVal.slice(1).trim();
            } else {
              type.setValue("Unit");
            }
            val.setValue(purchaseVal.trim());
          } else {
            if (main.startsWith("$")) {
              type.setValue("$");
              main = main.slice(1).trim();
            } else {
              type.setValue("Unit");
            }
            val.setValue(main.trim());
            suffix.setValue("");
          }
        } else {
          isPurchase.setValue(false);
          val.setValue("");
          type.setValue("");
          suffix.setValue(main.slice(1));
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-2">Earn</div>
        <ToggleButton
          className="mt-auto mb-2 ml-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              airMiles.value,
              isPurchase.value,
              val.value,
              type.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 120, marginLeft: 20 }}>
          <Input2
            label="Air Miles"
            value={airMiles.value}
            onChange={(e) => {
              airMiles.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                isPurchase.value,
                val.value,
                type.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          className="mt-auto mb-2"
          label="w/purchase"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            if (!e) {
              val.setValue("");
              type.setValue("");
            }
            handleUpdateValue(
              isUpTo.value,
              airMiles.value,
              e,
              e ? val.value : "",
              e ? type.value : "Unit",
              suffix.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            disabled={!isPurchase.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                airMiles.value,
                isPurchase.value,
                e,
                type.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            value={type.value}
            disabled={!isPurchase.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                airMiles.value,
                isPurchase.value,
                val.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 80, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                airMiles.value,
                isPurchase.value,
                val.value,
                type.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
