import React from "react";
import styled from "styled-components";

export const ToggleButton = ({
  className,
  value = false,
  onChange = () => {},
  label,
  ...props
}) => {
  return (
    <Container
      className={className}
      active={value}
      onClick={() => onChange(!value)}
      {...props}
    >
      {label}
    </Container>
  );
};

const Container = styled.button`
  width: fit-content;
  height: 24px;
  font-size: 12px;
  text-align: center;
  padding: 0 4px;
  color: ${(props) =>
    props.active ? props.theme.palette.white : props.theme.palette.secondary};
  background: ${(props) =>
    props.active ? props.theme.palette.primary : "transparent"};
  border: 1px solid ${(props) => props.theme.palette.itemsHover};
  transition: all 0.3s ease;
`;
