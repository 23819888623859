/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";

export const RejectWarningModal = ({
  open,
  onClose,
  onReject = () => { }
}) => {

  const handleCancel = () => {
    onClose()
  }

  const handleReject = () => {
    onReject();
    onClose()
  }



  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Rejecting Ad Block"}>
      <div>
        <div>Are you sure you want to reject this ad block? This will delete the ad block from the system.</div>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleReject()}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};
