/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  getCircularById,
  getImage,
  approveCircular,
  getPageAdblocks,
} from "api";
import { Button, Alert, DropDown2 } from "components";
import { Progress, Card } from "./components";
import { useParams, navigate } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { isJSON } from "utils";
import { useInput, useModal } from "hooks";
import { format } from "date-fns";
import { AdblockInfo } from "./components/AdblockInfo";
import { Adblock } from "./components/Adblock";
import { PageView } from "./components/PageView";
import { fabric } from "fabric";
import { useAuthContext } from "contexts";

const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const CircularDetailPage = () => {
  const params = useParams();
  const template = useInput("");

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [isApproving, setIsApproving] = useState(false);

  //Circular data
  const [pages, setPages] = useState([]);
  const [circularName, setcircularName] = useState("");
  const [circularStats, setCircularStats] = useState({
    num_adblocks: 0,
    num_adblocks_completed: 0,
    num_adblocks_do_not_enter: 0,
    num_adblocks_skipped: 0,
    num_pages: 0,
    num_pages_completed: 0,
    num_offers_approved: 0,
    num_offers_rejected: 0,
    num_products_approved: 0,
    num_products_rejected: 0,
  });
  const [circularId, setCircularId] = useState("");
  const [circularApproved, setCircularApproved] = useState(false);
  const { userInfo } = useAuthContext();

  // Page Data
  const [pageData, setPageData] = useState([]);
  const [pageStats, setPageStats] = useState({});
  const [pageId, setPageId] = useState("");
  const blockRef = useRef();

  // Adblocks Data
  const [adblocksData, setAdblocksData] = useState([]);
  const [currentAdblockId, setCurrentAdblockId] = useState("");
  const [currentOutlineColor, setCurrentOutlineColor] = useState(
    OUTLINE_COLORS[0]
  );

  // Canvas Data
  const [canvas, setCanvas] = useState("");

  //Modals
  const validationModal = useModal();
  var started = false;
  var x = 0;
  var y = 0;

  if (!userInfo.user.is_superuser) {
    navigate("/home");
  }

  const init = () => {
    const canvi = new fabric.Canvas("canvas", {
      height: blockRef.current.offsetHeight,
      width: blockRef.current.offsetWidth,
      fireRightClick: true, // <-- enable firing of right click events
      stopContextMenu: true, // <--  prevent context menu from showing
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "white";
    fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 8;
    // zoom when mouse wheel
    canvi.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvi.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvi.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvi.getHeight() > blockRef.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });
    // pan when alt key & mouse down
    canvi.on("mouse:down", function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    });

    canvi.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      } else if (this.isCreating) {
        // Resize the newly created crop while mouse draging
        if (!started) {
          return false;
        }

        let mouse = canvi.getPointer(opt.e);

        let w = mouse.x - x,
          h = mouse.y - y;

        if (!w || !h) {
          return false;
        }

        let rect = canvi.getActiveObject();
        if (rect) {
          if (opt.e.shiftKey) {
            rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
          } else {
            rect.set("width", w).set("height", h);
          }
          rect.setControlsVisibility({ mtr: false });
        }
        canvi.renderAll();
      }
    });
    canvi.on("mouse:up", function (opt) {
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      this.isCreating = false;
    });
    canvi.on("selection:created", function (opt) {
      setCurrentAdblockId(opt.target.id);
    });
    canvi.on("selection:updated", function (opt) {
      setCurrentAdblockId(opt.target.id);
    });
    canvi.on("selection:cleared", function (opt) {
      setCurrentAdblockId("");
    });
    setCanvas(canvi);
  };

  useEffect(() => {
    init();

    return () => {};
  }, []);

  const initCirculars = async () => {
    setIsLoading(true);

    const res = await getCircularById(circularId);
    if (res.success) {
      setCircularApproved(res.payload.approved);

      setPageData(res.payload.pages);

      var circularStats = {
        num_adblocks: res.payload.num_adblocks ? res.payload.num_adblocks : 0,
        num_adblocks_completed: res.payload.num_adblocks_completed
          ? res.payload.num_adblocks_completed
          : 0,
        num_adblocks_do_not_enter: res.payload.num_adblocks_do_not_enter
          ? res.payload.num_adblocks_do_not_enter
          : 0,
        num_adblocks_skipped: res.payload.num_adblocks_skipped
          ? res.payload.num_adblocks_skipped
          : 0,

        num_pages: res.payload.num_pages ? res.payload.num_pages : 0,
        num_pages_completed: res.payload.num_pages_completed
          ? res.payload.num_pages_completed
          : 0,

        num_offers_approved: res.payload.num_offers_approved
          ? res.payload.num_offers_approved
          : 0,
        num_offers_rejected: res.payload.num_offers_rejected
          ? res.payload.num_offers_rejected
          : 0,

        num_products_approved: res.payload.num_products_approved
          ? res.payload.num_products_approved
          : 0,
        num_products_rejected: res.payload.num_products_rejected
          ? res.payload.num_products_rejected
          : 0,
      };

      setCircularStats(circularStats);

      var name =
        res.payload.retailer.name +
        "_" +
        format(
          new Date(
            res.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
          ),
          "MM/dd/yyyy"
        );
      setcircularName(name);

      setPages(
        res.payload.pages.map((page) => ({
          id: page.uuid,
          title: page.uuid,
        }))
      );

      if (params.page === "0") {
        setPageId(res.payload.pages[0].uuid);
      } else {
        setPageId(params.page);
      }
    } else {
      //System error message
    }
  };

  useEffect(() => {
    if (params?.id !== "0") {
      setCircularId(params?.id);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (circularId) {
      initCirculars();
    }
    return () => {};
  }, [circularId]);

  const loadPage = async () => {
    if (pageId !== "0" && pageId) {
      const page = pageData.find((item) => item.uuid == pageId);
      const tempPageStats = {
        num_adblocks: page?.num_adblocks ? page?.num_adblocks : 0,
        num_adblocks_completed: page?.num_adblocks_completed
          ? page?.num_adblocks_completed
          : 0,
        num_adblocks_do_not_enter: page?.num_adblocks_do_not_enter
          ? page?.num_adblocks_do_not_enter
          : 0,
        num_adblocks_skipped: page?.num_adblocks_skipped
          ? page?.num_adblocks_skipped
          : 0,

        num_pages: page?.num_pages ? page?.num_pages : 0,
        num_pages_completed: page?.num_pages_completed
          ? page?.num_pages_completed
          : 0,

        num_offers_approved: page?.num_offers_approved
          ? page?.num_offers_approved
          : 0,
        num_offers_rejected: page?.num_offers_rejected
          ? page?.num_offers_rejected
          : 0,

        num_products_approved: page?.num_products_approved
          ? page?.num_products_approved
          : 0,
        num_products_rejected: page?.num_products_rejected
          ? page?.num_products_rejected
          : 0,
      };

      setPageStats(tempPageStats);

      const res = await getPageAdblocks(pageId);
      if (res.success) {
        setAdblocksData(res.payload.adblocks);
        if (res.payload.adblocks.length > 0) {
          setCurrentAdblockId(res.payload.adblocks[0].uuid);
        }
      } else {
        setAdblocksData([]);
      }
      if (page && page?.filename) {
        const img = await getImage(page?.filename);
        if (img.success) {
          canvas.clear();
          canvas.zoomToPoint({ x: 0, y: 0 }, 1);
          let vpt = canvas.viewportTransform;
          vpt[4] = 0;
          vpt[5] = 0;

          var background = new Image();
          background.onload = async function () {
            var f_img = new fabric.Image(background);
            canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
              scaleX: canvas.height / background.height,
              scaleY: canvas.height / background.height,
            });
            console.log("adblock data", res.payload.adblocks);
            drawAdblocks(
              canvas.height / background.height,
              res.payload.adblocks
            );
          };

          background.src = "data:image/png;base64," + img.payload;
        }
        setIsLoading(false);
        navigate(`/circular/${params.id}/${pageId}`);
      }
    }
  };
  useEffect(() => {
    loadPage();

    return () => {};
  }, [pageId]);

  const drawAdblocks = (canvasRatio, adBlockData) => {
    // Draw block rect data if available
    if (adBlockData.length > 0) {
      adBlockData.forEach((adblock) => {
        const rect = new fabric.Rect({
          left: adblock.coords_x1 * canvasRatio,
          top: adblock.coords_y1 * canvasRatio,
          height: (adblock.coords_y2 - adblock.coords_y1) * canvasRatio,
          width: (adblock.coords_x2 - adblock.coords_x1) * canvasRatio,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: "transparent",
          cornerColor: "white",
          cornerStrokeColor: currentOutlineColor,
          id: adblock.uuid,
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
      });
      canvas.renderAll();
      setCanvas(canvas);
    }
  };

  const handleApprove = async () => {
    setIsApproving(true);
    await approveCircular(params.id);
    setIsApproving(false);
  };

  const onPageSelect = (params) => {
    setPageId(params.id);
  };

  const handleBackNavigation = () => {
    navigate("/circular");
  };

  const handlePickColor = async (color) => {
    setCurrentOutlineColor(color);
    canvas.getObjects().map(function (o) {
      return o.set({
        stroke: color,
        cornerStrokeColor: color,
      });
    });
    canvas.requestRenderAll();
  };

  const handleAdblockSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
    }
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex" style={{ width: 800 }}>
                <Circulars className="ml-1" onClick={handleBackNavigation}>
                  Circulars
                </Circulars>

                <CircularName className="ml-2 my-auto">
                  {circularName}
                </CircularName>

                <DropDown2
                  className="ml-3 dropdownWidth"
                  data={pages}
                  value={pageId}
                  onSelectValue={onPageSelect}
                />
              </div>
              <Button
                width="140px"
                buttonTheme="dark"
                size="small"
                className="ml-auto my-auto"
                onClick={() => navigate(`/adblock/${circularId}/${pageId}`)}
              >
                AD CROPPING
              </Button>
              <Button
                width="120px"
                buttonTheme="dark"
                size="small"
                className="ml-2 my-auto"
                onClick={() =>
                  navigate(
                    `/product/${circularId}/${pageId}/${currentAdblockId}`
                  )
                }
              >
                PRODUCTS
              </Button>
              <Button
                width="180px"
                buttonTheme="dark"
                size="small"
                className="ml-2 my-auto"
                onClick={() =>
                  navigate(`/offer/${circularId}/${pageId}/${currentAdblockId}`)
                }
              >
                OFFERS/INCENTIVES
              </Button>
              <Button
                buttonTheme={`${circularApproved ? "green" : "dark"}`}
                width="100px"
                size="small"
                className="ml-4 my-auto"
                onClick={handleApprove}
                isLoading={isApproving}
              >
                {circularApproved ? "APPROVED" : "APPROVE"}
              </Button>
            </div>
          )}
        </Header>

        <MainContent>
          <PageView
            title="PAGE VIEW"
            className="flex-1"
            activeColor={currentOutlineColor}
            onSelectColor={handlePickColor}
          >
            <CanvasContainer className="w-100" ref={blockRef}>
              {isLoading && (
                <Skeleton height={canvas.height} width={canvas.width} />
              )}
              <Canvas id="canvas" />
            </CanvasContainer>
          </PageView>
          <PageAdblocks title="PAGE ADBLOCKs" className="flex-1" isScrollable>
            {isLoading ? (
              <div>
                <Skeleton count={8} height={20} />
              </div>
            ) : (
              adblocksData.map((item, index) => (
                <Adblock
                  key={`${index}key`}
                  data={item}
                  onClick={() => handleAdblockSelect(item.uuid, index)}
                  isActive={item.uuid === currentAdblockId}
                />
              ))
            )}
          </PageAdblocks>
          <div className="d-flex flex-column flex-1">
            <CircularInformation title="CIRCULAR INFORMATION" isCollapsable>
              {isLoading ? (
                <div>
                  <Skeleton count={8} height={20} />
                </div>
              ) : (
                <>
                  <Progress
                    title="Pages Progress"
                    total={circularStats.num_pages}
                    value={circularStats.num_pages_completed}
                    className="mt-2"
                  />
                  <Progress
                    title="Adblocks Progress"
                    total={circularStats.num_adblocks}
                    value={circularStats.num_adblocks_completed}
                    className="mt-2"
                  />
                  <div className="d-flex">
                    <OtherStats>
                      No Enter: {circularStats.num_adblocks_do_not_enter}
                    </OtherStats>
                    <OtherStats>
                      Skipped: {circularStats.num_adblocks_skipped}
                    </OtherStats>
                  </div>
                  <Progress
                    title="Products Progress"
                    total={circularStats.num_adblocks}
                    value={
                      circularStats.num_products_approved +
                      circularStats.num_products_rejected +
                      circularStats.num_adblocks_do_not_enter +
                      circularStats.num_adblocks_skipped
                    }
                    className="mt-2"
                  />
                  <Progress
                    title="Offers Progress"
                    total={circularStats.num_adblocks}
                    value={
                      circularStats.num_offers_approved +
                      circularStats.num_offers_rejected +
                      circularStats.num_adblocks_do_not_enter +
                      circularStats.num_adblocks_skipped
                    }
                    className="mt-2"
                  />
                </>
              )}
            </CircularInformation>
            <PageInformation title="PAGE INFORMATION" isCollapsable>
              {isLoading ? (
                <div>
                  <Skeleton count={8} height={20} />
                </div>
              ) : (
                <>
                  <Progress
                    title="Adblocks Progress"
                    total={pageStats.num_adblocks}
                    value={pageStats.num_adblocks_completed}
                    className="mt-2"
                  />
                  <div className="d-flex">
                    <OtherStats>
                      No Enter: {pageStats.num_adblocks_do_not_enter}
                    </OtherStats>
                    <OtherStats>
                      Skipped: {pageStats.num_adblocks_skipped}
                    </OtherStats>
                  </div>
                  <Progress
                    title="Products Progress"
                    total={pageStats.num_adblocks}
                    value={
                      pageStats.num_products_approved +
                      pageStats.num_products_rejected +
                      pageStats.num_adblocks_skipped +
                      pageStats.num_adblocks_do_not_enter
                    }
                    className="mt-2"
                  />
                  <Progress
                    title="Offers Progress"
                    total={pageStats.num_adblocks}
                    value={
                      pageStats.num_offers_approved +
                      pageStats.num_offers_rejected +
                      pageStats.num_adblocks_skipped +
                      pageStats.num_adblocks_do_not_enter
                    }
                    className="mt-2"
                  />
                </>
              )}
            </PageInformation>
            <AdblockInfo
              data={adblocksData.find((item) => item.uuid === currentAdblockId)}
              isLoading={isLoading}
            />
          </div>
        </MainContent>
        <Alert size="small" title="Validation" {...validationModal}>
          <div>{isJSON(template.value) ? "Success" : "Error"}</div>
          <div className="d-flex">
            <Button
              size="small"
              className="mt-2 ml-auto"
              width="120px"
              buttonTheme="dark"
              onClick={() => validationModal.onClose()}
            >
              OK
            </Button>
          </div>
        </Alert>
      </MainLayout>
    </SkeletonTheme>
  );
};
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 16px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;

  .dropdownWidth {
    width: 340px;
  }
`;

const PageAdblocks = styled(Card)`
  margin: 0 2px;
  max-width: 320px;
`;

const CircularInformation = styled(Card)`
  margin: 0 2px;
`;

const PageInformation = styled(Card)`
  margin: 2px 2px 0 2px;
`;

const Circulars = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CircularName = styled.div`
  padding: 5px;
`;

const OtherStats = styled.div`
  color: white;
  font-size: 14px;
  padding: 4px;
`;

const CanvasContainer = styled.div`
  height: 100% !important;
  background-color: ${(props) => props.theme.palette.items};
`;
const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;
