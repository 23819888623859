/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import {
  Card,
  OfferSelector,
  AdblockLocation,
  AdblockImage,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  OfferTypeSearch,
  QuickSelectionChoice,
  OfferItem,
  OfferTextBuilder,
  NoSuggestions,
  IncentiveItem,
  QuickSelection,
  MetaData,
  FlagButtonGroup,
  EmptyOfferSelector,
  IncentiveTextBuilder,
  HistoryDropdown,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { IncentiveInfo, OfferInfo, EmptyInfo } from "fragments";
import { format } from "date-fns";
import {
  getNextOffer,
  getImage,
  approveOffer,
  getOfferTypesApi,
  updateAdblockSuggestions,
  skipTopDownAdblock,
  doNotEnterTopDownAdblock,
  getCircularIncentives,
  getPageAdblocks,
  rejectAdblock,
} from "api";
import { useMainContext, useAdblockHistoryContext } from "contexts";
import { useInput } from "hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { omit } from "lodash";
import { useLocation } from "@reach/router";
import useKeypress from "react-use-keypress";
import {
  OFFER_SUGGESTION_TITLE,
  INCENTIVE_SUGGESTION_TITLE,
  OFFER_INIT_SUGGESTIONS,
  OFFER_INIT_INFO,
  INCENTIVE_INIT_SUGGESTION,
  INCENTIVE_INIT_INFO,
  INCENTIVE_INFO_FIELDS,
  OFFER_INFO_FIELDS,
  INIT_OFFER_METADATA,
  EXTRA_INCENTIVE_SUGGESTIONS,
} from "config";
import { validateOffer, validateIncentive } from "utils";

export const OffersPage = () => {
  //const params = useParams();
  const blockRef = useRef();
  const location = useLocation();

  const {
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    activeField,
    setActiveField,
    setFieldNames,
    moveNextField,
    updateActiveInfo,
    currentInfo,
    setCurrentInfo,
    setSessionId,
    activeFieldValue,
    updateInfo,
    setMetaData,
    setMetaDataFromPayload,
    metaData,
    isManualEntry,
    setIsManualEntry,
    movePreviousField,
    getMetaData,
    updateInfos,
  } = useMainContext();
  const { initHistory, pushHistory } = useAdblockHistoryContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [copiedText, setCopiedText] = useState(false);

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  //Base List Data
  const [adblockData, setAdblockData] = useState([]);
  const [offerTypeData, setOfferTypeData] = useState([]);

  //Currently Viewed Information
  const [circularName, setCircularName] = useState("");
  const [queueCount, setQueueCount] = useState("");
  const [messageID, setMessageID] = useState(false);
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");

  //Offer list from adblock
  const [offerData, setOfferData] = useState([]);
  const currentOfferIndex = useInput(0);
  const [allOffersValid, setAllOffersValid] = useState(false);
  const [offerSuggestionData, setOfferSuggestionData] = useState(
    OFFER_INIT_SUGGESTIONS
  );

  //Adblock Flags
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const [isDoNotEnter, setIsDoNotEnter] = useState(false);

  //Incentives list from adblock
  let [incentiveData, setIncentiveData] = useState([]);
  const currentIncentiveIndex = useInput(0);
  const [incentiveSuggestionData, setIncentiveSuggestionData] = useState(
    INCENTIVE_INIT_SUGGESTION
  );

  const [currentItemType, setCurrentItemType] = useState("offer");
  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);

  //Adblock data
  const events = useInput([]);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });

  //Form Flow
  const [activeOfferInfo, setActiveOfferInfo] = useState("");
  const [activeOfferInfoText, setActiveOfferInfoText] = useState("");
  //const [inputFocused, //setInputFocused] = useState(false);

  //Suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  const [isEndQueue, setIsEndQueue] = useState(false);
  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  const init = async () => {
    setCurrentPage("TD - Offers");
    setPreviousTime(new Date());
    setIsLoading(true);

    setImageHeight(blockRef.current.offsetHeight);
    setImageWidth(blockRef.current.offsetWidth);
    setMetaData(INIT_OFFER_METADATA);

    const data = await getOfferTypesApi();
    setOfferTypeData(
      data.payload.map((item) => ({
        ...item,
        id: item.name,
        title: item.name,
      }))
    );
    setOfferSuggestionData({
      ...offerSuggestionData,
      offer_type: data.payload
        .sort((a, b) => a.ext_id - b.ext_id)
        .slice(0, 9)
        .map((item) => {
          return {
            title: item.name,
            value: item.name,
          };
        }),
    });
    fetchAdBlock();
    setIsLoading(false);
  };

  const initInfo = () => {
    setActiveField("");
    setMetaData(INIT_OFFER_METADATA);
  };

  /*
  ///////////////////////////////////////
  ////// Watchers /////////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    init();
    initHistory("offer");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isManualEntry &&
      activeOfferInfo !== "" &&
      activeOfferInfo.indexOf("_f") === -1 &&
      activeOfferInfo !== "offer_type"
    ) {
      //Setting the timeout prevents the keypress from populating an input
      setTimeout(() => {
        document.getElementById(activeOfferInfo).focus();
      });
    }
    return () => {};
  }, [isManualEntry]);

  useEffect(() => {
    setAllOffersValid(
      offerData.every(function (e) {
        return e.isValid;
      }) &&
        incentiveData.every(function (e) {
          return e.isValid;
        })
    );
    return () => {};
  }, [offerData, incentiveData]);

  useEffect(() => {
    if (activeField && currentItemType) {
      setCurrentSuggestions(
        currentItemType === "offer"
          ? offerSuggestionData[activeField]
          : incentiveSuggestionData[activeField]
      );
      if (
        currentInfo["offer_type"] === "w/purchase case" &&
        activeField === "offer_text"
      ) {
        setCurrentSuggestions([
          {
            value: "w/purchase whole case",
            title: "w/purchase whole case",
          },
          {
            value: "w/purchase half case",
            title: "w/purchase half case",
          },
        ]);
      } else if (
        currentInfo["offer_type"] === "w/purchase product" &&
        activeField === "offer_text"
      ) {
        let products = [];
        if (adblockData?.products && adblockData?.products.length > 0) {
          products = adblockData?.products.map((p) => ({
            title: p.brand_nm,
            value: p.brand_nm,
          }));
        } else if (
          adblockData?.suggestions?.products &&
          adblockData?.suggestions?.products.length > 0
        ) {
          products = adblockData?.suggestions?.products.map((p) => ({
            title: p.brand_nm,
            value: p.brand_nm,
          }));
        }
        setCurrentSuggestions(products);
      } else if (
        [
          "Clearance",
          "Extra Coupons",
          "Free Shipping",
          "Pharmacy Services",
          "Rollback",
          "Saturdate",
          "Sweepstakes",
          "Earn Free Item",
          "w/agreement",
        ].includes(currentInfo["incentive_type"]) &&
        activeField === "incentive_text"
      ) {
        setCurrentSuggestions(
          EXTRA_INCENTIVE_SUGGESTIONS[currentInfo["incentive_type"]]
        );
      }
      setActiveOfferInfoText(
        currentItemType === "offer"
          ? OFFER_SUGGESTION_TITLE[activeField]
          : INCENTIVE_SUGGESTION_TITLE[activeField]
      );
    }

    return () => {};
  }, [activeField]);

  useEffect(() => {
    if (Object.keys(currentInfo).length > 0) {
      if (currentItemType === "offer") {
        let temp = [...offerData];
        temp[currentOfferIndex.value] = {
          ...currentInfo,
          isValid: validateOffer(currentInfo),
        };
        setOfferData(temp);
      } else if (currentItemType === "incentive") {
        let temp = [...incentiveData];
        setIncentiveData(
          temp.map((item) => {
            if (item.index === currentInfo.index)
              return {
                ...currentInfo,
                isValid: validateIncentive(currentInfo),
              };
            return { ...item };
          })
        );
      }
    }
    return () => {};
  }, [currentInfo]);
  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchAdBlock = async () => {
    setIsFetching(true);
    setIsManualEntry(false);

    setIsApproved(false);
    setIsRejected(false);
    setIsDoNotEnter(false);
    setIsSkipped(false);

    setOfferData([]);
    setIncentiveData([]);
    initInfo();
    const result = await getNextOffer(currentAdBlockId);

    if (result.success) {
      //When payload comes back as 204 it will be a blank string
      if (result.payload !== "") {
        setIsEndQueue(false);
        setAdblockData(result.payload.adblock);
        setCurrentAdBlockId(result.payload.adblock.uuid);
        setCurrentPageId(result.payload.page.uuid);
        setCurrentCircularId(result.payload.circular.uuid);
        console.log("push", {
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        pushHistory({
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        setCircularName(
          result.payload.circular.retailer.name +
            "_" +
            format(new Date(result.payload.circular.circ_week_dt), "MM/dd/yyyy")
        ); //setPageData(result.payload.page);
        setQueueCount(result.payload.queue_count);
        setMessageID(result.payload.message_id);
        setSessionId(result.payload.message_id);
        setIsDoNotEnter(result.payload.adblock?.do_not_enter_f);
        setIsSkipped(result.payload.adblock?.skipped);
        setIsRejected(result.payload.adblock?.offers_rejected);
        setIsApproved(result.payload.adblock?.approved);

        setAdblockCoords({
          coords_x1: result.payload.adblock.coords_x1,
          coords_x2: result.payload.adblock.coords_x2,
          coords_y1: result.payload.adblock.coords_y1,
          coords_y2: result.payload.adblock.coords_y2,
        });
        let tempIncentives = [];
        if (result.payload.adblock?.incentive_text) {
          tempIncentives = [
            ...result.payload.adblock?.incentive_text
              .split(",")
              .map((item) => ({
                incentive_text: item,
                incentive_attach_to: "Adblock",
              })),
          ];
        }
        if (result.payload.page?.incentive_nm) {
          tempIncentives = [
            ...tempIncentives,
            ...result.payload.page?.incentive_nm.split(",").map((item) => ({
              incentive_text: item,
              incentive_attach_to: "Page",
            })),
          ];
        }
        if (result.payload.circular?.incentive_nm) {
          tempIncentives = [
            ...tempIncentives,
            ...result.payload.circular?.incentive_nm.split(",").map((item) => ({
              incentive_text: item,
              incentive_attach_to: "Circular",
            })),
          ];
        }

        tempIncentives = tempIncentives.map((item, index) => ({
          ...item,
          index,
        }));
        setIncentiveData(tempIncentives);
        const cirIncentiveData = await getCircularIncentives(
          result.payload.circular.uuid
        );
        setIncentiveSuggestionData({
          ...incentiveSuggestionData,
          incentive_text: cirIncentiveData.map((item) => ({
            title: item,
            value: item,
          })),
        });
        setPageWidth(result.payload.page.img_width);
        setPageHeight(result.payload.page.img_height);
        events.setValue(
          result.payload.adblock?.event_nm
            ? result.payload.adblock?.event_nm.split(",")
            : []
        );

        const backImg = await getImage(result.payload.page.filename);
        setPageImg(backImg.payload);

        if (
          result.payload.adblock?.suggestions &&
          result.payload.adblock?.suggestions?.offers.length > 0
        ) {
          const offers = result.payload.adblock.suggestions.offers.map(
            (item, index) => ({
              ...item,
              isValid: validateOffer(item),
              index,
            })
          );
          setOfferData(offers);

          if (offers.length > 0) {
            setCurrentInfo(offers[0]);
            currentOfferIndex.setValue(0);
            setCurrentItemType("offer");
            setFieldNames(OFFER_INFO_FIELDS);
            setOfferSuggestionData({
              ...offerSuggestionData,
              offer_text: offers.slice(0, 9).map((item, index) => {
                return {
                  title: item.offer_text,
                  value: item.offer_text,
                };
              }),
            });
          }
        } else {
          setOfferData([]);
          initInfo();
          setCurrentItemType("");
        }
        setMetaDataFromPayload(result.payload.adblock);

        setAdblockFilename(result.payload.adblock.filename);
      } else {
        setOfferData([]);
        setQueueCount(0);
        setIsEndQueue(true);
      }
    }
    postPageEvents("ready", "");
    setIsFetching(false);
  };

  const navigateNext = () => {
    fetchAdBlock();
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async () => {
    setIsSubmitting(true);
    const result = await rejectAdblock(currentAdBlockId, "offers");

    if (result.success) {
      navigateNext();
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);

    const int_keys = [
      //"unit_price",
      "reg_price",
      "limit",
      "coupon_amount",
      "rebate_amount",
      "min_qty",
    ];

    let offers = offerData.map((offer) => omit(offer, ["index"]));
    offers.forEach((offer) => {
      Object.entries(offer).forEach(([key, value]) => {
        if (value === "" && int_keys.indexOf(key) !== -1) {
          offer[key] = null;
        }
      });
    });

    let data = {
      adblock: {
        ...getMetaData(),
        event_nm: events.value.join(),
        incentive_text: incentiveData
          .filter((a) => validateIncentive(a))
          .map((a) =>
            a?.incentive_limit
              ? `${a.incentive_text} (${a.incentive_limit})`
              : a.incentive_text
          )
          .join(","),
      },
      offers: offers,
      message_ID: messageID,
    };

    const result = await approveOffer(currentAdBlockId, data);
    if (result.success) {
      initInfo();
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    const result = await skipTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "offers"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    const result = await doNotEnterTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "offers"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const refetchAdblocks = async () => {
    const result = await getPageAdblocks(currentPageId);
    const newData = result?.payload?.adblocks;
    setAdblockData(newData);
  };
  const handleAddItem = (type) => {
    switch (type) {
      case "offer":
        handleAddOffer();
        break;
      case "incentive":
        handleAddIncentive();
        break;

      default:
        break;
    }
    setCurrentItemType(type);
  };
  /*
  ///////////////////////////////////////
  ////// Offer Controls /////////////
  /////////////////////////////////////
  */

  const handleAddOffer = () => {
    const newIndex =
      offerData.length > 0 ? offerData[offerData.length - 1].index + 1 : 0;
    const newOffer = {
      ...OFFER_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setOfferData([...offerData, newOffer]);
    currentOfferIndex.setValue(newIndex);
    setCurrentInfo(newOffer);
    setFieldNames(OFFER_INFO_FIELDS);
    setCurrentItemType("offer");
  };

  const handleRemoveOffer = (event, index) => {
    event.stopPropagation();
    const idx = offerData.findIndex((offer) => offer.index === index);

    const newOffersData = offerData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));
    setOfferData(newOffersData);

    if (currentItemType === "offer") {
      if (newOffersData.length === 0) {
        if (incentiveData.legnth > 0) {
          setCurrentItemType("incentive");
          setCurrentInfo(incentiveData[0]);
          currentIncentiveIndex.setValue(incentiveData[0]);
        } else {
          setCurrentInfo({});
          currentOfferIndex.setValue(0);
          setCurrentItemType("");
        }
      } else {
        const newIdx = newOffersData.length > idx ? idx : idx - 1;
        currentOfferIndex.setValue(newOffersData[newIdx].index);
        setCurrentInfo(newOffersData[newIdx]);
      }
    }
  };

  const handleCloneOffer = (event, index) => {
    event.stopPropagation();
    if (offerData.length > 0) {
      const newIndex = offerData[offerData.length - 1].index + 1;
      setOfferData([
        ...offerData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentOfferIndex.setValue(newIndex);
    }
  };

  const handleOfferSelect = (index) => {
    currentOfferIndex.setValue(index);
    setCurrentItemType("offer");
    setFieldNames(OFFER_INFO_FIELDS);
    setActiveField(OFFER_INFO_FIELDS[0]);
    setCurrentInfo(offerData[index]);
  };

  ///////////////////////////////////////
  ////// Incentive Controls /////////////
  /////////////////////////////////////

  const handleAddIncentive = () => {
    const newIndex =
      incentiveData.length > 0
        ? incentiveData[incentiveData.length - 1].index + 1
        : 0;
    const newIncentive = {
      ...INCENTIVE_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setIncentiveData([...incentiveData, newIncentive]);
    currentIncentiveIndex.setValue(newIndex);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setCurrentInfo(newIncentive);
  };

  const handleRemoveIncentive = (index) => {
    // event.stopPropagation();
    const idx = incentiveData.findIndex(
      (incentive) => incentive.index === index
    );
    const newIncentivesData = incentiveData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));
    setIncentiveData(newIncentivesData);

    if (currentItemType === "incentive") {
      if (newIncentivesData.length === 0) {
        if (offerData.length > 0) {
          setCurrentItemType("offer");
          setCurrentInfo(offerData[0]);
          currentOfferIndex.setValue(offerData[0].index);
        } else {
          setCurrentInfo({});
          currentIncentiveIndex.setValue(0);
          setCurrentItemType("");
        }
      } else {
        const newIdx = newIncentivesData.length > idx ? idx : idx - 1;
        currentIncentiveIndex.setValue(newIncentivesData[newIdx].index);
        setCurrentInfo(newIncentivesData[newIdx]);
      }
    }
  };

  const handleCloneIncentive = (event, index) => {
    event.stopPropagation();
    if (incentiveData.length > 0) {
      const newIndex = incentiveData[incentiveData.length - 1].index + 1;
      setIncentiveData([
        ...incentiveData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentIncentiveIndex.setValue(newIndex);
      setCurrentItemType("incentive");
      setFieldNames(INCENTIVE_INFO_FIELDS);
    }
  };

  const handleIncentiveSelect = (index) => {
    currentIncentiveIndex.setValue(index);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setActiveField(INCENTIVE_INFO_FIELDS[0]);
    setCurrentInfo(incentiveData[index]);
  };

  useEffect(() => {
    if (offerData.length === 0 && incentiveData.length === 0) {
      setCurrentItemType("");
      setActiveField("");
    }
    return () => {};
  }, [offerData, incentiveData]);
  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    postPageEvents("click", "Refresh Quick Selection");
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      let offers = [];
      //This means the adblock is approved
      if (result.payload.offers.length > 0) {
        setIsApproved(true);
        offers = result.payload.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else if (result.payload.suggestions.offers.length > 0) {
        offers = result.payload.suggestions.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      }
      setOfferData(offers);
      if (offers.length > 0) {
        setCurrentInfo(offers[0]);
        currentOfferIndex.setValue(0);
        setCurrentItemType("offer");
        setFieldNames(OFFER_INFO_FIELDS);
      }
    }
    setSuggestionsLoading(false);
  };

  /*
  ///////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  /////////////////////////////////////
  */

  const handleSuggestionClick = (value, event) => {
    if (value === "Manual") {
      setIsManualEntry(true);
    } else {
      if (activeField === "offer_type") {
        updateInfos({
          offer_text: INIT_OFFER_TEXT[value.toLowerCase()],
          offer_type: value,
        });
      } else {
        updateActiveInfo(value);
      }
      moveNextField();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var value, title;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        value = "Manual";
      } else {
        value = currentSuggestions[parseInt(event.key) - 1].value;
        title = currentSuggestions[parseInt(event.key) - 1].title;
      }

      handleSuggestionClick(value, title);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      //setInputFocused(false);
      setIsManualEntry(false);
    } else {
      //setInputFocused(false);
    }

    if (document.activeElement) {
      document.activeElement.blur();
    }
  });

  //Remove Offers
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveOffer();
    // }
  });

  //Add Offers
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddOffer();
    }
  });

  //Clone Offers
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneOffer();
    // }
  });

  //Cycle through offers
  useKeypress(["["], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      //If we go backwards to the start, go back to top.
      if (currentOfferIndex.value === 0) {
        currentOfferIndex.setValue(offerData.length - 1);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value - 1);
      }
    } else {
      //If we go forward to the end, go back to start.
      if (currentOfferIndex.value === offerData.length - 1) {
        currentOfferIndex.setValue(0);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value + 1);
      }
    }
  });

  //Approve offers
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      handleApprove();
    }
  });

  //Approve offers
  useKeypress(["w", "W"], (event) => {
    if (event.altKey) {
      handleDoNotEnterAdblock();
    }
  });

  //Approve offers--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //Approve offers
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  //Move to next field item, only if not in manual entry
  useKeypress(["Tab"], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      movePreviousField();
    } else {
      moveNextField();
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {/* <BreadCrumb className="ml-1 my-auto">Circulars</BreadCrumb> */}
                <BreadCrumb className="ml-3 my-auto">{circularName}</BreadCrumb>
                <BreadCrumb className="ml-3 my-auto">
                  {currentPageId}
                </BreadCrumb>
                <HistoryDropdown className="ml-4" width={320} type="offer" />
              </div>

              <PageInfo className="ml-auto my-auto ">
                {` Adblocks remaining: ${queueCount} `}
              </PageInfo>
              <FlagButtonGroup
                isSubmitting={isSubmitting}
                isFetching={isSubmitting}
                onDoNotEnter={handleDoNotEnterAdblock}
                onSkip={handleSkipAdblock}
                onReject={handleReject}
                onApprove={handleApprove}
                isDoNotEnter={isDoNotEnter}
                isSkipped={isSkipped}
                isRejected={isRejected}
                isApproved={isApproved}
                isAllOfferValid={allOffersValid}
              />
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onRefresh={refetchAdblocks}
                onChange={(e) => events.setValue(e)}
              />
              {(offerData.length > 0 || incentiveData.length > 0) && (
                <MetaData className="mt-3" />
              )}
            </MetadataSection>
          </div>
          <AdblockImage
            filename={adblockFilename}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            queueCount={queueCount}
            currentAdBlockData={adblockData}
            isEndQueue={isEndQueue}
          />

          <div className="d-flex flex-column flex-1">
            <OfferSelector
              title={`OFFERS & INCENTIVES ${
                currentOfferIndex.value +
                1 +
                (currentItemType === "incentive"
                  ? currentIncentiveIndex.value + 1
                  : 0)
              }/${offerData.length + incentiveData.length}`}
              onAdd={handleAddItem}
              isAddable
            >
              {offerData.length === 0 && incentiveData.length === 0 ? (
                <EmptyOfferSelector
                  onCreateOffer={handleAddOffer}
                  onCreateIncentive={handleAddIncentive}
                />
              ) : (
                <div className="d-flex">
                  <ItemsList>
                    {offerData.map((offer, index) => (
                      <OfferItem
                        key={`offer${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentOfferIndex.value &&
                          currentItemType === "offer"
                        }
                        onClick={() => handleOfferSelect(index)}
                        onRemove={handleRemoveOffer}
                        onClone={handleCloneOffer}
                        data={offer}
                        index={index}
                      />
                    ))}
                    {incentiveData.map((incentive, index) => (
                      <IncentiveItem
                        key={`incentive${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentIncentiveIndex.value &&
                          currentItemType === "incentive"
                        }
                        onClick={() => handleIncentiveSelect(index)}
                        onRemove={() => handleRemoveIncentive(index)}
                        onClone={() => handleCloneIncentive(index)}
                        data={incentive}
                        index={index}
                        isApproved={isApproved}
                      />
                    ))}
                  </ItemsList>
                </div>
              )}
            </OfferSelector>
            <div
              className="d-flex flex-1"
              style={{ height: "calc(100% - 195px)" }}
            >
              <QuickSelection
                onRefreshSuggestion={() => refreshAdblockSuggestions()}
                isRefresh={adblockData.length > 0}
              >
                {isManualEntry && !isApproved && (
                  <ManualEntry onExit={() => setIsManualEntry(false)} />
                )}
                {((activeField === "offer_text" && isManualEntry) ||
                  (activeField === "offer_text" &&
                    currentSuggestions &&
                    currentSuggestions.length === 0)) && (
                  <OfferTextBuilder
                    type={currentInfo?.offer_type}
                    value={currentInfo?.offer_text}
                    onChange={(e) => {
                      updateInfo("offer_text", e);
                    }}
                  />
                )}
                {((activeField === "incentive_text" && isManualEntry) ||
                  (currentSuggestions &&
                    currentSuggestions.length === 0 &&
                    activeField === "incentive_text")) && (
                  <IncentiveTextBuilder
                    type={currentInfo?.incentive_type}
                    value={currentInfo?.incentive_text}
                    onChange={(e) => {
                      updateInfo("incentive_text", e);
                    }}
                  />
                )}
                {
                  //TODO: this part should have its own loading state
                  isFetching || suggestionsLoading ? (
                    <div>Loading...</div>
                  ) : offerData.length === 0 && incentiveData.length === 0 ? (
                    <div className="mt-4 pt-4 text-center">
                      No offer/incentive available
                    </div>
                  ) : (
                    <>
                      <div className="text-blue-light mb-3">
                        {activeOfferInfoText}
                      </div>

                      {isApproved ? (
                        <AdblockApproved />
                      ) : currentSuggestions.length > 0 ? (
                        <>
                          {!isManualEntry && (
                            <QuickSelectionChoice
                              index={0}
                              data={{
                                title: "Manual Entry",
                                value: "Manual",
                              }}
                              active={false}
                              showIndex={!isManualEntry}
                              onClick={handleSuggestionClick}
                            />
                          )}

                          {currentSuggestions
                            .slice(
                              0,
                              isManualEntry ? currentSuggestions.length : 9
                            )
                            .filter((item) => item.title)
                            .map((item, index) => (
                              <QuickSelectionChoice
                                key={`${index}key`}
                                showIndex={!isManualEntry}
                                index={index + (isManualEntry ? 0 : 1)}
                                data={item}
                                active={activeFieldValue === item.value}
                                onClick={handleSuggestionClick}
                              />
                            ))}
                        </>
                      ) : (
                        <>
                          <NoSuggestions />
                        </>
                      )}
                    </>
                  )
                }
              </QuickSelection>

              {currentItemType === "offer" ? (
                <OfferInfo offerTypeData={offerTypeData} />
              ) : currentItemType === "incentive" ? (
                <IncentiveInfo />
              ) : (
                <EmptyInfo />
              )}
            </div>
          </div>
        </Content>
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
`;

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;

const SelectionContainer = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  color: white;
  flex: 1;
  max-height: calc(100% - 12px);
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const NoItems = ({ onCreate = () => {} }) => {
  return (
    <NoItemsContainer className="mt-2">
      <div>There is no offer available.</div>
      <div>
        {" "}
        <span className="cursor-pointer" onClick={onCreate}>
          <u>Create</u>
        </span>{" "}
        a new offer or flag the adblock appropriately.
      </div>
    </NoItemsContainer>
  );
};

const NoItemsContainer = styled.div`
  font-size: 14px;
`;

const BreadCrumb = styled.div`
  color: white;
  font-size: 14px;
`;
