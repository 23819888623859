import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const ManualEntry = ({ onExit = () => {}, isStatic }) => {
  const { postPageEvents } = useMainContext();
  const handleExit = () => {
    postPageEvents("click", "Exit Manual Entry");
    onExit();
  };

  return (
    <Container>
      <div className="heading">Manual Entry Enabled</div>
      <div className="text">
        To exit manual entry{" "}
        <ClickHere onClick={handleExit}>click here</ClickHere> or press ESC.
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.secondary};
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
  margin-bottom: 8px;
  .heading {
    font-size: 12px;
  }
  .text {
    font-size: 9px;
  }
`;

const ClickHere = styled.span`
  text-decoration: underline;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
