import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";
import { useModal } from "hooks";
import { CreateModifyUserModal } from "modals";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {

  const createUserModal = useModal();

  const handleCreateUser = () => {
    createUserModal.openModal();
  };

  return (
    <Container>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn label="Email"></TableHeaderColumn>
      <TableHeaderColumn label="User Name"></TableHeaderColumn>
      <TableHeaderColumn label="Full Name"></TableHeaderColumn>
      <TableHeaderColumn label="Super User"></TableHeaderColumn>
      <TableHeaderColumn label="Active"></TableHeaderColumn>
      <TableHeaderColumn label="Created Date"></TableHeaderColumn>
      <Link onClick={handleCreateUser}>Create New User</Link>
      <CreateModifyUserModal {...createUserModal} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  position:relative;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  & > div:nth-child(1) {
    width: 0px;
  }
  & > div:nth-child(2) {
    width: 250px;
  }
  & > div:nth-child(3) {
    width: 250px;
  }
  & > div:nth-child(4) {
    width: 250px;
  }
  & > div:nth-child(5) {
    width: 200px;
  }
  & > div:nth-child(6) {
    width: 150px;
  }
  & > div:nth-child(7) {
    width: 250px;
  }
  & > div:nth-child(8) {
    flex: 1;
  }
`;

const Link = styled.div`
  position:absolute;
  right:20px;
  top:-4px;
  text-decoration: underline;
  &:hover {
    color:${(props) => props.theme.palette.primaryHover}
  }
  cursor:pointer;
`;
