/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useMainContext } from "contexts";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./style.scss";

export const IncentiveTypeSearch = ({
  // value,
  onChange = () => {},
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "Search by incentive type",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  func = () => {},
  onSelect = () => {},
  onSearch = () => {},
  onClick = () => {},
  onFocus = () => {},
  data = [],
  id = "",
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const [innerValue, setInnerValue] = useState("");
  const {
    postPageEvents,
    updateInfo,
    currentInfo,
    moveNextField,
    setActiveField,
    activeField,
    setIsManualEntry,
  } = useMainContext();
  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    if (!focused) {
      setShowResults(false);
    }
  }

  const handleChange = (params) => {
    setSearch("");
    onChange(params[idField]);

    onSelect(params);
    setInnerValue(params[idField]);
    setShowResults(false);
    postPageEvents("click", id || label);
    updateInfo(inputId, params[idField]);
    moveNextField();
  };

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);

    // setTimeout(() => {
    //   setShowResults(false);
    // }, "100");
  };

  const setShowData = (event) => {
    setFocused(true);
    setShowResults(true);
    setInnerValue("");
    postPageEvents("focus", id || label);
    onChange("");
    setActiveField(inputId);
  };

  useEffect(() => {
    if (debouncedValue) {
      setShowResults(true);
    } else {
      //setShowResults(false);
    }
    return () => {};
  }, [debouncedValue]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => {};
  }, [displayValue]);

  useEffect(() => {
    if (currentInfo[inputId]) {
      setInnerValue(currentInfo[inputId]);
    }
    return () => {};
  }, [currentInfo[inputId]]);

  useEffect(() => {
    setFocused(activeField === inputId);
    if (activeField !== inputId) {
      setShowResults(false);
    }
    return () => {};
  }, [activeField, inputId]);

  useEffect(() => {
    if (!currentInfo[inputId]) {
      setInnerValue("");
    }
    return () => {};
  }, [currentInfo[inputId]]);

  const handleClick = () => {
    onClick();
    setIsManualEntry(true);
  };

  return (
    <Container>
      <Label>{label}</Label>
      <div
        className={`d-flex incentive-type-search-container`}
        onClick={handleClick}
      >
        <div className="incentive-type-search" style={{ width }}>
          <div className="menu-button" style={{ width }} onClick={setShowData}>
            {innerValue ? (
              <>
                <InnerValue className="mr-auto" value={innerValue} readOnly />
                <AiFillCaretDown
                  color="white"
                  className="menu-button__drop-down"
                  alt="drop-down"
                />
              </>
            ) : (
              <div className="d-flex w-100">
                <StyledInput
                  type="text"
                  id={inputId || Math.random(100)}
                  aria-labelledby={"auto complete"}
                  autocomplete="false"
                  onBlur={handleBlur}
                  aria-describedby={"auto complete"}
                  value={search}
                  placeholder={placeholder}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />

                <AiFillCaretUp
                  color="white"
                  className="menu-button__drop-down"
                  alt="drop-down"
                />
              </div>
            )}

            <Underline focused={focused} />
          </div>
          {showResults && (
            <div ref={menuRef} className="menu-dropdown">
              {data.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
              ).length > 0 ? (
                data
                  .filter((item) =>
                    item.title.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        className={classNames(
                          "menu-item",
                          item[idField] === currentInfo[inputId] ? "active" : ""
                        )}
                        key={`key${index}`}
                        onClick={() => handleChange(item)}
                        style={{ width }}
                      >
                        {item.title}
                      </div>
                    );
                  })
              ) : (
                <div className="my-3 d-flex justify-content-center">
                  No results
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  label {
    position: absolute;
    top: 0;
  }
`;

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const Label = styled.label`
  font-size: 12px;
  color: #707070;
  margin-bottom: 0;
`;
const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;
