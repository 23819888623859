export const API_URL = "https://api.dev.addisonpromo.com/";
export const HEADER_COLOR = "#2a90af";
export const HEADER_BANNER = "Development";
export const TAB_DATA = [{ label: "USER ADMIN", url: "/users" }, {label: "RETAILERS", url: "/retailers"}];

export const COUNT_PER_PAGE = 50;
export const POST_COUNT_PER_PAGE = 50;
export const MAX_FILE_UPLOAD_SIZE = 1024 * 1024 * 500; // 500MB
export const TOKEN_EXPIRATION = 1000 * 60 * 60 * 24 * 7; // 1 week

export const DURATION_MIN = 1;
export const DURATION_MAX = 120;

export const SPECIAL_DATES = [
  "yesterday",
  "yesterweek",
  "yestermonth",
  "yesterquater",
];

export const JOB_STATUS_PROGRESS = {
  NEW: "Stage",
  CRAWL_PRE: "Scraping",
  CRAWL_IP: "Scraping",
  CRAWL_POST: "Scraping",
  PARSE_PRE: "Parsing",
  PARSE_IP: "Parsing",
  PARSE_POST: "Parsing",
  ETL_PRE: "Deliverables",
  ETL_IP: "Deliverables",
  ETL_POST: "Deliverables",
  DONE: "Finish",
};

export * from "./offer";
