/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import styled from "styled-components";
import { Input2 } from "components";
import { useInput } from "hooks";
export const OfferPriceEachTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const valueStart = useInput("");
  const valueEnd = useInput("");

  const handleUpdateValue = (start, end) => {
    if (start && !end) {
      onChange(`${start}`);
    }else if(start && end){
      onChange(`${start}-${end}`);
    }else{
      onChange(``);
    }
  };


  useEffect(() => {
    if (value) {
      const parts = value.split("-");
    
      if (parts.length > 1) {
        valueStart.setValue(parts[0]);
        valueEnd.setValue(parts[1]);
      } else {
        valueStart.setValue(parts[0]);
      }
    }
    return () => {};
  }, [value]);


  return (
    <BuilderContainer className={`${className} mt-1`}>
      <Title>Offer Text Builder</Title>
      <Container>
        <div style={{ width: 90 }}>
          <Input2
            label="Value Start"
            value={valueStart.value}
            onChange={(e) => {
              valueStart.setValue(e);
              handleUpdateValue(e, valueEnd.value);
            }}
          />
        </div>
        <div className="mt-auto mb-1 p-2 text">-</div>
        <div style={{ width: 90 }}>
          <Input2
            label="Value End"
            value={valueEnd.value}
            onChange={(e) => {
              valueEnd.setValue(e);
              handleUpdateValue(valueStart.value, e);
            }}
          />
        </div>

      </Container>
    </BuilderContainer>
  );
};

const BuilderContainer = styled.div`
  padding:8px 0;
`;

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
