import { React, useState } from "react";
import styled from "styled-components";
import { useInput } from "hooks";
import { Accordion, DateRange, Button } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  getPageCroppingStats,
  getAblockTimingStats,
  getRetailerTimingStats,
} from "api";

import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const ReportsSection = ({ filterOptions, isLoading, onChange }) => {
  const pageCropDateFrom = useInput("");
  const pageCropDateTo = useInput("");
  const adblockTimeDateFrom = useInput("");
  const adblockTimeDateTo = useInput("");
  const retailerTimeDateFrom = useInput("");
  const retailerTimeDateTo = useInput("");
  const [isPageGenerating, setIsPageGenerating] = useState(false);
  const [isCropGenerating, setIsCropGenerating] = useState(false);

  const generateCropPageReport = async () => {
    setIsPageGenerating(true);

    let result = await getPageCroppingStats(
      pageCropDateFrom.value,
      pageCropDateTo.value
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `page_crop_timings_${pageCropDateFrom.value}-${pageCropDateTo.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsPageGenerating(false);
  };

  const generateAdblockTimeReport = async () => {
    setIsCropGenerating(true);

    let result = await getAblockTimingStats(
      adblockTimeDateFrom.value,
      adblockTimeDateFrom.value
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `adblock_timings_${adblockTimeDateFrom.value}-${adblockTimeDateFrom.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsCropGenerating(false);
  };
  const generateRetailerTimeReport = async () => {
    setIsCropGenerating(true);

    let result = await getRetailerTimingStats(
      retailerTimeDateFrom.value,
      retailerTimeDateFrom.value
    );
    if (result.success) {
      // Update adblocks
      const url = window.URL.createObjectURL(
        new Blob([result.results], { type: "text/plain" })
      );
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `retailer_timings_${retailerTimeDateFrom.value}-${retailerTimeDateFrom.value}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      //TODO: Show error message
    }

    setIsCropGenerating(false);
  };

  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>
          <div className="title">Reports</div>

          <Accordion title="Page Cropping Report" className="mb-1">
            <div className="py-1">
              <DateRange
                open={pageCropDateFrom || pageCropDateTo}
                startDate={
                  pageCropDateFrom.value !== ""
                    ? pageCropDateFrom.value
                    : undefined
                }
                endDate={
                  pageCropDateTo.value !== "" ? pageCropDateTo.value : undefined
                }
                onChange={(dates) => {
                  pageCropDateFrom.setValue(dates[0]);
                  pageCropDateTo.setValue(dates[1]);
                }}
              />
              <Button
                buttonTheme="dark"
                size="small"
                className="ml-auto my-auto"
                onClick={() => generateCropPageReport()}
                disabled={!pageCropDateFrom.value && !pageCropDateTo.value}
                isLoading={isPageGenerating}
              >
                Download Report
              </Button>
            </div>
          </Accordion>

          <Accordion title="Adblock Timing Report" className="mb-1">
            <div className="py-1">
              <DateRange
                open={adblockTimeDateFrom || adblockTimeDateTo}
                startDate={
                  adblockTimeDateFrom.value !== ""
                    ? adblockTimeDateFrom.value
                    : undefined
                }
                endDate={
                  adblockTimeDateTo.value !== ""
                    ? adblockTimeDateTo.value
                    : undefined
                }
                onChange={(dates) => {
                  adblockTimeDateFrom.setValue(dates[0]);
                  adblockTimeDateTo.setValue(dates[1]);
                }}
              />
              <Button
                buttonTheme="dark"
                size="small"
                className="ml-auto my-auto"
                onClick={() => generateAdblockTimeReport()}
                disabled={
                  !adblockTimeDateFrom.value && !adblockTimeDateTo.value
                }
                isLoading={isCropGenerating}
              >
                Download Report
              </Button>
            </div>
          </Accordion>
          <Accordion title="Retailers Timing Report" className="mb-1">
            <div className="py-1">
              <DateRange
                open={retailerTimeDateFrom || retailerTimeDateTo}
                startDate={
                  retailerTimeDateFrom.value !== ""
                    ? retailerTimeDateFrom.value
                    : undefined
                }
                endDate={
                  retailerTimeDateTo.value !== ""
                    ? retailerTimeDateTo.value
                    : undefined
                }
                onChange={(dates) => {
                  retailerTimeDateFrom.setValue(dates[0]);
                  retailerTimeDateTo.setValue(dates[1]);
                }}
              />
              <Button
                buttonTheme="dark"
                size="small"
                className="ml-auto my-auto"
                onClick={() => generateRetailerTimeReport()}
                disabled={
                  !retailerTimeDateFrom.value && !retailerTimeDateTo.value
                }
                isLoading={isCropGenerating}
              >
                Download Report
              </Button>
            </div>
          </Accordion>
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .title {
    color: white;
    font-size: 12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform: uppercase;
  }
`;
