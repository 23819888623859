/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { Button, PageDropDown, CircularDropDown } from "components";
import { Card } from "./components";
import { useParams } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getImage } from "api";

export const ObjectPage = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentCircular, setCurrentCircular] = useState({});
  const [adBlockImg, setAdBlockImg] = useState(null);

  const init = async () => {
    setIsLoading(false);
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleLabel = () => {};

  const handleApprove = () => {};
  const onCircularSelect = (params) => {
    setCurrentCircular(params);
  };

  const onPageSelect = (params) => {
    fetchImage(params.filename);
  };

  const fetchImage = async (id) => {
    const img = await getImage(id);
    setAdBlockImg(img.payload);
  };

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout >
        <div className="flex-1 d-flex">
          <div className="text-white m-auto">Coming soon...</div>
        </div>
        {/* <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                <CircularDropDown onSelectValue={onCircularSelect} />
                <PageDropDown
                  className="ml-3"
                  id={currentCircular?.uuid}
                  onSelectValue={onPageSelect}
                />
              </div>
              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-auto my-auto"
                onClick={handleLabel}
              >
                LABEL
              </Button>

              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-2 my-auto"
                onClick={handleApprove}
              >
                APPROVE
              </Button>
            </div>
          )}
        </Header>

        <Content>
     
          <div className="d-flex flex-1">
            <AdBlockSection title="Adblock">
              {adBlockImg && (
                <AdBlockImg
                  src={`data:image/jpeg;base64,${adBlockImg}`}
                  alt="adblock"
                />
              )}
            </AdBlockSection>
          </div>
          <LayerSection title="Offers">
            <ListItem index={1} title="75 BONUS POINTS WHEN YOU BUY ANY 1" />
            <ListItem index={2} title="FAIR TO COMPARE" />
            <ListItem index={3} title="Compare to Beyond Meat" />
            <ListItem index={3} title="with AdvantEdge Card Save $2" />
          </LayerSection>
          <LayerSection title="Products">
            <ListItem index={1} title="New" />
            <ListItem index={2} title="Full Circle Plant-Based Ground 16 oz" />
            <ListItem index={3} title="Compare to Beyond Meat" />
          </LayerSection>
        </Content> */}
      </MainLayout>
    </SkeletonTheme>
  );
};

// const AdBlockImg = styled.img`
//   width: 100%;
//   object-fit: contain;
// `;

// const Content = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex: 1;
//   overflow: auto;
// `;

// const LayerSection = styled(Card)`
//   min-width: 320px;
//   max-width: 320px;
// `;
// const AdBlockSection = styled(Card)`
//   flex: 1;
// `;

// const Header = styled.div`
//   height: 48px;
//   color: white;
//   font-size: 20px;
//   display: flex;
//   padding: 0 24px;
//   background: ${(props) => props.theme.palette.backgrounds};
//   margin-bottom: 1px;
// `;

// const ListItem = ({ index, title }) => {
//   return (
//     <ListItemContainer>
//       <div className="index">{index}</div>
//       <div className="title">{title}</div>
//     </ListItemContainer>
//   );
// };

// const ListItemContainer = styled.div`
//   color: white;
//   border-bottom: 1px solid ${(props) => props.theme.palette.gray};
//   padding: 8px;
//   margin: 4px 0;
//   .index {
//     color: ${(props) => props.theme.palette.secondary};
//     font-size: 12px;
//   }
//   .title {
//     font-size: 18px;
//     line-height: 18px;
//     margin-left: 18px;
//   }
// `;
