/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, Button } from "components";
import useCountDown from "react-countdown-hook";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { resetTimerAdblock } from "api";
import { useMainContext } from "contexts";
import { useLocation } from "@reach/router";

export const IdleModal = ({ open, onClose }) => {
  const [timeLeft, { start, reset }] = useCountDown(30000, 1000);
  const { currentAdBlockId } = useMainContext();
  const location = useLocation();

  const handleStillWorking = async () => {
    onClose();
    await resetTimerAdblock(
      currentAdBlockId,
      location.pathname.startsWith("/offers")
        ? "offers"
        : location.pathname.startsWith("/products")
        ? "products"
        : location.pathname.startsWith("/adblocks")
        ? "adblocks"
        : ""
    );
    reset();
  };

  useEffect(() => {
    if (open) {
      start();
    }

    return () => {};
  }, [open]);

  useEffect(() => {
    if (timeLeft === 1000) {
      navigate("/home");
    }
    return () => {};
  }, [timeLeft]);

  const handleReport = () => {
    window.location.href =
      "https://deepad.atlassian.net/servicedesk/customer/portal/6";
  };
  const handleHelp = () => {
    window.location.href =
      "https://deepad.atlassian.net/servicedesk/customer/portal/6";
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      size="medium"
      title={"Are you still there?"}
    >
      <div>
        <Content>
          If not, we will close end this session in: 00:
          {timeLeft / 1000 < 10 ? `0${timeLeft / 1000}` : timeLeft / 1000}
        </Content>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleStillWorking()}
        >
          Still Working
        </Button>
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={(e) => handleReport(e)}
        >
          Report Issue
        </Button>
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={(e) => handleHelp(e)}
        >
          Help
        </Button>
      </div>
    </Modal>
  );
};

const Content = styled.div`
  font-size: 16px;
`;
