import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

const KEY_MAP = {
  bulkqty_f: "Bulk Quantity",
  newitem_f: "New Item",
  select_varieties_f: "Selected Varieties",
  bonus_card_f: "Bonus Card",
  brand_equity_f: "Brand Equity",
  coupon_f: "Coupon",
  inc_discount_f: "Inc Discount",
  limitedtime_f: "Limited Time",
  loyalty_f: "Loyalty",
  meal_deal_f: "Meal Deal",
  online_only_f: "Online Only",
  show_math_f: "Show Math",
  sugar_tax_f: "Sugar Tax",
};
const PRODUCT_KEY_MAP = {
  liner_f: "Liner",
  limit: "Limit",
};
const OFFER_KEY_MAP = {
  limit: "Limit",
  coupon_f: "Coupon",
  coupon_amount: "Coupon Amount",
  liner_f: "Liner",
  min_qty_dollar_f: "Minimum Qualifier Amount",
  min_qty: "Minimum Qualifier",
  mix_and_match_f: "Mix and match",
  qty_off_total_purchase_f: "Apply to total purchase",
  rebate_f: "Rebate",
  rebate_amount: "Rebate Amount",
  reg_price: "Regular Price",
};
export const AdblockInfo = ({ className, isLoading, data }) => {
  return (
    <Container className={className}>
      <Header>
        <span>ADBLOCK INFORMATION -&nbsp;</span>
        {data && Object.keys(data).length > 0 && (
          <>
            <Info>
              {data.products.length} Products / {data.offers.length} Offers /{" "}
              {data.incentive_text ? data.incentive_text.split(",").length : 0}{" "}
              Incentives
            </Info>
            <Status
              approved={data.products.length && data.offers.length}
              rejected={data.rejected}
            >
              {data.products.length && data.offers.length
                ? "APPROVED"
                : data.do_not_enter_f
                ? "NO ENTRY"
                : data.skipped
                ? "SKIPPED"
                : data.rejected
                ? "REJECTED"
                : data.products.length === 0 &&
                  data.offers.length === 0 &&
                  !data.incentive_text
                ? "Unstarted"
                : "INCOMPLETE"}
            </Status>
          </>
        )}
      </Header>
      <Content>
        {isLoading ? (
          <div>
            <Skeleton count={8} height={20} />
          </div>
        ) : data?.do_not_enter_f || data?.skipped ? (
          <div className="d-flex">
            <div className="my-4 mx-auto">No data available</div>
          </div>
        ) : data && Object.keys(data).length > 0 ? (
          <>
            {data.products.map((product, index) => (
              <div key={`${index}key`}>
                <Row>
                  <div>PRODUCT {index + 1}</div>
                  <div>{product?.brand?.brand_name}</div>
                </Row>
                {Object.keys(PRODUCT_KEY_MAP)
                  .filter((key) => product[key])
                  .map((key) => (
                    <Row className="ml-4" key={key}>
                      <div>{PRODUCT_KEY_MAP[key]}</div>
                      <div>{product[key] === true ? "TRUE" : product[key]}</div>
                    </Row>
                  ))}
              </div>
            ))}
            {data.offers.map((offer, index) => (
              <div key={`${index}key`}>
                <Row>
                  <div>OFFER {index + 1}</div>
                  <div>{offer?.offer_text}</div>
                </Row>
                {Object.keys(OFFER_KEY_MAP)
                  .filter((key) => offer[key])
                  .map((key) => (
                    <Row className="ml-4" key={key}>
                      <div>{OFFER_KEY_MAP[key]}</div>
                      <div>{offer[key] === true ? "TRUE" : offer[key]}</div>
                    </Row>
                  ))}
              </div>
            ))}
            {data.incentive_text &&
              data.incentive_text.split(",").map((incentive, index) => (
                <Row key={`${index}key`}>
                  <div>INCENTIVE {index + 1}</div>
                  <div>{incentive}</div>
                </Row>
              ))}
            {Object.keys(KEY_MAP)
              .filter((key) => data[key])
              .map((key) => (
                <Row key={key}>
                  <div>{KEY_MAP[key]}</div>
                  <div>TRUE</div>
                </Row>
              ))}
          </>
        ) : null}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px 0 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: 100%;
`;

const Row = styled.div`
  height: 28px;
  line-height: 28px;
  display: flex;
  color: white;
  font-size: 14px;
  & > div:first-child {
    width: 240px;
    text-align: left;
    padding-left: 8px;
  }
  & > div:last-child {
    padding-left: 8px;
    flex: 1;
    text-align: left;
    border-left: 2px solid ${(props) => props.theme.palette.backgrounds};
  }
  &:nth-child(2n) {
    background: ${(props) => props.theme.palette.backgrounds};
  }
  &:nth-child(2n + 1) {
    background: ${(props) => props.theme.palette.items};
  }
`;

const Info = styled.div`
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 2px;
`;

const Status = styled.div`
  margin-left: auto;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.theme.palette.warning};
  color: ${(props) =>
    props.approved || props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;
