import React from "react";
import styled from "styled-components";
import {
  Card,
  ScrollView,
  OfferTypeSearch,
  InfoInput,
  InfoCheckBox,
  OfferInfoContainer,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { useMainContext } from "contexts";

export const OfferInfo = ({ isEmpty, offerTypeData = [] }) => {
  const { currentInfo, isManualEntry, updateInfos } = useMainContext();

  const handleOfferTypeChange = (params) => {
    if (params && isManualEntry) {
      setTimeout(() => {
        updateInfos({
          offer_text: INIT_OFFER_TEXT[params.toLowerCase()],
          offer_type: params,
        });
      }, 300);
    }
  };
  return (
    <InfoSection title="OFFER INFORMATION" className="flex-1 flex-column">
      {isEmpty ? (
        <div className="mt-4 pt-4 text-center">No offers available</div>
      ) : (
        <ScrollView>
          <form autoComplete="off">
            <input style={{ display: "none" }} autoComplete="false"></input>

            <OfferInfoContainer inputId="offer_type">
              <OfferTypeSearch
                label="Offer Type"
                inputId="offer_type"
                data={offerTypeData}
                disabled={!isManualEntry}
                onChange={handleOfferTypeChange}
              />
            </OfferInfoContainer>

            <InfoInput
              className="mt-3"
              label="Offer Text"
              inputId="offer_text"
            />
            <div className="d-flex mt-3">
              <InfoInput label="Regular Price" inputId="reg_price" />
              <InfoInput label="Limit" inputId="limit" />
            </div>
            <InfoCheckBox
              className="mt-3"
              inputId="coupon_f"
              label="Does the offer have a coupon?"
            />
            {currentInfo?.coupon_f && (
              <InfoInput
                className="mt-2 ml-4 pl-2"
                inputId="coupon_amount"
                label="Coupon amount"
              />
            )}
            <InfoCheckBox
              className="mt-3"
              inputId="rebate_f"
              label="Does the offer have a rebate?"
            />
            {currentInfo?.rebate_f && (
              <InfoInput
                className="mt-2 ml-4 pl-2"
                inputId="rebate_amount"
                label="Rebate amount"
              />
            )}
            <InfoCheckBox
              className="mt-3"
              inputId="min_qty_dollar_f"
              label="Does the offer have a minimum qualifier?"
            />
            {currentInfo?.min_qty_dollar_f && (
              <InfoInput
                className="mt-2 ml-4 pl-2"
                inputId="min_qty"
                label="Minimum qualifier amount"
              />
            )}
            <InfoCheckBox
              className="mt-3"
              inputId="must_buy_f"
              label="Does the offer have must buy amounts?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="qty_off_total_purchase_f"
              label="Does the offer apply to the total purchase?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="mix_and_match_f"
              label="Does the offer allow mix and match?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="include_in_net_cost_f"
              label="Does the offer include net cost?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="liner_f"
              label="Does the offer have a liner?"
            />
          </form>
        </ScrollView>
      )}
    </InfoSection>
  );
};

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;
