import {
  SET_LOADING,
  SET_ERROR,
  GET_USERS,
  INIT_USERS,
  GET_MORE_USERS,
  SET_REFETCHING,
  SET_USERS_SEARCH,
  SET_USERS_FILTER,
} from "../types";

export default function JobReducer(state, action) {
  const { payload } = action;

  switch (action.type) {
    case INIT_USERS:
      return {
        currentPage: 0,
        users: [],
        isLoading: false,
        isRefetching: false,
        totalCount: 0,
        resultCount: 0,
        totalPages: 0,
        sortBy: "username",
        sortDir: "ASC",
      };
    case GET_USERS:
      return {
        ...state,
        users: payload.users,
        isLoading: false,
        isRefetching: false,
        totalCount: payload.total,
        resultCount: payload.users.length,
        totalPages: payload.pages,
        currentPage: 0,
      };
    case GET_MORE_USERS:
      return {
        ...state,
        users: [...state.users, ...payload.users],
        isRefetching: false,
        totalCount: payload.total,
        resultCount: payload.users.length,
        totalPages: payload.pages,
        currentPage: state.currentPage + 1,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload || true,
      };
    case SET_REFETCHING:
      return {
        ...state,
        isRefetching: payload || true,
      };
    case SET_USERS_SEARCH:
      return {
        ...state,
        query: payload,
      };
    case SET_USERS_FILTER:
      return {
        ...state,
        ...payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
