import React from "react";
import { useAuthContext } from "contexts";
import { Redirect } from "@reach/router";

export const PublicRoute = ({ as: Component, ...props }) => {
  const { userInfo } = useAuthContext();
  return (
    <div>
      {localStorage.getItem("user") ? (
        userInfo?.user?.is_superuser ? (
          <Redirect to="/circular" noThrow />
        ) : (
          <Redirect to="/home" noThrow />
        )
      ) : (
        <Component {...props} />
      )}
    </div>
  );
};
