import React from "react";
import styled from "styled-components";

export const Adblock = ({ className, onClick, isActive, data }) => {
  return (
    <Container className={className} onClick={onClick} isActive={isActive}>
      <Prefix
        color={
          data.products.length && data.offers.length
            ? "green"
            : data.products.length || data.offers.length || data.incentive_text
            ? "yellow"
            : "gray"
        }
      />
      <Content>
        <Name>
          {data.skipped
            ? "Skipped"
            : data.do_not_enter_f
            ? "No Entry"
            : data.rejected
            ? "Rejected"
            : data.products.length === 0 &&
              data.offers.length === 0 &&
              !data.incentive_text
            ? "Unstarted"
            : `${data.products.length} Products / ${
                data.offers.length
              } Offers /${" "}
          ${
            data.incentive_text ? data.incentive_text.split(",").length : 0
          }${" "}
          Incentives${" "}`}
        </Name>
        <Label>{data?.uuid}</Label>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.palette.primaryHover
        : props.theme.palette.backgrounds};
  cursor: pointer;
  margin: 1px 0;
`;

const Prefix = styled.div`
  width: 8px;
  background: ${(props) =>
    props.color === "green"
      ? props.theme.palette.success
      : props.color === "yellow"
      ? props.theme.palette.warning
      : props.theme.palette.itemsHover};
`;
const Content = styled.div`
  flex: 1;
  padding: 4px;
  background: ${(props) => props.theme.palette.gray};
`;
const Name = styled.div`
  color: white;
  font-size: 14px;
`;
const Label = styled.div`
  color: gray;
  font-size: 10px;
`;
