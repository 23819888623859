import React from "react";
import styled from "styled-components";
import { Card } from "components";

export const EmptyInfo = () => {
  return (
    <InfoSection title="OFFER INFORMATION" className="flex-1 flex-column">
      <div className="mt-4 pt-4 text-center">
        No offers/incentives available
      </div>
    </InfoSection>
  );
};

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;
