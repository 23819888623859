import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  postRetailerByIdActivate,
  postRetailerByIdDeactivate
 } from "api";


export const TableRow = ({ isLoading, data }) => {
  
  const [active, setActive] = useState(false);

  const init = async () => {
    setActive(data.active);
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleActiveRetailer = async () => {
    if(data.active === true) {
      await postRetailerByIdDeactivate(data.id);
      setActive(false);
      data.active = false;
    }else{
      await postRetailerByIdActivate(data.id);
      setActive(true);
      data.active = true;
    }

  };

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={data.is_active}>
      <div className="inner">
        <RowItem>{data?.name}</RowItem>
        <RowItem>{data?.parent_name}</RowItem>
        <RowItem>{data?.ext_id}</RowItem>
        <RowItem>
          <Link onClick={toggleActiveRetailer}>
            {active === true ? "Deactivate" : "Activate"}
          </Link>
        </RowItem>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;

  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === true
        ? props.theme.palette.success
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    position:relative;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 350px;
    }
    & > div:nth-child(3) {
      width: 250px;
    }
    & > div:nth-child(4) {
      width: 200px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  span {
    color:${(props) => props.theme.palette.error};
  }
`;

const Link = styled.div`
  position:absolute;
  right:20px;
  top:35px;
  text-decoration: underline;
  &:hover {
    color:${(props) => props.theme.palette.primaryHover}
  }
  cursor:pointer;
`;
