import React from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { FaPlus, FaTrash } from "react-icons/fa";

const types = [
  { id: "page", title: "Page" },
  { id: "circular", title: "Circular" },
];

export const AdblockIncentives = ({
  onAdd = () => {},
  onDelete = () => {},
  onUpdate = () => {},
  data = [],
}) => {
  return (
    <Container>
      <Header>
        Add Incentive
        <Button className="ml-2 mt-auto mb-1" onClick={onAdd}>
          <FaPlus color="white" size={16} />
        </Button>
      </Header>
      <Content>
        {data.map((item, index) => (
          <Incentive
            key={`${index}key`}
            data={item}
            onDelete={(data) => onDelete(index, data)}
            onUpdate={(data) => onUpdate(index, data)}
          />
        ))}
      </Content>
    </Container>
  );
};

const Incentive = ({ data, onDelete = () => {}, onUpdate = () => {} }) => {
  return (
    <div className="d-flex my-1">
      <DropDown2
        label="Type"
        data={types}
        width={120}
        value={data.type}
        onChange={(e) => {
          onUpdate({ ...data, type: e });
        }}
      />
      <Input2
        label="Text"
        className="ml-2"
        value={data.text}
        onChange={(e) => {
          onUpdate({ ...data, text: e });
        }}
      />
      <Button className="my-auto ml-3 mr-2" onClick={onDelete}>
        <FaTrash color="white" size={16} />
      </Button>
    </div>
  );
};

const Button = styled.div`
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  svg {
    margin-bottom: auto;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const Container = styled.div`
  height: 200px;
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;
  margin-bottom: 5px;
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
