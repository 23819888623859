import React from "react";
import styled from "styled-components";
import { ColorPick } from "components";
const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const PageView = ({
  className,
  children,
  activeColor,
  onSelectColor,
}) => {
  return (
    <Container className={className}>
      <Header>
        PAGE
        <div className="d-flex ml-auto">
          {OUTLINE_COLORS.map((color) => {
            return (
              <ColorPick
                bgColor={color}
                key={color}
                active={color === activeColor}
                onClick={() => onSelectColor(color)}
              />
            );
          })}
        </div>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};
const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 0px 0px;
  flex: 1;
  max-height: calc(100% - 12px);
`;
