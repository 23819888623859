/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, DropDownEdit2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveGasTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const val = useInput("0.05");
  const unit = useInput("per gallon");
  const isPurchase = useInput(false);
  const pVal = useInput("");
  const pType = useInput("");
  const suffix = useInput("");

  const typeData = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];
  const valueData = [{ id: "double savings", title: "double savings" }];
  const unitData = [
    { id: "per gallon", title: "per gallon" },
    { id: "per liter", title: "per liter" },
  ];

  const formatFirstLine = (v, u) => {
    if (v === "double savings") {
      return `double savings${u ? ` ${u}` : ""}`;
    }
    return `$${v}${u ? ` ${u}` : ""}`;
  };
  const formatPurchase = (isP, pV, pT) => {
    if (isP) {
      if (pT === "$") {
        return ` w/purchase/$${pV}`;
      } else {
        return ` w/purchase/${pV}`;
      }
    }
    return "";
  };

  const handleUpdateValue = (isUT, v, u, isP, pV, pT, suf) => {
    onChange(
      `Earn${isUT ? " Up To" : ""} ${formatFirstLine(v, u)}${formatPurchase(
        isP,
        pV,
        pT
      )}${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    if (value.startsWith("Earn")) {
      let main = value.slice(4).trimStart();
      if (main.startsWith("Up To")) {
        main = main.slice(5).trimStart();
        isUpTo.setValue(true);
      } else {
        isUpTo.setValue(false);
      }
      const pIdx = main.indexOf("w/purchase/");
      if (pIdx > 0) {
        let firstPart = main.slice(0, pIdx).trim();
        if (firstPart.endsWith("per gallon")) {
          unit.setValue("per gallon");
          firstPart = firstPart.slice(0, -10).trim();
        } else {
          unit.setValue("per liter");
          firstPart = firstPart.slice(0, -9).trim();
        }
        if (firstPart.startsWith("$")) {
          val.setValue(firstPart.slice(1));
        } else if (firstPart === "double savings") {
          val.setValue("double savings");
        }
        main = main.slice(pIdx + 11);
        const sufIdx = main.indexOf(" ");
        if (sufIdx > 0) {
          suffix.setValue(main.slice(sufIdx + 1));
          const pv = main.slice(0, sufIdx).trim();
          if (pv.startsWith("$")) {
            pType.setValue("$");
            pVal.setValue(pv.slice(1));
          } else {
            pType.setValue("Unit");
            pVal.setValue(pv);
          }
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-1">Earn</div>
        <ToggleButton
          className="mt-auto mb-2 ml-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              val.value,
              unit.value,
              isPurchase.value,
              pVal.value,
              pType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 110, marginLeft: 10 }}>
          <DropDownEdit2
            label="Value"
            data={valueData}
            value={val.value}
            width={110}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                unit.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 100, marginLeft: 10 }}>
          <DropDown2
            label="Unit"
            data={unitData}
            width={100}
            value={unit.value}
            onChange={(e) => {
              unit.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                e,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          className="mt-auto mb-2"
          label="w/purchase"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              isUpTo.value,
              val.value,
              unit.value,
              e,
              pVal.value,
              pType.value,
              suffix.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            disabled={!isPurchase.value}
            value={pVal.value}
            onChange={(e) => {
              pVal.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                unit.value,
                isPurchase.value,
                e,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            disabled={!isPurchase.value}
            value={pType.value}
            onChange={(e) => {
              pType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                unit.value,
                isPurchase.value,
                pVal.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 80, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                val.value,
                unit.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
