import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";

export const OfferInfoContainer = ({
  children,
  active,
  className,
  style,
  onClick = () => {},
  inputId,
}) => {
  const { activeField } = useMainContext();

  return (
    <Container
      style={style}
      className={className}
      active={activeField === inputId}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 8px 0 8px;
  border-left: 4px solid
    ${(props) =>
      props.active ? props.theme.palette.primaryHover : "transparent"};

  label {
    color: ${(props) =>
      props.active
        ? props.theme.palette.primaryHover
        : props.theme.palette.secondary};
  }

  &:focus,
  &:active {
    border-left: 4px solid ${(props) => props.theme.palette.primaryHover};
  }
  &:focus-within {
    border-left: 4px solid ${(props) => props.theme.palette.primaryHover};
  }
`;
