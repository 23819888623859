import { API } from "utils";
import { COUNT_PER_PAGE } from "config";

export const getCircularsApi = async ({
  page = 0,
  per_page = COUNT_PER_PAGE,
  sort_by = "created_at",
  sort_dir = "ASC",
  retailer_id,
  created_time_from,
  created_time_to,
  circ_time_from,
  circ_time_to,
  stage = undefined,
}) => {
  try {
    const response = await API().get("api/circulars", {
      params: {
        page,
        per_page,
        sort_by,
        sort_dir,
        retailer_id,
        created_time_from,
        created_time_to,
        circ_time_from,
        circ_time_to,
        stage: stage ? stage.join(",") : undefined,
      },
    });
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getCircularById = async (id) => {
  try {
    const response = await API().get(`api/circulars/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const deleteCircularById = async (id) => {
  try {
    const response = await API().delete(`api/circulars/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getCircularPages = async (id) => {
  try {
    const response = await API().get(`api/circulars/${id}/pages`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const addCircularPages = async (id, data) => {
  try {
    const response = await API().post(`api/circulars/${id}/add-page`, data);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const approveCircular = async (id) => {
  try {
    const response = await API().post(`api/circulars/${id}/approve`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
