export const headerHeight = 70;

export const appColor = "#0A0A0A";

export const easing = "cubic-bezier(0.35, 0.01, 0.77, 0.34);";

const theme = {
  breakpoints: {
    xs: 0,
    ix: 400,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
  },
  palette: {
    primary: "#006280",
    primaryOutline: "#2a90af",
    primaryHover: "#66c0e1",
    accent: "#c8ad00",
    accentOutline: "#ffdd3a",
    accentHover: "#ffff70",
    success: "#63ce6b",
    successOutline: "#A3EEA9",
    error: "#ff8484",
    errorOutline: "#ffdfdf",
    info: "#3bc7e3",
    warning: "#fff0a8",
    warningOutline: "#fcf5d1",
    gray: "#2B2B2B",
    canvas: "#0a0a0a",
    backgrounds: "#131313",
    items: "#191919",
    itemsHover: "#555454",
    white: "#ffffff",
    background: "#0A0A0A",
    secondary: "#707070",
  },
  font: {
    family: {
      main: "QuickSand",
      bold: "QuickSand Bold",
      italic: "QuickSand Italic",
      secondary: "Open Sans",
    },

    size: {
      xxs: "8px",
      xs: "10px",
      s: "12px",
      sm: "14px",
      m: "16px",
      ms: "18px",
      xm: "20px",
      l: "24px",
      xl: "32px",
      xxl: "48px",
      xxxl: "72px",
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    },
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};

export default theme;
