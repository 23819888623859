/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button, FormInput } from "components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { resetPassword } from "api";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter your password"),
  confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please enter your confirm password"),
});

export const ResetPasswordModal = ({ open, onClose, onReject = () => {} }) => {
  let reset = () => {};
  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Reset Password"}>
      <div>
        <Formik
          initialValues={{
            password: "",
            confirm: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await resetPassword(values.password);
            onClose();
            reset();
          }}
        >
          {({ isSubmitting, resetForm }) => {
            reset = resetForm;
            return (
              <Form>
                <FormInput
                  label="New Password"
                  name="password"
                  type="password"
                />
                <FormInput
                  label="Confirm Password"
                  name="confirm"
                  type="password"
                />
                <div className="d-flex mt-3">
                  <Button
                    size="small"
                    className="ml-auto"
                    type="button"
                    onClick={onClose}
                    width="120px"
                    buttonTheme="dark"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    className="ml-3"
                    type="submit"
                    isLoading={isSubmitting}
                    width="160px"
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
