/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { MdMessage } from "react-icons/md";
import { FaTags, FaBarcode } from "react-icons/fa";
import { navigate } from "@reach/router";
import { useMainContext } from "contexts";
import { getImage } from "api";

export const AdblockImage = ({
  type = "",
  // adBlockImg,
  filename = "",
  imageHeight,
  imageWidth,
  blockRef,
  isFetching = false,
  queueCount = 1,
  currentAdBlockData,
  circularId,
  pageId,
  id = "Adblock Imnage",
  isEndQueue,
}) => {
  const [showOCR, setShowOCR] = useState(false);
  const [isInnerFetching, setIsInnerFetching] = useState(false);
  const { postPageEvents } = useMainContext();
  const [adBlockImg, setAdBlockImg] = useState("");
  const [error, setError] = useState("");
  const fetchImage = async () => {
    setIsInnerFetching(true);
    try {
      const img = await getImage(filename);
      setAdBlockImg(img.payload);
    } catch (error) {
      setError(error);
    }
    setIsInnerFetching(false);
  };

  useEffect(() => {
    if (filename) {
      fetchImage();
    }
    return () => {};
  }, [filename]);

  return (
    <Container className="d-flex flex-column flex-1">
      <Header>
        <div>Adblock</div>
        <div className="ml-auto d-flex my-auto">
          {type === "product" ? (
            <IconButton
              className=" my-auto mr-2"
              onClick={() => {
                postPageEvents("bounce", "product -> offer");
                navigate(
                  `/offer/${circularId}/${pageId}/${currentAdBlockData.uuid}`
                );
              }}
            >
              <FaTags color="white" />
            </IconButton>
          ) : type === "offer" ? (
            <IconButton
              className="my-auto mr-2"
              onClick={() => {
                postPageEvents("bounce", "offer -> product");
                navigate(
                  `/product/${circularId}/${pageId}/${currentAdBlockData.uuid}`
                );
              }}
            >
              <FaBarcode color="white" />
            </IconButton>
          ) : null}
          <IconButton
            className="my-auto"
            onClick={() => {
              setShowOCR((state) => !state);
              postPageEvents("click", id);
            }}
          >
            <MdMessage color="white" />
          </IconButton>
        </div>
      </Header>
      {showOCR && (
        <OcrText>
          <div className="ocr-heading">OCR TEXT:</div>
          <div className="ocr-text">{currentAdBlockData?.ocr_text}</div>
        </OcrText>
      )}
      <Content className="d-flex flex-row flex-1">
        <div className="w-100 h-100" ref={blockRef}>
          {isFetching || isInnerFetching ? (
            <Skeleton height={imageHeight} width={imageWidth} />
          ) : isEndQueue ? (
            <div className="text-center mt-4 pt-4 endQueue">End of Queue</div>
          ) : currentAdBlockData.length === 0 ? (
            <div className="text-center mt-4 pt-4">No Adblock available</div>
          ) : adBlockImg ? (
            <AdBlockImg
              src={`data:image/jpeg;base64,${adBlockImg}`}
              alt="adblock"
            />
          ) : error ? (
            <div className="text-center mt-4 pt-4">Error Loading Image</div>
          ) : null}
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  .endQueue {
    color: white;
  }
`;

const AdBlockImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  user-select: none;
`;

const IconButton = styled.span`
  cursor: pointer;
  &:hover {
    svg {
      transition: all 0.3s ease;
      fill: ${(props) => props.theme.palette.primary} !important;
    }
  }
`;

const OcrText = styled.div`
  position: absolute;
  width: calc(100% - 16px);
  top: 37px;
  left: 7px;
  padding: 16px;
  color: white;
  font-size: 14px;
  background-color: ${(props) => props.theme.palette.items};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  .ocr-heading {
    color: gray;
  }
  z-index: 0;
  user-select: text;
`;
