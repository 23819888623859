import React from "react";
import styled from "styled-components";

export const ManualEntry = ({ onExit = () => {}, isStatic }) => {
  return (
    <Container className="text-center">
      <h3 className="mt-4">Manual Entry Enabled</h3>
      
      {isStatic ? (
        <>
          <div className="mt-3">Adblock has already been flagged.</div>
          <div>To update adblock please use the form on the right.</div>
        </>
      ) : (
        <>
        <div className="mt-3">Please use the form on the right.</div>
        <div>To exit manual entry <ClickHere onClick={onExit}>click here</ClickHere>{" "} or press ESC.</div>
        </>
      )}

    </Container>
  );
};

const Container = styled.div`
  padding-top: 120px;
  color: #aaa;
  font-size: 14px;
  h3 {
    font-size: 24px;
  }
`;

const ClickHere = styled.span`
  text-decoration: underline;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    color: white;
  }
`;
