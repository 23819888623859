import React from "react";
import styled from "styled-components";

export const Card = ({ title, children, className }) => {
  return (
    <Container className={className}>
      <Header>{title}</Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 3px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
`;
