import React from "react";
import styled from "styled-components";
import { Input2 } from "components";
import { FaTrash } from "react-icons/fa";

export const AdblockSection = ({
  isLoading,
  data,
  index,
  onDelete,
  active = false,
  type = "adblock",
  onClick = () => {},
  onChangeText = () => {},
}) => {
  const handleDelete = (data, index) => {
    onDelete(data, index);
  };
  return (
    <Container type={active ? "active" : type} onClick={onClick}>
      <div className="inner">
        <SectionTitle>Section {data?.index}</SectionTitle>
        <SectionUUID className="mt-1">{data?.uuid}</SectionUUID>
        <Input2
          label="Section Text"
          className="mt-2"
          value={data?.text}
          onChange={(e) => onChangeText(e)}
        />
        <FaTrash
          className="deleteIcon"
          onClick={() => handleDelete(data, index)}
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  cursor: pointer;
  background: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  font-size: 12px;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid
    ${(props) =>
      props.type === "active"
        ? props.theme.palette.primary
        : props.type === "page"
        ? props.theme.palette.accentHover
        : props.type === "circular"
        ? props.theme.palette.accentHover
        : props.type === "rejected"
        ? props.theme.palette.error
        : props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.type === "active"
        ? props.theme.palette.primary
        : props.type === "page"
        ? props.theme.palette.accentHover
        : props.type === "circular"
        ? props.theme.palette.accentHover
        : props.type === "rejected"
        ? props.theme.palette.error
        : props.theme.palette.gray};

  margin: 3px 6px 3px 6px;
  border-radius: 2px;
  .inner {
    width: 100%;
    padding: 5px;
    /* height: 45px; */
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    .deleteIcon {
      position: absolute;
      right: 10px;
      top: 5px;

      &:hover {
        background: ${(props) =>
          props.isLoading
            ? props.theme.palette.items
            : props.theme.palette.itemsHover};
      }
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 14px;
`;

const SectionUUID = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.palette.secondary};
`;
