import React, { useState, useEffect }  from "react";
import styled from "styled-components";
import { Progress } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { format } from "date-fns";
import { Link } from "@reach/router";

const TextLink = {
  color: "white",
};

export const TableRow = ({ isLoading, data }) => {
  const [status, setStatus] = useState("inprocess");

  const init = async () => {
    if(data?.num_dupe_pages === data?.num_pages && data?.num_dupe_pages === data?.num_pages_completed) {
      setStatus("error");
    }else if(data.stage === "PAGE_PROCESSING") {
      setStatus("");
    }else if(data.stage === "COMPLETE") {
      setStatus("complete");
    }
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading>
        <div className="inner">
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Progress isLoading />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
          <RowItem>
            <Skeleton height={32} />
          </RowItem>
        </div>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container status={status}>
      <div className="inner">
        <RowItem>
          <Link to={`/circular/${data?.uuid}/0`} style={TextLink}>
            {data?.retailer?.name}_{format(new Date(data.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM/dd/yyyy")}
          </Link>
        </RowItem>
        <RowItem>
          <Progress data={data} />
        </RowItem>
        <RowItem>{data?.stage}</RowItem>
        <RowItem>
          {data?.num_pages_completed} / {data?.num_pages}  <span>({data?.num_dupe_pages !== null ? data?.num_dupe_pages : 0 })</span>
        </RowItem>
        <RowItem>
          {data?.num_adblocks_completed} / {data?.num_adblocks} 
        </RowItem>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.palette.items};
  display: block;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  border-left: 12px solid
    ${(props) =>
      props.status === "complete"
        ? props.theme.palette.success
        : props.status === "warning"
        ? props.theme.palette.warning
        : props.status === "inprocess"
        ? props.theme.palette.primaryOutline
        : props.status === "error"
        ? props.theme.palette.error
        : props.status === "none"
        ? props.theme.palette.white
        : props.theme.palette.secondary};

  margin: 3px 6px 3px 12px;
  border-radius: 2px;
  .inner {
    display: flex;
    width: 100%;
    padding: 0 0px 0 32px;
    height: 88px;
    &:hover {
      background: ${(props) =>
        props.isLoading
          ? props.theme.palette.items
          : props.theme.palette.itemsHover};
    }

    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      width: 600px;
    }
    & > div:nth-child(3) {
      width: 200px;
    }
    & > div:nth-child(4) {
      width: 150px;
    }
    & > div:nth-child(5) {
      width: 150px;
    }
  }
`;

const RowItem = styled.div`
  margin: auto 16px auto 0;
  span {
    color:${(props) => props.theme.palette.error};
  }
`;
