import React, { createContext, useContext, useState } from "react";

export const AdblockHistoryContext = createContext([]);
export const useAdblockHistoryContext = () => useContext(AdblockHistoryContext);

export const AdblockHistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([]);
  const initHistory = (params) => {
    setHistory([]);
  };
  const pushHistory = (params) => {
    setHistory((history) =>
      [...history, { ...params, time: new Date() }].slice(-20)
    );
  };

  return (
    <AdblockHistoryContext.Provider
      value={{
        history,
        initHistory,
        pushHistory,
      }}
    >
      {children}
    </AdblockHistoryContext.Provider>
  );
};
