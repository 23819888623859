/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal } from "components";

export const ErrorModal = ({ open, onClose, children }) => {
  useEffect(() => {
    if (open) {
    }
    return () => {};
  }, [open]);

  return (
    <Modal open={open} size="medium" onClose={onClose} title={"Error"}>
      {children}
    </Modal>
  );
};
