/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveRewardsTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const start = useInput("10");
  const end = useInput("");
  const type = useInput("X");
  const reward = useInput("Bonus Card Points");
  const isPurchase = useInput(false);
  const pStart = useInput("");
  const pEnd = useInput("");
  const pType = useInput("");
  const suffix = useInput("");
  const typeData = [
    { id: "%", title: "%" },
    { id: "$", title: "$" },
    { id: "X", title: "X" },
    { id: "Rewards", title: "Rewards" },
  ];
  const typeData2 = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];
  const rewardData = [
    { id: "Bonus Card Points", title: "Bonus Card Points" },
    { id: "Box Tops Rewards", title: "Box Tops Rewards" },
    { id: "Credit Card Points", title: "Credit Card Points" },
    { id: "Gas Bonus Points", title: "Gas Bonus Points" },
    { id: "Labels for Learning", title: "Labels for Learning" },
    { id: "Labels for Education", title: "Labels for Education" },
    { id: "Rewards", title: "Rewards" },
    { id: "“R” Us", title: "“R” Us" },
    { id: "+ Up Rewards", title: "+ Up Rewards" },
    { id: "Balance Rewards", title: "Balance Rewards" },
    { id: "Cartwheel Points", title: "Cartwheel Points" },
    { id: "Extra Bucks", title: "Extra Bucks" },
    { id: "Familiplus", title: "Familiplus" },
    { id: "JcPenney Rewards", title: "JcPenney Rewards" },
    { id: "mPerks Rewards", title: "mPerks Rewards" },
    { id: "My Best Buy Points", title: "My Best Buy Points" },
    { id: "Office Depot Rewards", title: "Office Depot Rewards" },
    { id: "Office Max Rewards", title: "Office Max Rewards" },
    { id: "Plenti", title: "Plenti" },
    { id: "REDperks", title: "REDperks" },
    { id: "Register Rewards", title: "Register Rewards" },
    { id: "Sears Club Points", title: "Sears Club Points" },
    { id: "Shop Your Way Rewards", title: "Shop Your Way Rewards" },
    { id: "Staples Rewards", title: "Staples Rewards" },
    { id: "Wellness+ BonusCash", title: "Wellness+ BonusCash" },
    { id: "Yes Rewards", title: "Yes Rewards" },
    { id: "Yes2U", title: "Yes2U" },
    { id: "w/Ibotta App", title: "w/Ibotta App" },
    { id: "w/Fetch App", title: "w/Fetch App" },
    { id: "w/BUREA App", title: "w/BUREA App" },
    { id: "w/Checkout 51 App", title: "w/Checkout 51 App" },
    { id: "w/Brand Rewards", title: "w/Brand Rewards" },
  ];

  const formatFirstLine = (s, e, t) => {
    if (t === "$") {
      if (s && e) {
        return ` $${s}-$${e}`;
      } else if (s) {
        return ` $${s}`;
      }
    } else {
      if (s && e) {
        return ` ${s}-${e}${t}`;
      } else if (s) {
        return ` ${s}${t}`;
      }
    }
    return "";
  };

  const formatPurchase = (isP, ps, pe, pt) => {
    if (isP) {
      let result = "w/purchase/";
      if (ps && pe) {
        result += `${pt === "$" ? "$" : ""}${ps}-${pt === "$" ? "$" : ""}${pe}`;
      } else if (ps) {
        result += `${pt === "$" ? "$" : ""}${ps}`;
      }
      return ` ${result}`;
    }
    return "";
  };

  const handleUpdateValue = (isU, s, e, t, r, isP, ps, pe, pt, suf) => {
    onChange(
      `Earn${isU ? " Up To" : ""}${formatFirstLine(s, e, t)}${
        r ? ` ${r}` : ""
      }${formatPurchase(isP, ps, pe, pt)}${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    if (value.startsWith("Earn")) {
      let main = value.slice(4).trimStart();
      if (main.startsWith("Up To")) {
        main = main.slice(5).trimStart();
        isUpTo.setValue(true);
      } else {
        isUpTo.setValue(false);
      }
      const pIdx = main.indexOf("w/purchase/");
      if (pIdx > 0) {
        const first = main.slice(0, pIdx).trim();
        const vIdx = first.indexOf(" ");
        if (vIdx) {
          let v = first.slice(0, vIdx).trim();
          if (v.startsWith("$")) {
            type.setValue("$");
            v = v
              .split("")
              .filter((s) => s !== "$")
              .join("");
          } else if (v.endsWith("%")) {
            type.setValue("%");
            v = v.slice(0, -1);
          } else if (v.endsWith("X")) {
            type.setValue("X");
            v = v.slice(0, -1);
          } else if (v.endsWith("Rewards")) {
            type.setValue("Rewards");
            v = v.slice(0, -7);
          }
          const endIdx = v.indexOf("-");
          if (endIdx > 0) {
            start.setValue(v.slice(0, endIdx).trim());
            end.setValue(v.slice(endIdx + 1).trim());
          } else {
            start.setValue(v.trim());
            end.setValue("");
          }
          reward.setValue(first.slice(vIdx).trim());
        } else {
          let v = first;
          if (v.startsWith("$")) {
            type.setValue("$");
            v = v
              .split("")
              .filter((s) => s !== "$")
              .join("");
          } else if (v.endsWith("%")) {
            type.setValue("%");
            v = v.slice(0, -1);
          } else if (v.endsWith("X")) {
            type.setValue("X");
            v = v.slice(0, -1);
          } else if (v.endsWith("Rewards")) {
            type.setValue("Rewards");
            v = v.slice(0, -7);
          }
          reward.setValue("");
        }
        const second = main.slice(pIdx + 11).trimStart();
        const sufIdx = second.indexOf(" ");

        if (sufIdx >= 0) {
          let pv = second.slice(0, sufIdx);
          if (pv.startsWith("$")) {
            pv = pv
              .split("")
              .filter((s) => s !== "$")
              .join("");
            pType.setValue("$");
          } else {
            pType.setValue("Unit");
          }
          const endIdx = pv.indexOf("-");
          if (endIdx > 0) {
            pStart.setValue(pv.slice(0, endIdx).trim());
            pEnd.setValue(pv.slice(endIdx + 1).trim());
          } else {
            pStart.setValue(pv.trim());
            pEnd.setValue("");
          }
          suffix.setValue(second.slice(sufIdx).trimStart());
        } else {
          let pv = second;
          if (pv.startsWith("$")) {
            pv = pv
              .split("")
              .filter((s) => s !== "$")
              .join("");
            pType.setValue("$");
          } else {
            pType.setValue("Unit");
          }
          const endIdx = pv.indexOf("-");
          if (endIdx > 0) {
            pStart.setValue(pv.slice(0, endIdx).trim());
            pEnd.setValue(pv.slice(endIdx + 1).trim());
          } else {
            pStart.setValue(pv.trim());
            pEnd.setValue("");
          }
          suffix.setValue("");
        }
      } else {
        const firstIdx = main.indexOf(" ");
        let first = "";
        if (firstIdx > 0) {
          first = main.slice(0, firstIdx).trim();
          let rest = main.slice(firstIdx);
          rest = rest.trimStart();
          rewardData.forEach((item) => {
            if (rest.startsWith(item.id)) {
              rest = rest.slice(item.id.length + 1);
              reward.setValue(item.id);
            }
          });
          suffix.setValue(rest);
        } else {
          first = main;
        }
        let v = first;
        if (
          v.startsWith("$") ||
          v.endsWith("%") ||
          v.endsWith("X") ||
          v.endsWith("Rewards")
        ) {
          if (v.startsWith("$")) {
            type.setValue("$");
            v = v
              .split("")
              .filter((s) => s !== "$")
              .join("");
          } else if (v.endsWith("%")) {
            type.setValue("%");
            v = v.slice(0, -1);
          } else if (v.endsWith("X")) {
            type.setValue("X");
            v = v.slice(0, -1);
          } else if (v.endsWith("Rewards")) {
            type.setValue("Rewards");
            v = v.slice(0, -7);
          }
          const endIdx = v.indexOf("-");
          if (endIdx > 0) {
            start.setValue(v.slice(0, endIdx).trim());
            end.setValue(v.slice(endIdx + 1).trim());
          } else {
            start.setValue(v.trim());
            end.setValue("");
          }
        }
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-2">Earn</div>
        <ToggleButton
          label="Up To"
          className="mt-auto mb-2 ml-2"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              start.value,
              end.value,
              type.value,
              reward.value,
              isPurchase.value,
              pStart.value,
              pEnd.value,
              pType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 50, marginLeft: 10 }}>
          <Input2
            label="Start"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                end.value,
                type.value,
                reward.value,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 50, marginLeft: 10 }}>
          <Input2
            label="End"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                e,
                type.value,
                reward.value,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>

        <div style={{ width: 80, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={80}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                e,
                reward.value,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <div style={{ width: 240 }}>
          <DropDown2
            label="Rewards"
            data={rewardData}
            width={240}
            value={reward.value}
            onChange={(e) => {
              reward.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                e,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          label="w/purchase"
          className="mt-auto mb-2"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              isUpTo.value,
              start.value,
              end.value,
              type.value,
              reward.value,
              e,
              pStart.value,
              pEnd.value,
              pType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 70, marginLeft: 10 }}>
          <Input2
            label="Start"
            disabled={!isPurchase.value}
            value={pStart.value}
            onChange={(e) => {
              pStart.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                reward.value,
                isPurchase.value,
                e,
                pEnd.value,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <Input2
            label="Value"
            disabled={!isPurchase.value}
            value={pEnd.value}
            onChange={(e) => {
              pEnd.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                reward.value,
                isPurchase.value,
                pStart.value,
                e,
                pType.value,
                suffix.value
              );
            }}
          />
        </div>

        <div style={{ width: 60, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData2}
            width={60}
            disabled={!isPurchase.value}
            value={pType.value || "Unit"}
            onChange={(e) => {
              pType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                reward.value,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <div style={{ width: 240 }}>
          <Input2
            label="Suffix"
            width={240}
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                reward.value,
                isPurchase.value,
                pStart.value,
                pEnd.value,
                pType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
