import React from "react";
import styled from "styled-components";

export const Footer = () => {
  return (
    <Container>
      <div className="my-auto">ADDISON by Deep.ad</div>
      <Link
        href="https://www.deep.ad/privacy-policy"
        target="_blank"
        className="ml-3 my-auto"
      >
        Privacy Policy
      </Link>
      <Link
        href="https://www.deep.ad/tos"
        target="_blank"
        className="ml-3 my-auto"
      >
        Terms of Service
      </Link>
      <Link
        href="https://deepad.atlassian.net/servicedesk/customer/portal/6"
        target="_blank"
        className="ml-3 my-auto"
      >
        Feedback
      </Link>
    </Container>
  );
};

const Container = styled.div`
  background: #131313;
  height: 50px;
  color: white;
  font-size: 14px;
  padding: 0 12px;
  display: flex;
  border-top: 2px solid #0a0a0a;
`;
const Link = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    color: #006280;
    cursor: pointer;
  }
`;
