/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useModal } from "hooks";
import { fabric } from "fabric";
import {
  getImage,
  approvePage,
  rejectPage,
  getNextPageCrop,
  postPageAutoCrop,
  savePageIncentiveString,
  saveCircularIncentiveString,
  getCircularById,
} from "api";
import useKeypress from "react-use-keypress";
import styled from "styled-components";
import "cropperjs/dist/cropper.css";
import { MainLayout } from "layouts";
import { useMainContext, useAdblockHistoryContext } from "contexts";
import {
  Button,
  Alert,
  ColorPick,
  AdblockToolButton,
  HistoryDropdown,
  AdblockIncentives,
  AdblockSection,
  AdblockItem,
} from "components";
import { RetryCroppingModal } from "modals";
import { useParams } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { format } from "date-fns";
import { MdRefresh } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const AdBlocksPage = () => {
  const params = useParams();
  const blockRef = useRef();

  const {
    currentPageId,
    currentCircularId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    setSessionId,
    currentAdBlockId,
  } = useMainContext();
  const { initHistory, pushHistory } = useAdblockHistoryContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [isAdCropping, setIsAdCropping] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Page data
  const [pageData, setPageData] = useState([]);

  //Adblock data
  const [adBlockData, setAdBlockData] = useState([]);
  const [isNoQueue, setNoQueue] = useState(false);
  const [queueCount, setQueueCount] = useState("");
  const [messageID, setMessageID] = useState("");
  const [type, setType] = useState("adblock");

  // Canvas information
  const [canvas, setCanvas] = useState("");
  const [canvasRatio, setCanvasRatio] = useState(1);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [activeCanvasObject, setActiveCanvasObject] = useState(null);

  // Error Messages
  const [errorText, setErrorText] = useState("");

  //Modals and user information
  const alertModal = useModal();
  const retryCroppingModal = useModal();

  // View info
  const [currentAdblockId, setCurrentAdblockId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [circularName, setCircularName] = useState("");
  const [currentOutlineColor, setCurrentOutlineColor] = useState(
    OUTLINE_COLORS[0]
  );

  var started = false;
  var x = 0;
  var y = 0;

  // Incentive data
  const [incentiveData, setIncentiveData] = useState([]);
  const handleAddIncentive = () => {
    setIncentiveData([...incentiveData, { type: "page", text: "" }]);
  };
  const handleRemoveIncentive = (index) => {
    let temp = [...incentiveData];
    temp.splice(index, 1);
    setIncentiveData(temp);
  };
  const handleUpdateIncentive = (index, data) => {
    let temp = [...incentiveData];
    temp.splice(index, 1, data);
    setIncentiveData(temp);
  };

  // Section Data
  const handleSectionSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);
      await postPageEvents("click", `Section ${index}`);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Start Methods //////////////////
  /////////////////////////////////////
  */

  const init = async (type, canvas) => {
    // display dotted boundary when over rect
    canvas.on("mouse:over", function (e) {
      handleCanvisObjectMouseOver(canvas, e);
    });

    // remove dotted boundary when over rect
    canvas.on("mouse:out", function (e) {
      if (e?.target) {
        e.target.set("strokeDashArray", null);

        if (canvas.status === "crop") {
          e.target.hoverCursor = "crosshair";
        } else {
          e.target.hoverCursor = "default";
        }

        canvas.renderAll();
      }
    });
    // zoom when mouse wheel
    canvas.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvas.getHeight() > blockRef.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });
    // pan when alt key & mouse down
    canvas.on("mouse:down", function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else if (evt.button === 2) {
        console.log("create down event", evt);
        var mouse = canvas.getPointer(evt);
        started = true;
        x = mouse.x;
        y = mouse.y;

        var today = new Date();
        this.isCreating = true;
        var rect = new fabric.Rect({
          width: 0,
          height: 0,
          left: x,
          top: y,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: type === "adblock" ? "transparent" : "#3BC7E320",
          cornerColor: "white",
          cornerStrokeColor: currentOutlineColor,
          id: "temp-" + today.getTime(),
          type,
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
        canvas.renderAll();
        canvas.setActiveObject(rect);
      }
    });

    canvas.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      } else if (this.isCreating) {
        // Resize the newly created crop while mouse draging
        if (!started) {
          return false;
        }

        let mouse = canvas.getPointer(opt.e);

        let w = mouse.x - x,
          h = mouse.y - y;

        if (!w || !h) {
          return false;
        }

        let rect = canvas.getActiveObject();
        if (rect) {
          if (opt.e.shiftKey) {
            rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
          } else {
            rect.set("width", w).set("height", h);
          }
          rect.setControlsVisibility({ mtr: false });
        }
        canvas.renderAll();
      }
    });
    canvas.on("mouse:up", function (opt) {
      const evt = opt.e;
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      console.log("mouse up", evt);
      this.isCreating = false;
      if (evt.button === 2) {
        if (started) {
          started = false;
        }
        // Remove if the rect is too small
        var objects = canvas.getObjects();
        objects.forEach((rect) => {
          if (
            rect &&
            (Math.abs(rect?.width) < 20 || Math.abs(rect.height) < 20)
          ) {
            canvas.remove(rect);

            // setCanvas(canvas);
          }
        });
        canvas.requestRenderAll();

        // Disable all objects unselectable
        canvas.discardActiveObject();
        objects = canvas.getObjects();
        //Create the adblocks list
        setCanvasObjects(
          objects.map((object, index) => {
            return {
              index: index,
              uuid: object?.id,
              type: object?.type,
            };
          })
        );
      }
    });

    //
    canvas.on("selection:created", function (opt) {
      console.log("selection:created", opt.target.id);
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      if (canvas.status === "move") {
        opt.target.hoverCursor = "move";
      }
    });

    canvas.on("selection:updated", function (opt) {
      console.log("selection:updated", opt.target.id);
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      if (canvas.status === "move") {
        opt.target.hoverCursor = "move";
      }
    });

    canvas.on("selection:cleared", function (opt) {
      console.log("selection:cleared", opt);
      setActiveCanvasObject(null);
    });
  };

  /*
  ///////////////////////////////////////
  ////// Watchers //////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    setIsLoading(false);
    setCurrentPage("TD - Adblocks");
    setPreviousTime(new Date());

    const canvi = new fabric.Canvas("canvas", {
      height: blockRef.current.offsetHeight,
      width: blockRef.current.offsetWidth,
      fireRightClick: true, // <-- enable firing of right click events
      stopContextMenu: true, // <--  prevent context menu from showing
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "white";
    fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 8;
    setCanvas(canvi);
    initHistory();
    init(type, canvi);
    fetchPageData(canvi);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canvas) {
      init(type, canvas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  /*
  ///////////////////////////////////////
  ////// Fetch Data //////////////////
  /////////////////////////////////////
  */

  const fetchPageData = async (canvas) => {
    setIsFetching(true);
    setNoQueue(false);
    const result = await getNextPageCrop();
    if (result.success) {
      if (result.payload !== "") {
        setCurrentPageId(result.payload.page.uuid);
        setCurrentCircularId(result.payload.circular.uuid);
        console.log("push history", result.payload);
        pushHistory({
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
        });
        setQueueCount(result.payload.queue_count);
        const tmpAdblocks = [
          ...result.payload.sections.map((section) => ({
            ...section,
            type: "section",
          })),
          ...result.payload.adblocks.map((adblock) => ({
            ...adblock,
            type: "adblock",
          })),
        ];
        setAdBlockData(tmpAdblocks);
        setMessageID(result.payload.message_id);
        setSessionId(result.payload.message_id);
        setCircularName(
          result.payload.circular.retailer.name +
            "_" +
            format(new Date(result.payload.circular.circ_week_dt), "MM/dd/yyyy")
        );
        if (result.payload.page && result.payload.page.filename) {
          setPageData(result.payload.page);
          fetchImage(
            result.payload.page.filename,
            params,
            tmpAdblocks,
            result.payload.circular.uuid,
            canvas
          );
        }
      } else {
        setNoQueue(true);
        setQueueCount(0);
      }
      postPageEvents("ready", "");
    } else {
      //TODO: Show error message, system bugs?
    }
    postPageEvents("ready", "");
    setIsFetching(false);
  };

  const fetchImage = async (id, pageId, adblocks, circularId, canvas) => {
    setIsFetching(true);
    let cir = await getCircularById(circularId);
    if (cir.success) {
      if (cir.payload.incentive_nm) {
        setIncentiveData([
          {
            type: "circular",
            text: cir.payload.incentive_nm,
          },
        ]);
      } else {
        setIncentiveData([]);
      }
    }
    const img = await getImage(id);
    if (img.success && canvas) {
      canvas.clear();
      canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      let vpt = canvas.viewportTransform;
      vpt[4] = 0;
      vpt[5] = 0;

      var background = new Image();
      background.onload = async function () {
        setIsFetching(true);

        var f_img = new fabric.Image(background);
        setCanvasRatio(canvas.height / background.height);
        canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.height / background.height,
          scaleY: canvas.height / background.height,
        });

        setAdBlockData(adblocks);
        drawAdblocks(canvas.height / background.height, adblocks, canvas);

        //Create the adblocks list
        var objects = canvas.getObjects();
        const canvasObjects = objects.map((object, index) => {
          return {
            index: index,
            uuid: object?.id,
            type: object?.type,
            text: object?.text,
          };
        });
        setCanvasObjects(canvasObjects);
        setIsFetching(false);
      };

      background.src = "data:image/png;base64," + img.payload;
    } else {
      //TODO: If image doesnt load show and error
    }
    setIsFetching(false);
  };

  const navigateNext = async () => {
    canvas && canvas.clear();
    setAdBlockData([]);
    setCanvasObjects([]);
    fetchPageData(canvas);
  };

  const drawAdblocks = (canvasRatio, adBlockData, canvas) => {
    // Draw block rect data if available
    if (adBlockData.length > 0) {
      adBlockData.forEach((adblock) => {
        const rect = new fabric.Rect({
          left: adblock.coords_x1 * canvasRatio,
          top: adblock.coords_y1 * canvasRatio,
          height: (adblock.coords_y2 - adblock.coords_y1) * canvasRatio,
          width: (adblock.coords_x2 - adblock.coords_x1) * canvasRatio,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: adblock.type === "adblock" ? "transparent" : "#3BC7E320",
          cornerColor: "white",
          id: adblock.uuid,
          type: adblock.type,
          text: adblock.type === "section" ? adblock?.event_nm || "" : "",
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
      });
      canvas.renderAll();
      setCanvas(canvas);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Page Flags //////////////////
  /////////////////////////////////////
  */

  const handleReject = async () => {
    setIsSubmitting(true);
    let data = {};
    data.message_id = messageID;
    try {
      await rejectPage(currentPageId, data);
      navigateNext();
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);
    const crops = canvas.getObjects();
    let data = {};
    const adblocks = crops
      .filter((crop) => crop.type === "adblock")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }

        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
        };
      });
    const sections = crops
      .filter((crop) => crop.type === "section")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }
        let event_nm = "";
        const sec = canvasObjects.find((item) => item.uuid === crop.id);
        if (sec) {
          event_nm = sec.text;
        }
        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
          event_nm,
        };
      });
    data.adblocks = adblocks;
    data.message_id = messageID;
    data.sections = sections;

    if (incentiveData.filter(({ type }) => type === "page").length > 0) {
      await savePageIncentiveString(
        currentPageId,
        incentiveData
          .filter(({ type }) => type === "page")
          .map((item) => item.text)
          .join(",")
      );
    }
    if (incentiveData.filter(({ type }) => type === "circular").length > 0) {
      await saveCircularIncentiveString(
        currentCircularId,
        incentiveData
          .filter(({ type }) => type === "circular")
          .map((item) => item.text)
          .join(",")
      );
    }

    try {
      await approvePage(currentPageId, data);
      navigateNext();
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  /*
  ///////////////////////////////////////
  ////// Auto Crop //////////////////
  /////////////////////////////////////
  */

  const handleAutoCrop = async (force = false) => {
    setIsAdCropping(true);
    const result = await postPageAutoCrop(currentPageId, force);
    if (result.success) {
      fetchImage(pageData.filename, params, result.payload.adblocks);
    } else {
      //TODO: set auto crop fail here
    }
    setIsAdCropping(false);
    postPageEvents("click", "auto crop");
  };

  /*
  ///////////////////////////////////////
  ////// Canvas Tools //////////////////
  /////////////////////////////////////
  */

  const handleCanvisObjectMouseOver = (canvi, event) => {
    if (event?.target && canvi.status !== "crop") {
      if (event.target === canvi.getActiveObject()) {
        event.target.hoverCursor = "move";
      }
      event.target.set("strokeDashArray", [5]);
      canvi.renderAll();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Adblock Methods  //////////////////
  /////////////////////////////////////
  */

  function handleListDelete(selected) {
    postPageEvents("click", "Delete");
    //Find the object in the list, create new list
    let newCanvasObjects = canvasObjects.filter(
      (item) => item.uuid !== selected.uuid
    );

    const seletedIndex = canvasObjects.findIndex(
      (item) => item.uuid === selected.uuid
    );
    //select the object first
    canvas.setActiveObject(canvas.item(seletedIndex));
    setCurrentAdblockId("");

    // //delete the object
    canvas.remove(canvas.item(seletedIndex));
    //Update the new list after deletion
    canvas.requestRenderAll();

    setCanvasObjects(newCanvasObjects);
  }

  function handleSingleDelete() {
    //Find the object in the list, create new list
    let newCanvasObjects = canvasObjects.filter(
      (item) => item.uuid !== activeCanvasObject.id
    );
    postPageEvents("click", "Delete");
    newCanvasObjects = newCanvasObjects.map((object, index) => {
      return {
        index: index,
        uuid: object?.uuid,
      };
    });

    // //delete the object
    canvas.remove(activeCanvasObject);
    canvas.requestRenderAll();

    //Update the new list after deletion
    setCanvasObjects(newCanvasObjects);
  }

  const handleAdblockSelect = (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
    }
  };

  const handlePickColor = (color) => {
    postPageEvents("click", "Color");
    setCurrentOutlineColor(color);
    canvas.getObjects().map(function (o) {
      return o.set({
        stroke: color,
        cornerStrokeColor: color,
      });
    });
    canvas.requestRenderAll();
  };

  const handleClickAutoCrop = () => {
    if (adBlockData.length > 0) {
      retryCroppingModal.openModal();
    } else {
      handleAutoCrop(false);
    }
  };

  const handleClickAdblockType = () => {
    setType("adblock");
  };
  const handleClickSectionType = () => {
    setType("section");
  };

  const handleChangeText = (id, text) => {
    setCanvasObjects(
      canvasObjects.map((obj) => {
        if (obj?.uuid === id) {
          return { ...obj, text };
        }
        return obj;
      })
    );
  };

  /*
  ///////////////////////////////////////
  ////// Keyboard events  //////////////////
  /////////////////////////////////////
  */

  useKeypress(["Escape"], (event) => {
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    setCurrentAdblockId("");
  });

  useKeypress(["Delete"], (event) => {
    if (currentAdBlockId) {
      handleListDelete({ uuid: currentAdBlockId }, selectedIndex);
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                <BreadCrumb className="ml-3 my-auto">{circularName}</BreadCrumb>
                <BreadCrumb className="ml-3 my-auto">
                  {currentPageId}
                </BreadCrumb>
                <HistoryDropdown className="ml-4" width={320} type="adblock" />
              </div>
              <PageInfo className="ml-auto my-auto ">{`Remaing in queue:  ${queueCount}`}</PageInfo>
              <Button
                buttonTheme="dark"
                width="100px"
                size="small"
                className="ml-3 my-auto"
                onClick={handleReject}
                isLoading={isSubmitting}
                disabled={isFetching || isLoading || isNoQueue}
                eventType="flag"
              >
                REJECT
              </Button>
              <Button
                buttonTheme="dark"
                width="120px"
                size="small"
                className="ml-2 my-auto"
                onClick={handleApprove}
                isLoading={isSubmitting}
                disabled={isFetching || isLoading || isNoQueue}
                eventType="flag"
              >
                APPROVE
              </Button>
            </div>
          )}
        </Header>

        <Content>
          <ToolContent>
            <AdblockToolButton
              onClick={handleClickAdblockType}
              type="Adblock"
              isActive={type === "adblock"}
            />
            <AdblockToolButton
              onClick={handleClickSectionType}
              type="Section"
              isActive={type === "section"}
            />
          </ToolContent>
          <div className="d-flex flex-1">
            <SelectionContainer>
              <SelectionHeader>
                PAGE ADBLOCK CROPPING
                <div className="ml-auto d-flex">
                  {OUTLINE_COLORS.map((color) => {
                    return (
                      <ColorPick
                        bgColor={color}
                        key={color}
                        active={color === currentOutlineColor}
                        onClick={() => handlePickColor(color)}
                      />
                    );
                  })}
                  {!isAdCropping ? (
                    <MdRefresh
                      className="refresh ml-2 my-auto"
                      size={18}
                      onClick={handleClickAutoCrop}
                    />
                  ) : null}
                  {activeCanvasObject ? (
                    <FaTrash
                      className="delete ml-2 my-auto mr-2"
                      onClick={handleSingleDelete}
                    />
                  ) : null}
                </div>
              </SelectionHeader>

              {isAdCropping || isFetching || isSubmitting || isNoQueue ? (
                <CanvasOverlay>
                  {isAdCropping ? ( //TODO: we can rotate text and make it look like it is processing
                    <AutoCropping height={canvas.height} width={canvas.width}>
                      Recropping adblocks for this page, please do not refresh
                      or close the page. This process can take a minute to 90
                      seconds.
                    </AutoCropping>
                  ) : null}

                  {isFetching || isSubmitting ? (
                    <SkeletonContainer>
                      <Skeleton height={canvas.height} width={canvas.width} />
                    </SkeletonContainer>
                  ) : null}

                  {isNoQueue ? (
                    <NoQueue>No more Pages in queue.</NoQueue>
                  ) : null}
                </CanvasOverlay>
              ) : null}

              <CanvasContainer className="w-100" ref={blockRef}>
                <Canvas id="canvas" />
              </CanvasContainer>
            </SelectionContainer>
          </div>
          <RightSection>
            <AdblockIncentives
              onAdd={handleAddIncentive}
              onDelete={handleRemoveIncentive}
              onUpdate={handleUpdateIncentive}
              data={incentiveData}
            />
            <AdblocksContainer>
              <SelectionHeader>
                <span>{canvasObjects.length}</span>&nbsp; ADBLOCKS
              </SelectionHeader>
              <SelectionContent>
                {isLoading || isSubmitting || isFetching ? (
                  <Skeleton count={10} height={40} style={{ marginTop: 8 }} />
                ) : (
                  <>
                    {canvasObjects.map((item, index) =>
                      item.type === "adblock" ? (
                        <AdblockItem
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleAdblockSelect(item.uuid, index)}
                        />
                      ) : item.type === "section" ? (
                        <AdblockSection
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleSectionSelect(item.uuid, index)}
                          onChangeText={(e) => handleChangeText(item.uuid, e)}
                        />
                      ) : null
                    )}
                  </>
                )}
              </SelectionContent>
            </AdblocksContainer>
          </RightSection>
        </Content>
        <Alert {...alertModal} title="Error" size="small">
          {errorText}
        </Alert>
        <RetryCroppingModal
          onOk={() => handleAutoCrop(true)}
          {...retryCroppingModal}
        />
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const ToolContent = styled.div`
  width: 64px;
  color: white;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.backgrounds};
  margin: 2px 2px;
  padding: 8px 0;
`;

const SelectionContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  position: relative;
`;

const AdblocksContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SkeletonContainer = styled.div`
  margin-top: -4px;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;
  margin-bottom: 5px;
  display: flex;
  svg {
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SelectionContent = styled.div`
  text-transform: uppercase;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const CanvasContainer = styled.div`
  height: calc(100% - 30px) !important;
  background-color: ${(props) => props.theme.palette.items};
`;

const CanvasOverlay = styled.div`
  height: calc(100% - 30px) !important;
  position: absolute;
  z-index: 1;
`;

const AutoCropping = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  background-color: ${(props) => props.theme.palette.items};
  padding: 20px;
  color: ${(props) => props.theme.palette.white};
`;

const NoQueue = styled.div`
  color: white;
  padding: 20px;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const BreadCrumb = styled.div`
  color: white;
  font-size: 14px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
