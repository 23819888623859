/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { fabric } from "fabric";
import ReactModal from "react-modal";

export const PageViewModal = ({
  title,
  open,
  onClose,
  children,
  size = "large",
  isCloseDisabled = false,
  backImg,
  pageWidth,
  pageHeight,
  adblockCoords,
}) => {
  const ref = useRef(null);
  const [clientWidth, setClientWidth] = useState(1);
  const [clientHeight, setClientHeight] = useState(1);
  const [canvas, setCanvas] = useState("");

  useEffect(() => {
    ReactModal.setAppElement("body");
    setClientWidth(window.innerWidth);
    setClientHeight(window.innerHeight - 50);
    const canvi = new fabric.Canvas("minmap-canvas", {
      height: window.innerHeight - 50,
      width: window.innerWidth,
    });
    // zoom when mouse wheel
    canvi.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvi.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvi.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvi.getHeight() > ref.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });

    // pan when alt key & mouse down
    canvi.on("mouse:down", function (opt) {
      const evt = opt.e;
      this.isDragging = true;
      this.selection = false;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
    });

    canvi.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });

    canvi.on("mouse:up", function (opt) {
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
    });
    setCanvas(canvi);

    return () => {};
  }, []);

  useEffect(() => {
    if (open) {
      canvas.renderAll();
      setCanvas(canvas);
    }

    return () => {};
  }, [open]);

  useEffect(() => {
    if (
      canvas &&
      backImg &&
      pageWidth > 1 &&
      pageHeight > 1 &&
      (adblockCoords.coords_x1 > 0 ||
        adblockCoords.coords_x2 > 0 ||
        adblockCoords.coords_y1 > 0 ||
        adblockCoords.coords_y2 > 0)
    ) {
      let offsetX = 0,
        offsetY = 0,
        adblockWidth,
        adblockHeight;
      const clientRatio = clientWidth / clientHeight;
      const adblockRatio = pageWidth / pageHeight;
      if (adblockRatio < clientRatio) {
        offsetY = 0;
        offsetX = (clientWidth - clientHeight * adblockRatio) / 2;
        adblockWidth = clientHeight * adblockRatio;
        adblockHeight = clientHeight;
      } else {
        offsetY = (clientHeight - clientWidth / adblockRatio) / 2;
        offsetX = 0;
        adblockWidth = clientWidth;
        adblockHeight = clientHeight / adblockRatio;
      }

      canvas.setBackgroundImage(backImg, canvas.renderAll.bind(canvas), {
        top: offsetY,
        left: offsetX,
        originX: "left",
        originY: "top",
        scaleX: canvas.height / pageHeight,
        scaleY: canvas.height / pageHeight,
      });
      canvas.getObjects().forEach((obj) => canvas.remove(obj));
      const rect = new fabric.Rect({
        left: offsetX + adblockWidth * (adblockCoords.coords_x1 / pageWidth),
        top: offsetY + adblockHeight * (adblockCoords.coords_y1 / pageHeight),
        width:
          adblockWidth *
          ((adblockCoords.coords_x2 - adblockCoords.coords_x1) / pageWidth),
        height:
          adblockHeight *
          ((adblockCoords.coords_y2 - adblockCoords.coords_y1) / pageHeight),

        stroke: "red",
        strokeWidth: 2,
        fill: "transparent",
        cornerColor: "white",
        selectable: false,
      });
      rect.setControlsVisibility({ mtr: false });
      canvas.add(rect);
      canvas.renderAll();
    }
    return () => {};
  }, [backImg, pageWidth, pageHeight, adblockCoords, canvas]);

  return (
    <Container isOpen={open}>
      <Header>
        <span className="my-auto">{title}</span>
        {!isCloseDisabled && (
          <CloseIcon onClick={onClose}>
            <AiOutlineClose size={20} />
          </CloseIcon>
        )}
      </Header>
      <Content ref={ref}>
        <Canvas id="minmap-canvas" />
      </Content>
    </Container>
  );
};

const CloseIcon = styled.div`
  color: white;
  margin: auto 0 auto auto;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    opacity: 0.7;
  }
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 100%;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 0 24px 0 28px;
`;
const Content = styled.div`
  overflow: auto;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  cursor: move !important;
`;
